import React, { useState, useEffect, useContext, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import isNil from 'lodash.isnil'
import Paginator from '../Paginator/Paginator'
import MatchScore from '../MatchScore/MatchScore'
import StageSelector from '../StageSelector/StageSelector'
import CandidateGrader from '../CandidateGrader/CandidateGrader'
import moment from 'moment'
import styles from './styles/CandidateTable.module.scss'
import { StoreDispatchContext } from '../../../stores/JDPStore'
import isEmpty from 'lodash.isempty'
import Util from '../../../utils/util'
import { Card, Container, Col, Button, DropdownButton, Image, Row, ButtonGroup } from 'react-bootstrap'
import profileImage from '../../../../assets/images/icons/profile.png'
import Badge from 'react-bootstrap/Badge'
import './styles/CandidateTable.scss'
import applicationIcon from '../../../../assets/images/icons/application.png'
import lastActivityIcon from '../../../../assets/images/icons/lastactivity.png'
import lastContactIcon from '../../../../assets/images/icons/lastcontact.png'
import SubmittedIcon from '../../../../assets/images/icons/submitted.png'
import CalendarIcon from '../../../../assets/images/icons/calendar-icon.svg'
import MailIcon from '../../../../assets/images/icons/mail-icon.svg'
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse'
import _ from 'lodash'
import Alert from 'react-bootstrap/Alert'
import { CSVLink } from 'react-csv'
import { makeRequest } from '../RequestAssist/RequestAssist'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import LinkedinIcon from '../../../../assets/images/icons/linkedin.png'
import CandidateAvatarIcon from '../CandidateAvatarIcon/CandidateAvatarIcon'
import PdfIcon from '../../../../assets/images/icons/pdf.png'
import { allStages,requiredStages } from '../../../misc/gconst'
import eyeImage from '../../../../assets/images/icons/eye.png'
import DateSelector from '../DateSelector.js/DateSelector'
import { Calendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './styles/modal.scss'
import { capitalize } from '../../../utils'
import { nanoid } from 'nanoid'
import DateRangePicker from '../DateRangePicker/DateRangePicker'
import '../CandidateTable/styles/CandidateTableModal.scss'
import TimePicker from '../TimePicker/TimePicker'
import InterviewScheduleModal from '../InterviewScheduleModal/InterviewScheduleModal'
import SweetAlert from 'react-bootstrap-sweetalert'
import Select from 'react-select'

const MAX_SELECTION_LIMIT = 25
const CANDIDATES_PER_PAGE = 25

function CandidateTable({
    loading,
    candidates,
    page,
    jobId,
    stage,
    handlePageChangeClick,
    requestSort,
    sortConfig,
    totalPages,
    allSelectedCandidates,
    totalCandidateCount,
    enableStages,
    refreshCandidates,
    columns,
    displayCount,
    candidatePage,
    jobs,
    candidateSource,
    memberOrganization,
    currentOrganization,
    candidate_history,
    filterStack,
    showCandidate,
    organization,
    ctMember,
    freeCandidates,
    listEmailSendCandidate
}) {
    const [orgList, setOrgList] = useState(organization !== undefined ? organization?.map((org)=> ({ label:org.name,value:org.id })) : [])
    const [jobList, setJobList] = useState([])
    const [allJobList, setAllJobList] = useState(jobs.length > 0 ? jobs?.map((job)=> ({ label:job.name,value:job.id })) : [])
    const customTheme = {
        yearColor: '#405b73',
        lineColor: '#d0cdc4',
        dotColor: '#262626',
        borderDotColor: '#d0cdc4',
        titleColor: '#405b73',
        subtitleColor: '#bf9765',
        textColor: '#262626',
    }
    const [selectionLimit, setSelectionLimit] = useState(
        Math.min(MAX_SELECTION_LIMIT, totalCandidateCount)
    )
    const [lastContact, setLastContact] = useState({
        active: false,
        id: null,
    })
    const [application, setApplication] = useState({
        active: false,
        id: null,
    })
    const [lastActive, setLastActive] = useState({
        active: false,
        id: null,
    })
    const [submissionData, setSubmissionData] = useState({
        active: false,
        id: null,
    })
    const { dispatch, state } = useContext(StoreDispatchContext)
    const [transactionData, setTransactionData] = useState([])
    const [validationErrors, setValidationErrors] = useState({})
    const [selectedRange, setSelectedRange] = useState('')
    const csvLink = useRef()
    const [openModal, setOpenModal] = useState(false)
    const [selectedJobs, setSelectedJobs] = useState('')
    const [selectedJobId, setSelectedJobId] = useState('')
    const [selectedOrg, setSelectedOrg] = useState('')
    const [selectedOrgId, setSelectedOrgId] = useState('')
    const [selectedStage, setSelectedStage] = useState('')
    const [selectedStageValue, setSelectedStageValue] = useState('')
    const [historyShow, setHistoryShow] = useState(false)
    const [emailReponseShow, setEmailReponseShow] = useState(false)
    const [emailResponseTxt, setEmailResponseTxt]  = useState('')
    const [emailResponseDate, setEmailResponseDate]  = useState('')
    const { skills } = filterStack
    const [personId, setPersonId] = useState('')
    const [personOpoinionId, setPersonOpoinionId] = useState('')
    const [goodBadBoxShow, setGoodBadBoxShow] = useState(false)
    const [scoreDetailOpen, setScoreDetailOpen] = useState(false)
    const [scoreDetail, setScoreDetail] = useState('')
    const [goodBadBoxError, setGoodBadBoxError] = useState(false)
    const [goodBadBoxRejectError, setGoodBadBoxRejectError] = useState(false)
    const [history, setHistory] = useState()
    const [review, setReview] = useState()
    const [allHistory, setAllHistory] = useState()
    const [historyLoading, setHistoryLoading] = useState(false)
    const [showCurrentJob, setShowCurrentJob] = useState(candidateSource != 'candidate_search' ? true : false)
    const [showAllJob, setShowAllJob] = useState(candidateSource != 'candidate_search' ? false : true)
    const [showReview, setShowReview] = useState(false)
    const [getstage, setGetStage] = useState()
    const [moveToHired, setMoveToHired] = useState(false)
    const selectedCandidates = isEmpty(allSelectedCandidates) ? state['selectedCandidates'] : allSelectedCandidates
    const [joiningDate, onChange] = useState(new Date())
    const [moveToArchived, setMoveToArchived] = useState(false)
    const [checked, setChecked] = useState(false)
    const [checkInterviewStage, setCheckInteviewStage] = useState()
    const [checkArchivedStage, setCheckArchivedStage] = useState()
    const [validationDate, setValidationDate] = useState()
    const [validationTime, setValidationTime] = useState()
    const [dateError, setDateError] = useState()
    const [timeErrors, seTimeErrors] = useState()
    const [interviewTypeId, setInterviewTypeId] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [selectedTimes, setSelectedTimes] = useState([])
    const [timezone, setTimeZones] = useState()
    const [timeError, setTimeError] = useState(false)
    const [amError, setAmError] = useState(false)
    const [disable, setDisable] = useState(false)
    const [moveStage, setMoveStage] = useState('')
    const [moveJobId, setMoveJobId] = useState('')
    const [moveSelectedCandidates, setMoveSelectedCandidates] = useState('')
    const [moveUserId, setMoveUserId] = useState('')
    const [personReview, setPersonReview] = useState('')
    const [personReviewReason, setPersonReviewReason] = useState('')
    const [personReviewReasonNote, setPersonReviewReasonNote] = useState('')

    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    const [interviews, setInterviews] = useState([
        { id: nanoid(), date: null, time: null, stage: null, timeZone: null },
    ])
    const [dateRange, setDateRange] = useState({
        startDate: moment(new Date()).toDate(),
        endDate: moment(new Date()).toDate(),
    })
    const [backgroundColor, setBackgroundColor] = useState({
        active: false,
        id: null,
        matchDate: null,
    })
    const [selectedDate, setSelectedDate] = useState()
    useEffect(
        () =>
            setSelectionLimit(
                Math.min(MAX_SELECTION_LIMIT, totalCandidateCount)
            ),
        [totalCandidateCount, checkInterviewStage]
    )
    const handleDate = (date) => {
        setDateRange(date)
    }
    const handleScoreModal = (mDetail) => {
        if (mDetail != '' && mDetail) {
            setScoreDetail(mDetail)
            setScoreDetailOpen(true)
        }
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    useEffect(() => {
        let candidate = showCandidate
        dispatch({
            type: 'show_candidate',
            candidate,
        })
    }, [showCandidate, saveData, checkInterviewStage])

    const candidateClickHandler = (candidate) =>
    dispatch({
        type: 'show_candidate',
            candidate,
        })
    const handleImport = async () => {
        if (isEmpty(allSelectedCandidates)) {
            setValidationErrors({
                ...validationErrors,
                selectedcandidated: 'Please select candidate',
            })
            return
        }
        const candidates = allSelectedCandidates
        const candidateIds = candidates.map((candidate) => candidate.id)
        const payload = new FormData()
        payload.append('list_of_recipient_ids', candidateIds)
        let url = '/people/import_candidate'
        const result = await makeRequest(url, 'post', payload)
            .then((r) => {
                if (r?.data?.success == false) {
                    console.log(r?.data?.success)
                } else {
                    setTransactionData(r.data)
                    csvLink.current.link.click()
                }
            })
            .catch((e) => console.log(e))
    }

    const selectAllChanged = () => {
        const newCheckedState = !state.selectAllChecked
        if (newCheckedState) {
            dispatch({
                type: 'select_up_to_limit',
                limit: selectionLimit,
                page: page,
            })
        } else {
            dispatch({ type: 'deselect_all' })
        }
    }

    const handleOpenModal = () => {
        if (isEmpty(allSelectedCandidates)) {
            setValidationErrors({
                ...validationErrors,
                selectedcandidated: 'Please select candidate',
            })
            return
        } else {
            setOpenModal(true)
        }
    }

    const handleJobs = (orgId) => {
        if (!isEmpty(jobs) && ctMember && orgId) {
            setJobList(jobs?.filter((i)=>i?.organization_id == orgId)?.map((job) => ({ label:job.name+ ' [' +job.user.first_name+' '+job.user.last_name+']',value:job.id })))
        }
    }

    const handleSubmit = async () => {
        const candidates = allSelectedCandidates
        const candidateIds = candidates.map((candidate) => candidate.id)
        const jobId = selectedJobId
        const selectStageData = selectedStageValue
        const formData = new FormData()
        formData.append('candidate_ids', candidateIds)
        formData.append('job_id', jobId)
        formData.append('stage', selectStageData)
        formData.append('organization_id', selectedOrgId)
        const url = `/move_candidate_to_stage`
        try {
            const response = await makeRequest(url, 'post', formData, {
                contentType: 'multipart/form-data',
                loadingMessage: 'Submitting...',
                createResponseMessage: (response) => {
                    return {
                        message: response.message ? response.message : ' ',
                    }
                },
            })
        } catch (e) {
            console.error(e.message)
        }
        dispatch({ type: 'deselect_all' })
        setOpenModal(false)
    }

    // Refreshes the actual checked items if we change the selectionLimit
    // while "select all" is already checked
    useEffect(() => {
        if (state.selectAllChecked) {
            dispatch({
                type: 'select_up_to_limit',
                limit: selectionLimit,
                page: page,
            })
        }
    }, [selectionLimit])

    useEffect(() => {
        if (typeof personId == 'number') {
            setHistoryLoading(true)
            makeRequest(`/candidate_timeline_history`, 'get',  {params: {id: personId, job_id: jobId}}, {   
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    setHistory(res.data.notes)
                    setAllHistory(res.data.all_notes)
                    setReview(res.data.reviews)
                    setHistoryLoading(false)
                },
            })
        }
    }, [personId])

    if (loading) {
        return (
            <>
                <div
                    className="container"
                    style={{ textAlign: 'center', margin: '119px -2px' }}
                >
                    <h2>Loading....</h2>
                    <div id="loading" />
                </div>
            </>
        )
    }
    if (isEmpty(candidates)) {
        return (
            <>
                <div
                    className="container"
                    style={{ textAlign: 'center', margin: '119px -2px' }}
                >
                    <h2>No candidate found....</h2>
                </div>
            </>
        )
    }
    const handleJobFullName = (name) => {
        return name;
        // return name.length < 45 ? name : name.slice(0, 42) + '...'
    }
    function getSummaryRow(icon, title, value) {
        return (
            <div className="mb-4 d-flex">
                <div className={styles.iconContainer}>
                    <Image
                        src={icon}
                        style={{ width: '16px', height: '16px' }}
                    />
                </div>
                <div className="d-flex flex-column ml-3">
                    <span>{title}:</span>
                    <p>{value}</p>
                </div>
            </div>
        )
    }
    const handleHistoryModal = () => {
        setHistory([])
        setHistoryShow(false)
        setPersonId()
        setShowCurrentJob(candidateSource != 'candidate_search' ? true : false)
        setShowAllJob(candidateSource != 'candidate_search' ? false : true)
        setShowReview(false)
    }

    const handleEmailResponseModal = () => {
        setEmailReponseShow(false)
        setPersonId()
    }

    const handleResponseModal = (p_id) => {
        if (p_id != null) {
            setEmailReponseShow(true)
            makeRequest(`/candidate_automation_response`, 'get',  {params: {id: p_id, job_id: jobId}}, {   
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    let e_body = res?.data?.email_response?.formated_body 
                    let formattedText = e_body.split('\n').map((line, index) => (
                        <div key={index}>
                          {line.trim()}
                        </div>
                      ));
                    setEmailResponseTxt(formattedText)
                    setEmailResponseDate('')
                    if(res?.data?.email_response?.created_at != null && res?.data?.email_response?.created_at != ''){
                        setEmailResponseDate(moment(res?.data?.email_response?.created_at).format('llll'))
                    }
                     
                },
            })
        }
    }

    const closeInterviewScheduleModal = () => {
        setCheckInteviewStage(false)
        window.location.href = `/jobs/${jobId}`
    }

    const submitScheduleData = () => {
        // setDateError(true)
        // seTimeErrors(true)
        setDisable(false)
        setValidationDate(selectedDate == undefined ? 'Please select interview date' : '')
        setValidationTime((timezone.hour == '') && (timezone.minute == '') && 'Please select valid interview time ')
        setValidationTime(timeErrors && 'Please select interview time ')
        if (dateError == false && timeErrors == false) {
            setValidationDate('')
            setValidationTime('')
            var findId = allSelectedCandidates.map((i) => i.id)
            const person_ids = [...findId]
            const payload = new FormData()
            payload.append('interview_schedule[interview_date]', selectedDate)
            payload.append(
                'interview_schedule[interview_time]',
                JSON.stringify(timezone)
            )
            payload.append('interview_schedule[person_id]', person_ids)
            payload.append('interview_schedule[job_id]', jobs.id)
            payload.append(
                'interview_schedule[interview_type]',
                interviewTypeId
            )
            makeRequest('/submit_interview_schedule', 'post', payload, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
                createResponseMessage: (res) => {
                    setDisable(res?.success == true ? true :false)
                    changeStage(moveStage, moveJobId, moveSelectedCandidates, moveUserId,false)
                    return {
                        message: res.msg,
                        messageType: res.messageType
                    }
                },
            })
        }
    }

    const changeStage = (stage, jobId, selectedCandidates, userId, isInterview, isSubmissionStage=false) => {
        if(isInterview){
            setMoveStage(stage)
            setMoveJobId(jobId)
            setMoveSelectedCandidates(selectedCandidates)
            setMoveUserId(userId)
            setCheckInteviewStage(stage == 'second_interview' ? true :stage == 'first_interview' ? true :false )
        }else if(isSubmissionStage){
            setPersonOpoinionId(selectedCandidates)
            setGoodBadBoxShow(true)
            setMoveStage(stage)
            setMoveJobId(jobId)
            setMoveSelectedCandidates(selectedCandidates)
            setMoveUserId(userId)
            setCheckArchivedStage(false)
            if(stage == 'reject'){
                setCheckArchivedStage(true)
            }
        }else{
            const url = '/submissions/change_stage'
            let candidate_ids = selectedCandidates.filter(Boolean).map( candidate => (candidate.id) )
            if (selectedCandidates.some(item => item === undefined)) {
                 candidate_ids = (state.selectedCandidates).filter(Boolean).map( candidate => (candidate.id) )
            }
            if (candidate_ids.length == 0) return
            if(candidate_ids.length > 1 && stage == 'reject'){
                return
            }

            const payload = ({
                user_id: userId,
                job_id: jobId,
                candidate_ids,
                stage,
                submission_type: stage,
                organization_id: currentOrganization.id,
                source: 'ats',
                joining_date: joiningDate,
                move_to_reject: moveToArchived
            })
            makeRequest(url, 'put', payload, {
                loadingMessage: 'Submitting...',
                createResponseMessage: (responce) => {
                    if (responce.msg) {
                        dispatch({ type: 'deselect_all' })
                        setMoveToHired(false)
                        refreshCandidates()
                        // window.location.href = `/jobs/${jobId}`
                    }

                    return {
                        message: responce?.msg,
                        messageType: responce?.messageType,
                    }
                },
                createSuccessMessage: (response) =>
                    `Candidate${
                        candidate_ids.length > 1 ? 's' : ''
                    } successfully moved`,
            })
            // setTimeout(() => {
            //     setMoveToHired(false)
            //     window.location.href = `/jobs/${jobId}` 
            // }, 4000)
        }
    }

    const handleReview = (e) => {
        setPersonReview(e.value)
    }
    const handleReviewReason = (e) => {
        setPersonReviewReason(e.value)
        
    }
    const handleReviewReasonNote = (e) => {
        setPersonReviewReasonNote(e.target.value)
    }

    const submitReview = () => {
        setGoodBadBoxError(false)
        setGoodBadBoxRejectError(false)
        const url = '/submissions/person_review_store'
        const payload = ({
            review: (checkArchivedStage) ? 'Not Qualified' : 'Qualified',
            review_reason: personReviewReason,
            review_reason_note: personReviewReasonNote,
            jobId: jobId,
            person_id: state.selectedCandidates
        })
        let candidate_ids = (state.selectedCandidates).filter(Boolean).map( candidate => (candidate.id) )
        if ((state.selectedCandidates).some(item => item === undefined)) {
                candidate_ids = (state.selectedCandidates).filter(Boolean).map( candidate => (candidate.id) )
        }
        if(candidate_ids.length > 1 && checkArchivedStage){
            setGoodBadBoxRejectError(true)
            return false
        }else if(personReviewReason == 'Other (Specify Reason)' && personReviewReasonNote.length == 0){
            setGoodBadBoxError(true)
            return false
        
        }else{
            makeRequest(url, 'put', payload, {
                loadingMessage: 'Submitting...',
                createResponseMessage: (responce) => {
                    if (responce.messageType == 'success') {
                        setGoodBadBoxShow(false)
                        setGoodBadBoxRejectError(false)
                        changeStage(getstage, jobId, state.selectedCandidates, state.user.id,false, false)
                    }

                    return {
                        message: responce?.msg,
                        messageType: responce?.messageType,
                    }
                },
                createSuccessMessage: (response) =>
                    `Candidate review added successfully`,
            })
        }
        
        
    }
  const checktimeZone = (time) => {
    seTimeErrors((time.hour == '00') || (time.hour == '') || (time.timeZone == "Time-zone")  ? true : false)
  }
    return (
        <>
            <div>
                <InterviewScheduleModal
                setCheckInteviewStage={setCheckInteviewStage}
                checkInterviewStage={checkInterviewStage}
                allSelectedCandidates={state['selectedCandidates']}
                jobs={jobs}
                changeStage={changeStage}
                moveStage={moveStage}
                moveJobId={moveJobId}
                moveUserId={moveUserId}
                moveSelectedCandidates={state['selectedCandidates']}
                setInterviewTypeId={setInterviewTypeId}
                interviewTypeId={interviewTypeId}
                jobId={jobId}
                />
                <Modal
                    show={openModal}
                    onHide={handleCloseModal}
                    className="ats-border"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Move Candidate to ATS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ctMember && (<div style={{display: 'grid',marginLeft:'10px'}}>
                            <label>
                                Select Organization
                            </label>
                            <Select
                                defaultValue={handleJobFullName(selectedOrg)}
                                options={orgList}
                                onChange={(event)=>{
                                        setSelectedJobs('')
                                        setSelectedJobId('')
                                        handleJobs(event?.value)
                                        setSelectedOrg(event?.label)
                                        setSelectedOrgId(event?.value)
                                    }
                                }
                                name="organization"
                                className={styles.colourStyles}
                            />
                        </div>) }
                        
                        {(ctMember) && (<div style={{display: 'grid',marginLeft:'10px',marginTop:'10px'}}>
                            <label>
                                Select Jobs
                            </label>
                            <Select
                                defaultValue={handleJobFullName(selectedJobs)}
                                options={jobList}
                                onChange={(event)=>{
                                        console.log(event)
                                        setSelectedJobs(event.label)
                                        setSelectedJobId(event.value)
                                    }
                                }
                                name="jobs"
                                className={styles.colourStyles}
                            />
                        </div>) }
                        {(!ctMember) && (<div style={{display: 'grid',marginLeft:'10px',marginTop:'10px'}}>
                            <label>
                                Select Jobs
                            </label>
                            <Select
                                defaultValue={handleJobFullName(selectedJobs)}
                                options={selectedOrgId ? allJobList : []}
                                onChange={(event)=>{
                                        setSelectedJobs(event.label)
                                        setSelectedJobId(event.value)
                                    }
                                }
                                name="jobs"
                                className={styles.colourStyles}
                            />
                        </div>) }
                        {allStages && allStages.length > 0 && (<div style={{display: 'grid',marginLeft:'10px',marginTop:'10px'}}>
                            <label>
                                Select Stage
                            </label>
                            <Select
                                defaultValue={handleJobFullName(selectedStage)}
                                options={requiredStages}
                                onChange={(event)=>{
                                        setSelectedStage(event.label)
                                        setSelectedStageValue(event.id)
                                    }
                                }
                                name="stage"
                                className={styles.colourStyles}
                            />
                        </div>) }
                        
                    </Modal.Body>
                    <Modal.Footer>
                        {ctMember ? 
                            (<Button disabled={!selectedJobs || !selectedStage || !selectedOrg} variant="primary" onClick={handleSubmit}>Move to stage</Button>) : 
                        (<Button disabled={!selectedJobs || !selectedStage} variant="primary" onClick={handleSubmit}>Move to stage</Button>)}
                        
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div style={{float:'right !important'}}>
                    {enableStages && jobs.status != 'closed' && (
                        <StageSelector
                            allSelectedCandidates={allSelectedCandidates}
                            refreshCandidates={refreshCandidates}
                            jobId={jobId}
                            stage={stage}
                            currentOrganization = {currentOrganization}
                            setCheckInteviewStage={setCheckInteviewStage}
                            saveData={saveData}
                            setInterviewTypeId={setInterviewTypeId}
                            interviewTypeId={interviewTypeId}
                            setMoveToHired={setMoveToHired}
                            setMoveToArchived={setMoveToArchived}
                            changeStage={changeStage}
                            setGetStage={setGetStage}
                            setChecked={setChecked}
                        />
                    )}
            </div>
            <Modal
                show={historyShow}
                size="lg"
                dismissible
                onHide={handleHistoryModal}
                className="ats-border"
                backdrop="static"
                centered
                keyboard={true}
                onEscapeKeyDown={() => {
                    setPersonId()
                    setHistory([])
                    setHistoryShow(false)
                    setShowCurrentJob(candidateSource != 'candidate_search' ? true : false)
                    setShowAllJob(candidateSource != 'candidate_search' ? false : true)
                    setShowReview(false)
                }}>
                    <Modal.Header closeButton><Modal.Title>Candidate History</Modal.Title></Modal.Header>
                    <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ButtonGroup className={styles.buttonContainer}>
                                { candidateSource != 'candidate_search' &&
                                    <Button
                                        style={{
                                            background: `${
                                                showCurrentJob ? '#4C68FF' : ' #EBEDFA'
                                            }`,
                                            color: `${showCurrentJob ? ' #EBEDFA' : '#4C68FF'}`,
                                            borderRadius: '10px',
                                        }}
                                        disabled={loading}
                                        className={styles.jobButton}
                                        onClick={() => {
                                            setShowCurrentJob(true)
                                            setShowAllJob(false)
                                            setShowReview(false)
                                        }}
                                    >
                                        Current Job
                                    </Button>
                                }
                                    <Button
                                        style={{
                                            background: `${
                                                showAllJob
                                                    ? '#4C68FF'
                                                    : ' #EBEDFA'
                                            }`,
                                            color: `${
                                                showAllJob
                                                    ? ' #EBEDFA'
                                                    : '#4C68FF'
                                            }`,
                                            borderRadius: '10px',
                                        }}
                                        disabled={loading}
                                        className={styles.jobButton}
                                        onClick={() => {
                                            setShowCurrentJob(false)
                                            setShowAllJob(true)
                                            setShowReview(false)
                                        }}
                                    >
                                        All Jobs
                                    </Button>
                                    <Button
                                        style={{
                                            background: `${
                                                showReview
                                                    ? '#4C68FF'
                                                    : ' #EBEDFA'
                                            }`,
                                            color: `${
                                                showReview
                                                    ? ' #EBEDFA'
                                                    : '#4C68FF'
                                            }`,
                                            borderRadius: '10px',
                                        }}
                                        disabled={loading}
                                        className={styles.jobButton}
                                        onClick={() => {
                                            setShowCurrentJob(false)
                                            setShowAllJob(false)
                                            setShowReview(true)
                                        }}
                                    >
                                        Reviews
                                    </Button>
                                </ButtonGroup>
                                {!historyLoading && showCurrentJob ? (history && history?.length > 0) ? (
                                    <ul className="timeline-3">
                                    {history && history?.length > 0 ? history.map((note) => {
                                        let feedback = note.feedback
                                        if(note.feedback.includes("submitted"))
                                            feedback = note.feedback?.replaceAll('submitted', 'Submitted')
                                        if(note.feedback.includes("recruitor_screen"))
                                            feedback = note.feedback?.replaceAll('recruitor_screen', 'Recruiter Screened')
                                        if(note.feedback.includes("first_interview"))
                                            feedback = note.feedback?.replaceAll('first_interview', 'First Interview')
                                        if(note.feedback.includes("second_interview"))
                                            feedback = note.feedback?.replaceAll('second_interview', 'Second Interview')
                                        if(note.feedback.includes("offer"))
                                            feedback = note.feedback?.replaceAll('offer', 'Offers')
                                        if(note.feedback.includes("reject"))
                                            feedback = note.feedback?.replaceAll('reject', 'Archived')
                                        if(note.feedback.includes("applicant"))
                                            feedback = note.feedback?.replaceAll('applicant', 'Applicant')
                                        if(note.feedback.includes("hired"))
                                            feedback = note.feedback?.replaceAll('hired', 'Hired')
                                        if(note.feedback.includes("lead") || note.feedback.includes("Lead"))
                                            feedback = note.feedback?.replaceAll('Lead', 'Lead')
                                        return (
                                            <li>
                                                <span>{note.stage == "submitted" ? "Submitted" : note.stage == "recruitor_screen" ? 
                                                 "Recruiter Screened" : note.stage == "first_interview" ? "First Interview" : 
                                                 note.stage == "second_interview" ? "Second Interview" : note.stage == "offer" ? 
                                                 "Offers" : note.stage == "reject" ? "Archived" : note.stage == "applicant" ?
                                                 "Applicant" : note.stage == "hired" ? "Hired" : note.stage == "lead" ?
                                                 "Lead" : "" }</span>
                                                <span class="float-right">{moment(note.created_at).format('llll')}</span>
                                                <p class="mt-2" dangerouslySetInnerHTML={{ __html: feedback }}></p>
                                            </li>
                                        )
                                    }) : (<h5 className='text-center'>No History Available.</h5>)}
                                </ul>
                                ): (<h5 className='text-center'>No History Available.</h5>) : showCurrentJob && (
                                    <div className='container' style={{textAlign: 'center'}}>
                                        <div id="loading" />
                                    </div>
                                )  }
                                {!historyLoading && showAllJob ? (allHistory && allHistory?.length > 0) ? (
                                    <ul className="timeline-3">
                                    {allHistory && allHistory?.length > 0 ? allHistory.map((note) => {
                                        let feedback = note.feedback
                                        if(note.feedback.includes("submitted"))
                                            feedback = note.feedback?.replaceAll('submitted', 'Submitted')
                                        if(note.feedback.includes("recruitor_screen"))
                                            feedback = note.feedback?.replaceAll('recruitor_screen', 'Recruiter Screened')
                                        if(note.feedback.includes("first_interview"))
                                            feedback = note.feedback?.replaceAll('first_interview', 'First Interview')
                                        if(note.feedback.includes("second_interview"))
                                            feedback = note.feedback?.replaceAll('second_interview', 'Second Interview')
                                        if(note.feedback.includes("offer"))
                                            feedback = note.feedback?.replaceAll('offer', 'Offers')
                                        if(note.feedback.includes("reject"))
                                            feedback = note.feedback?.replaceAll('reject', 'Archived')
                                        if(note.feedback.includes("applicant"))
                                            feedback = note.feedback?.replaceAll('applicant', 'Applicant')
                                        if(note.feedback.includes("hired"))
                                            feedback = note.feedback?.replaceAll('hired', 'Hired')
                                        if(note.feedback.includes("lead") || note.feedback.includes("Lead"))
                                            feedback = note.feedback?.replaceAll('Lead', 'Lead')
                                        return (
                                            <li>
                                                <span>{note.stage == "submitted" ? "Submitted" : note.stage == "recruitor_screen" ? 
                                                 "Recruiter Screened" : note.stage == "first_interview" ? "First Interview" : 
                                                 note.stage == "second_interview" ? "Second Interview" : note.stage == "offer" ? 
                                                 "Offers" : note.stage == "reject" ? "Archived" : note.stage == "applicant" ?
                                                 "Applicant" : note.stage == "hired" ? "Hired" : note.stage == "lead" ?
                                                 "Lead" : "" }</span>
                                                <span class="float-right">{moment(note.created_at).format('llll')}</span>
                                                <p class="mt-2">{note.stage == "lead" ? `${feedback} - ${note.name}` : feedback }</p>
                                            </li>
                                        )
                                    }) : (<h5 className='text-center'>No History Available.</h5>)}
                                </ul>
                                ): (<h5 className='text-center'>No History Available.</h5>) : showAllJob && (
                                    <div className='container' style={{textAlign: 'center'}}>
                                        <div id="loading" />
                                    </div>
                                )}
                                {!historyLoading && showReview ? (review && review?.length > 0) ? (
                                    <ul className="timeline-3">
                                    {review && review?.length > 0 ? review.map((rv) => {
                                        return (
                                            <li>
                                                
                                                <span>{ ((rv.review_type).includes('Bad') || (rv.review_type).includes('Not Qualified')) ? 'Archived' : 'Submitted'  }</span>
                                                <span class="float-right">{moment(rv.created_at).format('llll')}</span>
                                                <p class="mt-2">The review given by <b>{rv.first_name} {rv.last_name}</b> for the candidate indicates that the candidate is <b>{rv.review_type}</b> for the <b>{rv.job_name}</b> at <b>{rv.org_name}</b>.</p>
                                                {rv.reason != '' && rv.reason != null &&
                                                 <p class="mt-2"><b>Reason: </b> {rv.reason}</p>
                                                }
                                                {rv.reason_note != '' && rv.reason_note != null &&
                                                    <p class="mt-2"><b>Note: </b> {rv.reason_note}</p>
                                                }
                                            </li>
                                        )
                                    }) : (<h5 className='text-center'>No History Available.</h5>)}
                                </ul>
                                ): (<h5 className='text-center'>No History Available.</h5>) : showReview && (
                                    <div className='container' style={{textAlign: 'center'}}>
                                        <div id="loading" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={emailReponseShow}
                size="lg"
                dismissible
                onHide={handleEmailResponseModal}
                className="ats-border"
                backdrop="static"
                centered
                keyboard={true}
                onEscapeKeyDown={() => {
                    setPersonId()
                    setEmailReponseShow(false)
                }}>
                    <Modal.Header closeButton >
                        <Modal.Title>Automation Email Response</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ borderTop: '0.5px solid #ebedfa'}}>
                    <div className="container">
                        <div className="row" style={{float: 'right'}}> {emailResponseDate}</div>
                        <div className="row">
                            <div>{emailResponseTxt}</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={scoreDetailOpen}
                onHide={() => setScoreDetailOpen(false)}
                size="lg"
                dismissible
                className="ats-border"
                backdrop="static"
                centered
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Match Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <p> {scoreDetail} </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setScoreDetailOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={goodBadBoxShow}
                size="md"
                dismissible="true"
                onHide={() => setGoodBadBoxShow(false)}
                style={{height:'850px'}}
            >
                <Modal.Header  closeButton className='pb-0'>
                    <Modal.Title>Candidate Review :</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-5' style={{height: (checkArchivedStage) ? '475px' : '280px'}}>
                    <div>
                        <div>
                            <label>
                                Select Review* :
                            </label>
                            <Select
                                options={ (checkArchivedStage) ? [{label: 'Not Qualified', value: 'Not Qualified'}] : [{label: 'Qualified', value: 'Qualified'}]}
                                onChange={(event)=>{
                                    handleReview(event)
                                    }
                                }
                                defaultValue={(checkArchivedStage) ? {label: 'Not Qualified', value: 'Not Qualified'} : {label: 'Qualified', value: 'Qualified'}}
                                name="review"
                                className={styles.colourStyles}
                            />
                        </div>
                        { (checkArchivedStage) && 
                            <div className='mt-2'>
                                <label>
                                    Select Reason* :
                                </label>
                                <Select
                                    options={[
                                        { label: 'Short Job Stints or Unstable Work History', value: 'Short Job Stints or Unstable Work History' },
                                        { label: 'Lack of Relevant Experience', value: 'Lack of Relevant Experience' },
                                        { label: 'Poor Cultural Fit or Personality Mismatch', value: 'Poor Cultural Fit or Personality Mismatch' },
                                        { label: 'Communication Skills or Professionalism Concerns', value: 'Communication Skills or Professionalism Concerns' },
                                        { label: 'Negative References or Performance Concerns', value: 'Negative References or Performance Concerns' },
                                        { label: 'Ethical Concerns or Legal Issues', value: 'Ethical Concerns or Legal Issues' },
                                        { label: 'Salary Expectations', value: 'Salary Expectations' },
                                        { label: 'Limited Availability', value: 'Limited Availability' },
                                        { label: 'Work Authorization or Visa Restrictions', value: 'Work Authorization or Visa Restrictions' },
                                        { label: 'Location Preferences', value: 'Location Preferences' },
                                        { label: 'Client Declined without feedback', value: 'Client Declined without feedback'},
                                        { label: 'Candidate declines the offer', value: 'Candidate declines the offer'},
                                        { label: 'Other (Specify Reason)', value: 'Other (Specify Reason)' }
                                    ]}
                                    onChange={(event)=>{
                                        handleReviewReason(event)
                                        }
                                    }
                                    name="review"
                                    className={styles.colourStyles}
                                />
                            </div>
                        }
                        <div className='mt-2'>
                            <label>
                                Specify Reason :
                            </label>
                            <textarea className='form-control' row="3" onChange={(event)=>{
                                    handleReviewReasonNote(event)
                                    }
                                }></textarea>
                                { goodBadBoxError == true &&
                                    <p className='mt-2' style={{ color: '#f52323',fontSize: '13px'}}> Please provide a reason.</p>
                                }
                                { goodBadBoxRejectError == true &&
                                    <p className='mt-2' style={{ color: '#f52323',fontSize: '13px'}}> Multiple candidates are not allowed to be archived.</p>
                                }
                                
                         </div>
                         <div className='mt-1'>
                                {checkArchivedStage &&
                                     <span style={{ color: '#f52323'}}> Note:  Specify reason is required if 'Other' is selected as the reason.</span>
                                }
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <Button style={{marginTop:'10px',marginRight:'20px'}}  className='btn btn-primary' onClick={() => setGoodBadBoxShow(false)}>
                                Cancel
                            </Button>
                            <Button  className='btn btn-success' style={{marginTop:'10px'}} onClick={() => submitReview()}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={moveToHired}
                size="lg"
                dismissible="true"
                onHide={() => setMoveToHired(false)}
                className="ats-border joining-date-modal" 
                backdrop="static"
                centered
            >
                <Modal.Header style={{ marginBottom: '-44px' }} closeButton>
                    <Modal.Title>Please Select Joining Date:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div style={{display:'grid'}}>
                        <Calendar 
                            onChange={onChange}
                            value={joiningDate} 
                        />
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                        <Button style={{marginTop:'20px'}} onClick={() => changeStage(getstage, jobId, state.selectedCandidates, state.user.id)}>
                            Submit
                        </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {moveToArchived &&
                <SweetAlert
                    className='sweet-alert'
                    warning
                    title='Are you sure?'
                    showCloseButton={false}
                    showCancel={true}
                    showConfirm={true}
                    confirmBtnText="Yes"
                    CancelBtnText="No"
                    onCancel={() => setMoveToArchived(false)}
                    onConfirm={() => { setChecked(false)
                        changeStage(getstage, jobId, selectedCandidates, state.user.id)}}
                    cancelBtnStyle={{textDecoration: 'none'}}
                >
            </SweetAlert>
            }
            <Table className={candidatePage ? styles.candidateTable : styles.table  }  responsive hover>
            <>
          {Object.values(validationErrors).map((error) => (
                            <Alert
                                key={error}
                                variant="danger"
                                onClose={() => setValidationErrors({})}
                                dismissible
                                className='candidate-table-close'
                            >
                                {error}
                            </Alert>
                        ))}
                        </>

                <thead className={candidatePage ? '' : 'thead-ATS'}>
                    <tr>
                        {columns.map((col, index) => {
                            switch (col) {
                                case 'select':
                                    return getSelectTH(index)
                                case 'image':
                                    return getCandidateImageTH(index)
                                case 'candidate':
                                    return getCandidateTH(index)
                                case 'match':
                                    return getMatchTH(index)
                                case 'skills':
                                    return getSkillsTH(index)
                                case 'last-contacted':
                                    return getLastContactedTH(index)
                                case 'message':
                                    return getMessageTH(index)
                                case 'moved_from':
                                    return getMovedFromTH(index)
                                case 'applied':
                                    return getAppliedTH(index)
                                case 'grading':
                                    return getGradingTH(index)
                                case 'candidate_search':
                                    return getCandidateCheck(index)
                                case 'moved_by':
                                    return getMovedByTH(index)
                                case 'submissions_detail':
                                    return getApplicationCountTH(index)
                                case 'history':
                                    return getViewHistory()
                                default:
                                    return (
                                        <td className={styles.td}>
                                            unknown column: {col}
                                        </td>
                                    )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(candidates) &&
                        candidates.map((candidate, index) => {
                            return (
                                <CandidateRow
                                    candidate={candidate}
                                    jobId={jobId}
                                    cols={columns}
                                    jobs={jobs}
                                    idx={absoluteIndex(page, index)}
                                    key={index}
                                    clickHandler={candidateClickHandler}
                                    lastContact={lastContact}
                                    setLastContact={setLastContact}
                                    application={application}
                                    setApplication={setApplication}
                                    lastActive={lastActive}
                                    setLastActive={setLastActive}
                                    setSubmissionData={setSubmissionData}
                                    submissionData={submissionData}
                                    candidateSource={candidateSource}
                                    memberOrganization={memberOrganization}
                                    currentOrganization={currentOrganization}
                                    candidate_history={candidate_history}
                                    filterSkills={skills}
                                    setHistoryShow={setHistoryShow}
                                    handleResponseModal={handleResponseModal}
                                    setPersonId={setPersonId}
                                    freeCandidates={freeCandidates}
                                    ctMember={ctMember}
                                    isEmailSend={ (listEmailSendCandidate.includes(candidate.id)) ? true : false }
                                    setPersonOpoinionId={setPersonOpoinionId}
                                    setGoodBadBoxShow={setGoodBadBoxShow}
                                    handleScoreModal={handleScoreModal}
                                />
                            )
                        })}
                </tbody>
            </Table>
            <div className={styles.tableFooter}>
                {totalPages > 1 && (
                    <Paginator
                        pageCount={totalPages}
                        pageWindowSize={5}
                        activePage={page}
                        setActivePage={(num) => handlePageChangeClick(num)}
                    />
                )}
            </div>
        </>
    )

    function getSelectTH() {
        return (
            <>
            <th key="select" className={styles.th}>
                {/* <BulkSelector
                    value={selectionLimit}
                    checked={state.selectAllChecked}
                    onCheckChange={selectAllChanged}
                    onInputChange={(e) => {
                        if (e.target.value == '') {
                            setSelectionLimit(0)
                            return
                        }
                        setSelectionLimit(
                            Math.min(
                                MAX_SELECTION_LIMIT,
                                totalCandidateCount,
                                parseInt(e.target.value)
                            )
                        )
                    }}
                /> */}
            </th>
            </>
        )
    }
    function getCandidateTH() {
        return (
            <th key="candidate" className={styles.th}>
                Candidate
            </th>
        )
    }

    function getCandidateImageTH() {
        return (
            <th
                key="candidateimage"
                className={styles.th}
                style={{ textAlign: 'center', paddingRight: '40px' }}
            >
                Image
            </th>
        )
    }

    function getCandidateCheck() {
        return (
            <>
               {pageName != 'free' && <div style={{ float: 'left' }}>{getSelectTH()}</div> }
               
                {!state.selectAllChecked && (
                    <label
                        className={styles.labelHeader}
                        style={{
                            marginTop: '15px',
                            float: 'left',
                            fontSize: '11px',
                        }}
                    >
                        {/* {pageName == 'free' ? <>&nbsp;</> : 'Select All'} */}
                    </label>
                )}
                {displayCount}

               {pageName != 'free' && <div className="importButton">
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            className="dropdown-text"
                        >
                            Services
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleOpenModal}>
                                Move to ATS
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleImport}>
                                Export Linkedin URL
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>}
                
                <CSVLink
                    data={transactionData}
                    filename="transactions.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                />
            </>
        )
    }
    function getMatchTH() {
        return (
            <th
                key="match"
                className={styles.th}
                style={{ textAlign: 'center',cursor: 'pointer' }}
                onClick={() => requestSort('match') }
            >
                Percentile Match {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </th>
        )
    }
    function getSkillsTH() {
        return (
            <th key="skills" className={styles.th}>
                Skills
            </th>
        )
    }
    function getLastContactedTH() {
        return (
            <th key="last-contacted" className={styles.th}>
                Last contacted
            </th>
        )
    }
    function getMessageTH() {
        return (
            <th key="message" className={`${styles.th}`}>
                <div className={styles.messageTHContainer}>Message </div>
            </th>
        )
    }
    function getMovedFromTH() {
        return (
            <th key="moved_from" className={styles.th}>
                {stage === 'lead' ? 'Uploaded At' : 'Moved From'}
            </th>
        )
    }
    function getAppliedTH() {
        return (
            <th key="applied" className={styles.th}>
                {stage === 'lead' ? 'Uploaded At' : 'Moved At'}
            </th>
        )
    }
    function getGradingTH() {
        return (
            <th key="grading" className={styles.th}>
                Grading
            </th>
        )
    }

    function getMovedByTH() {
        return (
            <th key="moved_by" className={styles.th}>
                {stage === 'lead' ? 'Uploaded By' : 'Moved By'}
            </th>
        )
    }

    function getApplicationCountTH() {
        return (
            <th key="submissions_detail" className={styles.th}>
                Submissions
            </th>
        )
    }

    function getViewHistory() {
        return (
            <th key="history" className={styles.th}>
                History
            </th>
        )
    }
}

function BulkSelector({ value, checked, onCheckChange, onInputChange }) {
    return (
        <div style={{ position: 'relative' }}>
            <Form.Check
                type="checkbox"
                name="checkAll"
                checked={checked}
                onChange={onCheckChange}
            />
            <span className={styles.bulkSelector}>Select All</span>
            {checked && (
                <span className={styles.bulkSelector}>
                    Select
                    <input
                        value={value}
                        onChange={onInputChange}
                        className={styles.bulkSelectorInput}
                        type="text"
                        readOnly="readOnly"
                    />
                    candidates
                </span>
            )}
        </div>
    )
}

function CandidateRow({
    candidate,
    jobId,
    jobs,
    cols,
    idx,
    clickHandler,
    setLastContact,
    lastContact,
    setLastActive,
    lastActive,
    setApplication,
    application,
    candidateSource,
    setSubmissionData,
    submissionData,
    memberOrganization,
    currentOrganization,
    candidate_history,
    filterSkills,
    setHistoryShow,
    handleResponseModal,
    setPersonId,
    freeCandidates,
    ctMember,
    isEmailSend,
    setPersonOpoinionId,
    setGoodBadBoxShow,
    handleScoreModal
}) {
    const { state, dispatch } = useContext(StoreDispatchContext)
    const checked = (state.selectedCandidates[idx]) ? true : false
    const [checkInterviewStage, setCheckInteviewStage] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [candidateId, setCandidateId] = useState('')
    const [employerComments, setEmployerComments] = useState('')
    const [panding, setpanding] = useState()
	const [validationError, setValidationError] = useState({})
	const [btnDisabled, setBtnDisabled] = useState(false)
    const [selectedOrgId, setSelectedOrgId] = useState('')
    const [selectedJobId, setSelectedJobId] = useState('')
    const [visible, setVisible] = useState(6)
    const [selectedJobs, setSelectedJobs] = useState('')
    const loadMore = () => {
        setVisible(visible + 6)
    }
   
    const handleJobFullName = (name) => {
        return name.length < 45 ? name : name.slice(0, 42) + '...'
    }
    const loadLess = () => {
        setVisible(6)
    }
    const SendRequestInterview = (id) => {

    }
    const handleCheckboxChange = (event) => {
        // setCheckInteviewStage(true)
        dispatch({
            type: 'toggle_candidate_selection',
            candidate: !checked ? candidate : false,
            index: idx,
        })
    }

    const validateForm = (e) => {
		let errors = {}
		let formIsValid = true
		if(!(employerComments?.trim())){
			formIsValid = false;
			errors["description"] = "description is required field."
		}
		setValidationError(errors)
		setBtnDisabled(formIsValid)
		return formIsValid;
	}
    
    const FindFreeCandidate = freeCandidates?.filter((item) => item?.person_id == candidateId)
    const HandleSaveModal = () => {
		if(validateForm(employerComments.length > 0)){
        const payload = new FormData()
        payload.append('free_candidates_details[employer_comment]', employerComments)
        payload.append('free_candidates_details[person_id]',candidateId)
        payload.append('free_candidates_details[approval_status]',false)
        payload.append('free_candidates_details[job_id]',selectedJobId)
        makeRequest('/free_candidate_search', 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (res) => {
                if (res?.success){
                    setpanding(res.data.approval_status)
                    setShowModal(false)
                       window.location.reload();
                }
                return {
                    message: res.msg,
                    messageType: res.messageType
                }
            },
        })
    }
    }
    return (
        <>
            <tr
                onClick={(e) => {
                    if (
                        e.type === 'click' &&
                        (e.target.localName == 'img' ||
                            e.target.localName == 'h6' ||
                            e.target.localName == 'label')
                    ) {
                        {
                            e.target.innerText === 'Last Contact'
                                ? handleActivityHandler(
                                      'activeLastContact',
                                      candidate
                                  )
                                : e.target.innerText === 'Last Active'
                                ? handleActivityHandler(
                                      'activeLastActive',
                                      candidate
                                  )
                                : e.target.innerText === 'Applications'
                                ? handleActivityHandler(
                                      'application',
                                      candidate
                                  )
                                : e.target.innerText === 'Submitted'
                                ? handleActivityHandler(
                                      'activeSubmission',
                                      candidate
                                  )
                                : ''
                        }
                    } else {
                        if (candidateSource === 'submitted_candidates') {
                            clickHandler(candidate)
                        }
                    }
                }}
            >
                {cols.map((col) =>
                    getCellForCol(candidate, col, loadMore, candidate_history,isEmailSend)
                )}
            </tr>
        </>
    )

    function handleActivityHandler(activeField, candidate) {
        switch (activeField) {
            case 'activeLastContact':
                setLastContact({
                    active: true,
                    id: candidate.id,
                })
                setLastActive({
                    active: false,
                    id: null,
                })
                setApplication({
                    active: false,
                    id: null,
                })
                setSubmissionData({
                    active: false,
                    id: null,
                })
                return 'setLastContact'
            case 'activeLastActive':
                setLastActive({
                    active: true,
                    id: candidate.id,
                })
                setApplication({
                    active: false,
                    id: null,
                })
                setLastContact({
                    active: false,
                    id: null,
                })
                setSubmissionData({
                    active: false,
                    id: null,
                })
                return 'activeLastActive'
            case 'application':
                setApplication({
                    active: true,
                    id: candidate.id,
                })
                setLastContact({
                    active: false,
                    id: null,
                })
                setLastActive({
                    active: false,
                    id: null,
                })
                setSubmissionData({
                    active: false,
                    id: null,
                })
                return 'application'
            case 'activeSubmission':
                setSubmissionData({
                    active: true,
                    id: candidate.id,
                })
                setApplication({
                    active: false,
                    id: null,
                })
                setLastContact({
                    active: false,
                    id: null,
                })
                setLastActive({
                    active: false,
                    id: null,
                })
                return 'activeSubmission'
            default:
                console.error('Unknown column', activeField)
                return (
                    <td className={styles.td}>unknown column: {activeField}</td>
                )
        }
    }

    function getCellForCol(candidate, col, loadMore, candidate_history, isEmailSend) {
        switch (col) {
            case 'select':
                return getSelectCell(candidate, col)
            case 'image':
                return getCandidateImage(candidate, col)
            case 'candidate':
                return getCandidateCell(candidate, candidate_history)
            case 'match':
                return getMatchCell(candidate, handleScoreModal)
            case 'skills':
            return getSkillsCell(candidate, col)
            case 'last-contacted':
                return getLastContactedCell(candidate, col)
            case 'message':
                return getMessageCell(candidate, col)
            case 'moved_from':
                return getMovedFromCell(candidate)
            case 'applied':
                return getAppliedCell(candidate)
            case 'grading':
                return getGradingCell(candidate)
            case 'candidate_search':
                return getCandidateDetail(candidate, loadMore)
            case 'moved_by':
                return getMovedByCell(candidate)
            case 'submissions_detail':
                return getApplicationCountCell(candidate)
            case 'history':
                return getCandidateHistory(
                    candidate,
                    setHistoryShow,
                    handleResponseModal,
                    setPersonId,
                    isEmailSend,
                    setPersonOpoinionId,
                    setGoodBadBoxShow
                )
            default:
                console.error('unknown column: ', col)
                return <td className={styles.td}>unknown column: {col}</td>
        }
    }

    function canidateSkills(skill, index) {
        return (
            <>
                <Badge
                    pill
                    key={index}
                    variant="secondary"
                    className="skills-button d-inline-block text-truncate"
                >
                    <label className="candidate-skills"> {skill} </label>
                </Badge>
                {'   '}
            </>
        )
    }

    function canidateSkillATS(skill, index) {
        return (
            <>
                <Badge
                    pill
                    key={index}
                    variant="secondary"
                    className="skill-ats-button"
                >
                    <label> {skill} </label>
                </Badge>
                {'   '}
            </>
        )
    }

    function getCandidateDetail(candidate, loadMore) {
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
        let skills = candidate.skills.toLowerCase().split(',')
        if (filterSkills.length > 0) {
            filterSkills.map((skill) => {
                skills.map((value, index) => {
                    let cindex = value
                        .toLowerCase()
                        .trim()
                        .indexOf(skill.toLowerCase())
                    if (cindex != -1) {
                        if (index >= 5) {
                            let skillname = skills[index]
                            skills.splice(index, 1)
                            skills.splice(0, 0, skillname)
                        }
                    }
                })
            })
        }

        return (
            <>
                <Card
                    key={candidate.id}
                    className={styles.candidateProfileCard}
                >
                    <Card.Body className="candidate-list">
                    {pageName != 'free' && <>
                        {getSelectCell(candidate)}
                    </>}
                    <InterviewScheduleModal
                    candidateId={candidate.id}
                    setCheckInteviewStage={setCheckInteviewStage}
                    checkInterviewStage={checkInterviewStage}
                    allSelectedCandidates={''}
                    jobs={''}
                    moveSelectedCandidates={''}
                    setInterviewTypeId={''}
                    interviewTypeId={''}
                    jobId={jobId}
                    />
                       {pageName == 'free' && <div style={{display: 'flex', justifyContent:'end'}}>
                              <div className='abcd'>
                              
                              {candidate?.colour_id == 0 && <button 
                               onClick={() => {
                                setShowModal(true)
                                setCandidateId(candidate.id)
                                SendRequestInterview(candidate?.id)
                                }} 
                                className={'request-interview' }>
                               Request Interview
                                </button>}
                                {candidate?.colour_id == 1 && <button 
                               onClick={() => {
                                // setShowModal(true)
                                // setCandidateId(candidate.id)
                                }} 
                                className={'panding-interview' }>
                               Waiting for Confirmation
                                </button>}
                                {candidate?.colour_id == 2 && <button 
                               onClick={() => {
                                setCheckInteviewStage(true)
                                }} 
                                className='schedule-interview'>
                                Schedule Interview
                                </button>}

                                {candidate?.colour_id == 3 && <button 
                               onClick={() => {
                                // setCheckInteviewStage(true)
                                }} 
                                className='reject-interview'>
                                Request Rejected
                                </button>}

                              </div>
                        </div>
                        }
                        <div >
                        <div style={{ margin: '-48px 0 0 35px' }}>
                            <div
                                className="row"
                                style={{ marginBottom: '11px' }}
                            >
                                {candidate.image_url ? (
                                    <img
                                        className={styles.candidateProfileImage}
                                        src={
                                            candidate.image_url
                                                ? candidate.image_url
                                                : profileImage
                                        }
                                        onClick={(e) => clickHandler(candidate)}
                                    />
                                ) : (
                                    <CandidateAvatarIcon
                                        clickHandler={clickHandler}
                                        candidate={candidate}
                                        firstName={candidate?.first_name}
                                        lastName={candidate?.last_name}
                                    />
                                )}
                                <div className={styles.cardBody + 'container'}>
                                    <h5 className={styles.cardBodyName}>
                                        <span
                                            onClick={(e) =>{
                                                clickHandler(candidate)
                                            }
                                            }
                                        >
                                            {Util.handleUndefinedFullName(
                                                candidate?.first_name,
                                                candidate?.last_name
                                            )}
                                        </span>
                                        {candidate.linkedin_profile_url && (
                                            <a
                                                href={
                                                    candidate.linkedin_profile_url
                                                }
                                                target="_blank"
                                            >
                                                <Image
                                                    src={LinkedinIcon}
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginLeft: '15px',
                                                        marginTop: '-1px',
                                                    }}
                                                />
                                            </a>
                                        )}
                                        {candidate.resume_url && (
                                            <a
                                                href={candidate.resume_url}
                                                target="_blank"
                                            >
                                                <Image
                                                    src={PdfIcon}
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        marginLeft: `${
                                                            candidate.linkedin_profile_url
                                                                ? '5px'
                                                                : '15px'
                                                        }`,
                                                        marginTop: '-1px',
                                                    }}
                                                />
                                            </a>
                                        )}
                                        <span><img
                                            onClick={() => {
                                                setPersonId(candidate.id)
                                                setHistoryShow(true)
                                            }}
                                            className={`${styles.eyeIcon} ${styles.pointer}`}
                                            src={eyeImage}
                                        /></span>
                                    </h5>
                                    <h5
                                        style={{ fontSize: '16px' }}
                                        onClick={(e) => clickHandler(candidate)}
                                    >
                                        {candidate.title}
                                    </h5>
                                    <p
                                        style={{ fontSize: '13px' }}
                                        onClick={(e) => clickHandler(candidate)}
                                    >
                                        {candidate.location}
                                    </p>
                                </div>
                            </div>
                            {candidate?.experiences && (
                                <div
                                    className="container row"
                                    onClick={(e) => clickHandler(candidate)}
                                >
                                    {/* <div>
                                <h6 >Experience</h6>  
                            </div>
                            <Col md={5}>
                                 <p className={styles.descriptionPage}>{candidate?.experiences}</p>
                            </Col> */}
                                </div>
                            )}
                            {candidate.degree && (
                                <div
                                    className="container row"
                                    onClick={(e) => clickHandler(candidate)}
                                >
                                    <div>
                                        <h6>Education</h6>
                                    </div>
                                    <Col md={4}>
                                        <p className={styles.descriptionPage}>
                                            {candidate.degree}
                                        </p>
                                    </Col>
                                </div>
                            )}
                            {candidate.skills && (
                                <div className="container row">
                                    <div>
                                        <h6 className={styles.skillTitle}>
                                            Skills
                                        </h6>
                                    </div>
                                    <div className={styles.badge}>
                                        {skills
                                            .slice(0, visible)
                                            .map((skill, index) =>
                                                canidateSkills(skill, index)
                                            )}
                                        {visible < skills.length && (
                                            <Badge
                                                pill
                                                variant="secondary"
                                                className="skills-more-button  d-inline-block text-truncate"
                                                onClick={loadMore}
                                            >
                                                <label>View More</label>
                                            </Badge>
                                        )}
                                        {visible >= skills.length &&
                                            skills.length > 6 && (
                                                <Badge
                                                    pill
                                                    variant="secondary"
                                                    className="skills-more-button  d-inline-block text-truncate"
                                                    onClick={loadLess}
                                                >
                                                    <label>View Less</label>
                                                </Badge>
                                            )}
                                    </div>
                                </div>
                            )}
                            <div
                                className="container row"
                                style={{ alignItems: 'center' }}
                            >
                                <div>
                                    <h6>Activity</h6>
                                </div>{' '}
                                {'  '}
                                <div
                                    className="activity-class"
                                    style={{ display: 'contents' }}
                                >
                                    <div className="activity-field">
                                        <h6
                                            style={{
                                                backgroundColor:
                                                    lastContact.id ==
                                                        candidate.id &&
                                                    lastContact.active
                                                        ? '#E4EAFF'
                                                        : '',
                                                borderRadius:
                                                    lastContact.id ==
                                                        candidate.id &&
                                                    lastContact.active
                                                        ? '20px'
                                                        : '',
                                                padding: '12px 12px',
                                            }}
                                        >
                                            <img src={lastContactIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                {' '}
                                                Last Contact{' '}
                                            </label>
                                        </h6>
                                    </div>
                                    <div className="activity-field">
                                        <h6
                                            style={{
                                                backgroundColor:
                                                    application.id ==
                                                        candidate.id &&
                                                    application.active
                                                        ? '#E4EAFF'
                                                        : '',
                                                borderRadius:
                                                    application.id ==
                                                        candidate.id &&
                                                    application.active
                                                        ? '20px'
                                                        : '',
                                                padding: '12px 12px',
                                            }}
                                        >
                                            <img src={applicationIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                                style={{
                                                    // background: application ? '#E4EAFF' : '',
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                Applications
                                            </label>
                                            {candidate.applications.filter(
                                                (s) =>
                                                    s?.organization_id ===
                                                    currentOrganization?.id
                                            ).length != 0 && (
                                                <Badge
                                                    className={
                                                        styles.applicationsCount
                                                    }
                                                    id=""
                                                    variant="light"
                                                >
                                                    {
                                                        candidate.applications.filter(
                                                            (s) =>
                                                                s?.organization_id ===
                                                                currentOrganization?.id
                                                        ).length
                                                    }
                                                </Badge>
                                            )}
                                        </h6>
                                    </div>
                                    <div className="activity-field">
                                        <h6
                                            style={{
                                                backgroundColor:
                                                    lastActive.id ==
                                                        candidate.id &&
                                                    lastActive.active
                                                        ? '#E4EAFF'
                                                        : '',
                                                borderRadius:
                                                    lastActive.id ==
                                                        candidate.id &&
                                                    lastActive.active
                                                        ? '20px'
                                                        : '',
                                                padding: '12px 12px',
                                            }}
                                        >
                                            <img src={lastActivityIcon} />
                                            <label
                                                className={
                                                    styles.activityFieldTitle
                                                }
                                                style={{
                                                    borderRadius: application
                                                        ? '20px'
                                                        : '',
                                                }}
                                            >
                                                {' '}
                                                Last Active{' '}
                                            </label>
                                        </h6>
                                    </div>
                                    {memberOrganization && (
                                        <div className="activity-field">
                                            <h6
                                                style={{
                                                    backgroundColor:
                                                        submissionData.id ==
                                                            candidate.id &&
                                                        submissionData.active
                                                            ? '#E4EAFF'
                                                            : '',
                                                    borderRadius:
                                                        submissionData.id ==
                                                            candidate.id &&
                                                        submissionData.active
                                                            ? '20px'
                                                            : '',
                                                    padding: '12px 12px',
                                                }}
                                            >
                                                <img src={SubmittedIcon} />
                                                <label
                                                    className={
                                                        styles.activityFieldTitle
                                                    }
                                                    style={{
                                                        borderRadius:
                                                            submissionData
                                                                ? '20px'
                                                                : '',
                                                    }}
                                                >
                                                    Submitted
                                                </label>
                                                {candidate.submissions.length !=
                                                    0 && (
                                                    <Badge
                                                        className={
                                                            styles.applicationsCount
                                                        }
                                                        id=""
                                                        variant="light"
                                                    >
                                                        {
                                                            candidate
                                                                .submissions
                                                                .length
                                                        }
                                                    </Badge>
                                                )}
                                            </h6>
                                        </div>
                                    )}
                                    {lastContact.id == candidate.id &&
                                        lastContact.active &&
                                        !isEmpty(
                                            candidate.last_contacted_content
                                        ) && (
                                            <div className="container">
                                                <hr />
                                                <div className="row contact_time">
                                                    <img
                                                        src={lastContactIcon}
                                                        style={{
                                                            height: '19px',
                                                            marginRight: '12px',
                                                        }}
                                                    />{' '}
                                                    1 email received from{' '}
                                                    {state.user.first_name} on{' '}
                                                    {moment(
                                                        application.last_contacted_time
                                                    ).format(
                                                        'MMM Do YYYY'
                                                    )}{' '}
                                                    <br />{' '}
                                                    {
                                                        candidate.last_contacted_content
                                                    }
                                                </div>
                                            </div>
                                        )}

                                    {application.id == candidate.id &&
                                        application.active && (
                                            <div className="container">
                                                <hr />
                                                <p> Recent Application</p>
                                                {candidate.applications
                                                    .filter(
                                                        (s) =>
                                                            s?.organization_id ===
                                                            currentOrganization?.id
                                                    )
                                                    .sort((a, b) =>
                                                        a.submission
                                                            ?.created_at >
                                                        b.submission?.created_at
                                                            ? -1
                                                            : 1
                                                    )
                                                    .map((application) => (
                                                        <ul
                                                            style={{
                                                                width: '100%',
                                                                marginBottom:
                                                                    '0',
                                                            }}
                                                        >
                                                            <li>
                                                                <div className="row contact_time">
                                                                    {
                                                                        application.name
                                                                    }
                                                                    {application?.stage && (
                                                                        <b
                                                                            style={{
                                                                                marginLeft:
                                                                                    '5px',
                                                                            }}
                                                                        >
                                                                            Stage
                                                                            :{' '}
                                                                        </b>
                                                                    )}{' '}
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                'capitalize',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        {application?.stage?.replace(
                                                                            '_',
                                                                            ' '
                                                                        )}
                                                                        &nbsp;{' '}
                                                                        <b>
                                                                            Applied
                                                                            on
                                                                        </b>{' '}
                                                                        &nbsp;{' '}
                                                                        {moment(
                                                                            application?.updated_date
                                                                        ).format(
                                                                            'MMM DD, YYYY'
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))}
                                            </div>
                                        )}
                                    {lastActive.id == candidate.id &&
                                        lastActive.active &&
                                        !isEmpty(candidate.last_login) && (
                                            <div className="container">
                                                <hr />
                                                <span>Last Active</span>
                                                <div className="row">
                                                    <img
                                                        src={lastActivityIcon}
                                                        style={{
                                                            height: '19px',
                                                            marginRight: '12px',
                                                        }}
                                                    />{' '}
                                                    {moment(
                                                        candidate.last_login
                                                    ).format('MMM DD, YYYY')}
                                                </div>
                                            </div>
                                        )}
                                    {submissionData.id == candidate.id &&
                                        submissionData.active && (
                                            <div className="container">
                                                <hr />
                                                <p> Submitted Jobs</p>
                                                {candidate.submissions.length >
                                                    0 &&
                                                    candidate.submissions.map(
                                                        (application) => (
                                                            <ul
                                                                style={{
                                                                    width: '100%',
                                                                    marginBottom:
                                                                        '0',
                                                                }}
                                                            >
                                                                <li>
                                                                    <div className="row contact_time">
                                                                        <b>
                                                                            {
                                                                                application.organization_name
                                                                            }
                                                                        </b>{' '}
                                                                        &nbsp; -
                                                                        &nbsp;
                                                                        {
                                                                            application.job_name
                                                                        }{' '}
                                                                        <span
                                                                            style={{
                                                                                textTransform:
                                                                                    'capitalize',
                                                                            }}
                                                                        >
                                                                            <b
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '5px',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                &nbsp;
                                                                                Submitted
                                                                                on
                                                                                &nbsp;
                                                                            </b>{' '}
                                                                            {moment(
                                                                                application.date
                                                                            ).format(
                                                                                'MMM DD, YYYY'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    )}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                       
                        </div>
                    </Card.Body>
                </Card>
                <Modal
                    className="free-candidate"
                    show={showModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header
                    onHide={() => setShowModal(false)}
                    closeButton
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Modal.Title>{'Interview Request'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
                   <Row>
                   <Col>
                   <Dropdown>
                            <Dropdown.Toggle
                                style={{
                                    width: '28rem',
                                    marginBottom: '7px',
                                    height: '48px',
                                    fontSize: '17px',
                                }}
                                title={selectedJobs}
                            >
                                {selectedJobs
                                    ? handleJobFullName(selectedJobs)
                                    : 'Select Job'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            {(jobs && ctMember && selectedOrgId) && jobs?.filter((i)=>i?.organization_id == selectedOrgId)?.length > 0 ? jobs?.filter((i)=>i?.organization_id == selectedOrgId)?.map((job) => (
                                    <Dropdown.Item
                                        key={job.id}
                                        onSelect={(e) => {
                                            setSelectedJobs(job.name)
                                            setSelectedJobId(job.id)
                                        }}
                                        value={job.id}
                                        eventKey={job.id}
                                    >
                                        {job.name}
                                    </Dropdown.Item>
                                )) : (jobs && ctMember) &&
                                (<Dropdown.Item>
                                    No Job Available For This Organization
                                </Dropdown.Item>)
                            }
                            {jobs && !ctMember && jobs.length > 0 ? jobs.map((job) => (
                                    <Dropdown.Item
                                        key={job.id}
                                        onSelect={(e) => {
                                            setSelectedJobs(job.name)
                                            setSelectedJobId(job.id)
                                        }}
                                        value={job.id}
                                        eventKey={job.id}
                                    >
                                        {job.name}
                                    </Dropdown.Item>
                                )) : (jobs && !ctMember) &&
                                (<Dropdown.Item>
                                    No Job Available For This Organization
                                </Dropdown.Item>)
                            }
                            </Dropdown.Menu>
                        </Dropdown>
                   </Col>
                   <Col>
                   <Form.Label>Description:</Form.Label>
                  <Form.Control name='description' type="text" onChange={(e) => setEmployerComments(e.target.value)} as="textarea" rows={3} placeholder="Enter Message Here " />
                  <p style={{color:'red'}}>
                  {validationError && validationError.description} 
                  </p>
                    </Col>
                     </Row>
                     <div style={{marginTop:'20px', display:'flex', justifyContent:'space-around'}}>
                   <div>
                   <Button onClick={() => setShowModal(false)} variant="secondary">
                     Close
                     </Button>
                    </div>
                    <div>
                   <Button onClick={HandleSaveModal} variant="secondary">
                     Save
                     </Button>
                    </div>
                     </div>
                      </Form>
                    </Modal.Body>
                    {/* <Modal.Footer>
          <Button variant="secondary">
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button>
        </Modal.Footer> */}

                </Modal>
                <br />
            </>
        )
    }

    function getSelectCell(candidate, col) {
        return (
            <td
                key={col + candidate.id}
                style={{ width: '1rem' }}
                className={styles.td + ' align-middle'}
            >
                <Form.Check
                    className={styles.candidateCheckbox}
                    type="checkbox"
                    value={candidate.name}
                    name={candidate.name}
                    checked={checked}
                    onChange={handleCheckboxChange}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
        )
    }

    function getCandidateImage(candidate, col) {
        return (
            <td
                key={col + candidate.id}
                style={{ width: '1rem' }}
                className={styles.td + ' align-middle'}
            >
                {candidate.image_url ? (
                    <img
                        className={styles.candidateProfileImage}
                        src={
                            candidate.image_url
                                ? candidate.image_url
                                : profileImage
                        }
                        onClick={(e) => clickHandler(candidate)}
                    />
                ) : (
                    <CandidateAvatarIcon
                        firstName={candidate?.first_name}
                        lastName={candidate?.last_name}
                    />
                )}
            </td>
        )
    }

    function getCandidateCell(candidate, candidate_history) {
        return (
            <td
                key={'candidate_' + candidate.id}
                className={styles.td}
                style={{ width: '15.5rem' }}
            >
                <span className={styles.candidateName}>
                    {candidate.first_name +
                        ' ' +
                        Util.handleUndefined(candidate.last_name)}
                </span>
                {candidate.linkedin_profile_url && (candidate.linkedin_profile_url).includes('https://www.linkedin') && (
                    <a href={candidate.linkedin_profile_url} target="_blank">
                        <Image
                            src={LinkedinIcon}
                            style={{
                                width: '16px',
                                height: '16px',
                                marginLeft: '15px',
                                marginTop: '-1px',
                            }}
                        />
                    </a>
                )}
                {candidate.resume && (
                    <a href={candidate.resume} target="_blank">
                        <Image
                            src={PdfIcon}
                            style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '5px',
                                marginTop: '-1px',
                            }}
                        />
                    </a>
                )}
                <div className={styles.tdLine}>
                    {Util.candidateCompanyString(candidate)}
                </div>
                {/* {candidate.score && (
                    <div className={styles.tdLine}>Sys Score: {candidate.sys_score}%</div>
                )} */}
                {candidate.location && (
                    <div className={styles.tdLine}>{candidate.location}</div>
                )}
                {candidate.title && (
                    <div className={styles.tdLine}>{candidate.title}</div>
                )}
                {!isEmpty(candidate.company_names) && (
                    <div className={styles.tdLine}>
                        {candidate.company_names != '[]'
                            ? candidate.company_names
                            : ''}
                    </div>
                )}
                {!isEmpty(candidate.school) && (
                    <div className={styles.tdLine}>
                        {candidate.school != '[]' ? candidate.school : ''}
                    </div>
                )}
                {candidate_history?.map((data) => {
                    if (data?.candidate_id === candidate.id)
                        return (
                            <div>
                                Last viewed{' '}
                                {moment(data?.created_at, 'YYYYMMDD').fromNow()}
                            </div>
                        )
                })}
            </td>
        )
    }

    function getMatchCell(candidate , handleScoreModal) {
        const matchScore = candidate.score
        const matchResponse = candidate.ai_response
        return (
            <td
                key={'matchscore_' + candidate.id}
                className={styles.td}
                style={{ width: '4rem' }}
                onClick={(event) => {
                    event.stopPropagation()
                    handleScoreModal(matchResponse)
                }}
            >
                <MatchScore score={matchScore} />
            </td>
        )
    }

    function getSkillsCell(candidate) {
        return (
            <td
                key={'skills_' + candidate.id}
                className={styles.tdEllipsis}
                style={{ width: '8rem' }}
                title={candidate.skills}
            >
                {candidate.skills && (
                    <div>
                        {candidate.skills
                            .split(',')
                            .slice(0, 5)
                            .map((skill, index) =>
                                canidateSkillATS(skill, index)
                            )}
                        {5 < candidate.skills.split(',').length && (
                            <Badge
                                pill
                                variant="secondary"
                                className="skills-more-ats-button  d-inline-block text-truncate"
                            >
                                <label onClick={(e) => clickHandler(candidate)}>
                                    View More
                                </label>
                            </Badge>
                        )}
                    </div>
                )}
            </td>
        )
    }

    function getLastContactedCell(candidate) {
        return (
            <td
                key={'last_contacted_' + candidate.id}
                className={styles.td}
                style={{ width: '2rem' }}
            >
                {!isEmpty(candidate.last_contacted) &&
                    moment(candidate.last_contacted).format('MM/DD/YY')}
            </td>
        )
    }

    function getMessageCell(candidate) {
        return (
            <td
                key={'message_' + candidate.id}
                className={styles.td}
                style={{ width: '2rem' }}
            ></td>
        )
    }

    function getAppliedCell(candidate) {
        let text = ''

        if (!isNil(candidate.moved_at) && !isEmpty(candidate.moved_at)) {
            text = moment(candidate.moved_at).format('MMM Do YYYY')
        }

        return (
            <td
                key={'applied_' + candidate.id}
                className={styles.td}
                style={{ width: '2rem' }}
            >
                {text}
            </td>
        )
    }

    function getGradingCell(candidate) {
        return (
            <td
                key={'grade_' + candidate.id}
                className={styles.td}
                style={{ width: '2rem' }}
            >
                <CandidateGrader jobId={jobId} candidate={candidate} />
            </td>
        )
    }

    function getMovedFromCell(candidate) {
        let stages = {
            recruitor_screen: 'Recruiter Screened',
            applicant: 'Applicant',
            submitted: 'Submitted',
            submitted: 'Submitted',
            first_interview: 'First Interview',
            second_interview: 'Second Interview',
            onhold: 'On Hold',
            offer: 'Offer',
            reject: 'Archived',
            hired: 'Hired',
        }
        return (
            <td
                key={'moved_' + candidate.id}
                className={styles.td}
                style={{ width: '2rem' }}
            >
                {stages[candidate.previous_stage]}
            </td>
        )
    }

    function getMovedByCell(candidate) {
        return (
            <td
                key={'message_' + candidate.id}
                className={styles.td}
                style={{ width: '2rem' }}
            >
                {candidate.moved_by}
            </td>
        )
    }

    function getApplicationCountCell(candidate) {
        return (
            <td
                key={'message_' + candidate.id}
                className={styles.td}
                style={{ width: '10rem' }}
            >
                {/* {(candidate.submissions_detail).length} */}
                {candidate?.submissions_detail.map((data) => {
                    //   if(jobId != data.id)
                    return (
                        <ul>
                            <li>
                                {moment(data.date).format('MMM Do YYYY')}
                                <br />
                                <b>{data.organization_name}</b> /{' '}
                                {data.job_name} - {data.first_name}{' '}
                                {data.last_name}{' '}
                            </li>
                        </ul>
                    )
                })}
            </td>
        )
    }
    function getCandidateHistory(candidate, setHistoryShow, handleResponseModal, setPersonId, isEmailSend, setPersonOpoinionId, setGoodBadBoxShow) {
        return (
            <td style={{ width: '4.5rem' }} className={styles.td}>
                <img
                    onClick={() => {
                        setPersonId(candidate.id)
                        setHistoryShow(true)
                    }}
                    className={`${styles.eyeIcon} ${styles.pointer}`}
                    src={eyeImage}
                />
                { isEmailSend == true && 
                    <img
                        className={`${styles.eyeIcon} ${styles.pointer}`}
                        src={MailIcon}
                        style={{ width: '20px',height: '20px', marginLeft: '12px'}}
                        onClick={() => {
                            handleResponseModal(candidate.id)
                        }}
                    />
                }
                    {/* <img
                        onClick={() => {
                            setPersonOpoinionId(candidate.id)
                            setGoodBadBoxShow(true)
                        }}
                        className={`${styles.eyeIcon} ${styles.pointer}`}
                        src={goodBad}
                        style={{ width: '20px',height: '20px', marginLeft: '5px'}}
                    /> */}
            </td>
        )
    }
}

function absoluteIndex(page, idx) {
    return page * CANDIDATES_PER_PAGE + idx
    // return idx
}

const RecruiterActivityItem = ({ author, created_at, title, content }) => {
    const regExp = /@\[[a-zA-Z\s]+\]/g
    const matches = [...content.matchAll(regExp)].map((arr) => arr[0])
    let val = content

    for (let index = 0; index < matches.length; index++) {
        const element = matches[index]
        val = val.replace(
            element,
            '<b>@' + element.slice(2, element.length - 1) + '</b>'
        )
    }
    val = val?.replaceAll('recruitor_screen', '<b>Recruiter Screened</b>')
    val = val?.replaceAll('submitted', '<b>Submitted</b>')
    val = val?.replaceAll('first_interview', '<b>First Interview</b>')
    val = val?.replaceAll('second_interview', '<b>Second Interview</b>')
    val = val?.replaceAll('offer', '<b>Offer</b>')
    val = val?.replaceAll('reject', '<b>Archived</b>')
    val = val?.replaceAll('applicant', '<b>Applicant</b>')
    val = val?.replaceAll('hired', '<b>Hired</b>')
    val = val?.replaceAll('lead', '<b>Lead</b>')
    return (
        <div className={`${styles.indent} ${styles.tabContentText}`}>
            <Row className="d-flex justify-content-between mx-0">
                <span className={styles.noteAuthorText}>
                    {author ? author.first_name + ' ' + author.last_name : ''}
                </span>
                <b className={styles.noteCreatedText}>
                    {moment(created_at).format('llll')}
                </b>
            </Row>
            <Row className="mx-0">
                <p className={`${styles.noteCreatedText} mb-0`}>
                    {title ? '@ ' + title : ''}
                </p>
            </Row>
            <Row className="mx-0">
                <p
                    className={styles.noteCreatedText}
                    dangerouslySetInnerHTML={{ __html: val }}
                ></p>
            </Row>
        </div>
    )
}

export default CandidateTable
