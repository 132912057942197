import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import EditAccountSettingV2 from './EditAccountSettingV2'

const AccountSettingPage = (props) => {
    console.log("🚀 ~ file: AccountSettingPage.js:17 ~ AccountSettingPage ~ props", props)
    const { currentUser,person } = props
    const { is_cm } = props
    const {
        first_name,
        last_name,
        email,
        phone_number,
        active_job_seeker,
        id,
        linkedin_profile_url,
        role,
    } = currentUser

    const {
        job_titles,
        locations,
        experiences,
        skills,
        avatar_url,
    } = person

    
    const [isEditBasicInfo, setIsEditBasicInfo] = useState(false)
    const [isEditPreference, setIsEditPreference] = useState(false)
    const [firstName, setFirstName] = useState(first_name)
    const [lastName, setLastName] = useState(last_name)
    const [phoneNumber, setPhoneNumber] = useState(phone_number)
    const [linkedinProfileUrl, setLinkedinId] = useState(linkedin_profile_url)
    

    const handleSaveBasic = async (value) => {
        const { firstName, lastName, phoneNumber, linkedinProfileUrl, job_titles, experiences, locations, skills, avatar_url } = value
        const payload = new FormData()
        const url = `/users/${id}`
        
        payload.append('user[first_name]', firstName)
        payload.append('user[last_name]', lastName)
        payload.append('user[phone_number]', phoneNumber)
        if (role === 'talent'){
            payload.append('user[linkedin_profile_url]', linkedinProfileUrl)
            payload.append('person[first_name]', firstName)
            payload.append('person[last_name]', lastName)
            payload.append('person[phone_number]', phoneNumber)
            payload.append('person[linkedin_profile_url]', phoneNumber)

            payload.append('person[job_titles]', job_titles)
            payload.append('person[experiences]', experiences)
            payload.append('person[locations]', locations)
            payload.append('person[skills]', skills)
            if(typeof avatar_url == 'object' && avatar_url != null) {
                payload.append('person[avatar]', avatar_url)
            }
        }
        {
            const response = await makeRequest(url, 'put', payload, {
                contentType: 'multipart/form-data',
                loadingMessage: 'Submitting...',
                createSuccessMessage: (response) => response.data.message,
                createResponseMessage: (response) => {
                    return {
                        message: "Updated Successfully",
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                },
                onSuccess: (res) => {
                    setFirstName(res.data.person.first_name)
                    setLinkedinId(res.data.person.linkedin_profile_url)
                    setLastName(res.data.person.last_name)
                    setPhoneNumber(res.data.person.phone_number)
                    setIsEditBasicInfo(!isEditBasicInfo)
                },
            })
        }
    }

    return (
        <EditAccountSettingV2
            firstName={firstName}
            lastName={lastName}
            phoneNumber={phoneNumber}
            job_title={job_titles}
            location={locations}
            experience={experiences}
            skill={skills}
            email={email}
            avatar={avatar_url}
            linkedinProfileUrl={linkedinProfileUrl}
            role={role}
            handleSaveBasic={handleSaveBasic}
            setIsEditBasicInfo={setIsEditBasicInfo}
            setLinkedinId={setLinkedinId}
        />
    )
}
export default AccountSettingPage
