import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: #393f60;
  margin-bottom: 20px;
`
export const H3 = styled.h3`
  margin: 0px;
  color: #393f60;
`
export const P = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #1d2447;
  margin-bottom: 15px;
`
export const A = styled.a`
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: ${({ tAlign }) => (tAlign ? tAlign : 'center')};
  color: #8091e7;
`
export const Message = styled.span`
  font-family: Avenir, 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 68px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const SPAN = styled.span`
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  color: #1d2447;
`
export const DIVERROR = styled.div`
  font-size: 15px;
  color: red;
  text-align: center;
  width: 100%;
`
export const DIVSUCCESS = styled.div`
  font-size: 15px;
  color: green;
  text-align: center;
`

export const Button = styled.button`
  background: linear-gradient(
    94.67deg,
    #5f78ff -1.19%,
    #7185f2 53.94%,
    #8d91ff 102.59%
  );
  border-radius: 20px;
  padding: 9px 31px;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: lightgray;
  }
`
export const Label = styled.label`
  color: #3a5182;
  cursor: pointer;
  font-size: 14px;
`

export const GoogleButton = styled.button`
  background: #ffffff;
  color: #304be0;
  border: 1px solid #304be0;
  border-radius: 5px;
  padding: 5px 30px;
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  img{
       height: 20px;
       width: 20px;
       margin-right: 10px;
    }
`
export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
`
