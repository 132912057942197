// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".FilterJob-module__filterByContainer___1NQ5_{display:flex;flex-direction:row;margin-bottom:5px;margin-left:8px}.FilterJob-module__filterByText___2nZv-{font-size:14px;margin:0px}.FilterJob-module__filterByInputWrapper___2t1PN{display:flex;flex-direction:row;justify-content:space-around}.FilterJob-module__filterByInputWrapper___2t1PN .FilterJob-module__checkBox___q95aT{display:flex;align-items:center;margin-left:30px}.FilterJob-module__filterByInputWrapper___2t1PN .FilterJob-module__checkBox___q95aT label{margin:0px;margin-left:8px}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20240916072422/app/javascript/components/common/FilterJob/styles/FilterJob.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAa,CACb,kBAAmB,CACnB,iBAAkB,CAClB,eAAgB,CACjB,wCAEC,cAAe,CACf,UAAW,CACZ,gDAEC,YAAa,CACb,kBAAmB,CACnB,4BAA6B,CAH/B,oFAKI,YAAa,CACb,kBAAmB,CACnB,gBAAiB,CAPrB,0FAUM,UAAW,CACX,eAAgB","file":"FilterJob.module.scss","sourcesContent":[".filterByContainer {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 5px;\n  margin-left: 8px;\n}\n.filterByText {\n  font-size: 14px;\n  margin: 0px;\n}\n.filterByInputWrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  .checkBox {\n    display: flex;\n    align-items: center;\n    margin-left: 30px;\n\n    label {\n      margin: 0px;\n      margin-left: 8px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"filterByContainer": "FilterJob-module__filterByContainer___1NQ5_",
	"filterByText": "FilterJob-module__filterByText___2nZv-",
	"filterByInputWrapper": "FilterJob-module__filterByInputWrapper___2t1PN",
	"checkBox": "FilterJob-module__checkBox___q95aT"
};
module.exports = exports;
