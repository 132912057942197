import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import styles from '../styles/SearchResultsBlock.module.scss'
import JobPositionCard_v2 from '../../../common/JobPositionCard/JobPositionCard_v2'
import Paginator_v2 from '../../../common/Paginator/Paginator_v2'
import Util from '../../../../utils/util'
import JobSearchBar from '../../../common/JobSearchBar/JobSearchBar'
import FilterJob_v2 from '../../../common/FilterJob/FilterJob_v2'
import Select from 'react-select'
import ResumeDragDrop from '../../../common/ResumeDragDrop/ResumeDragDrop'
import '../styles/ResumeDragDrop.scss'
import HorizontalLoader from '../../../common/HorizontalLoader/HorizontalLoader'
import { Form } from 'react-bootstrap'


const SearchResultsBlock_v2 = (props) => {
    const {
        jobs,
        loading,
        activePage,
        totalJobsCount,
        pageCount,
        submitJobSearch,
        handleInputChange,
        inputValue,
        setActivePage,
        setErrorSearchingJobs,
        errorSearchingJobs,
        filterStack,
        setStackFilter,
        handleSearch,
        experienceYears,
        setExperienceYears,
        user,
        perPage,
        filter_status,
        organization_type_list,
        setSelectOrganizationType,
        selectOrganizationType,
        setJobFilter,
        jobFilter,
        validationErrors,
        resumeFile,
        handleOnRemoveResumes,
        handleResumeFiles,
        handleSubmitResume,
        generatingScoreLoading,
        currentUser,
        handleClearResumes,
        buttonDisabled,
        uploadResumeFlage,
        setShow,
        resumeFileName,
        removeResume,
        personId,
        onChangeBulkJobIds,
        job_groups,
        selectJobGroup,
        setSelectJobGroup
    } = props
    const displayNumberOfResults = () => {
        return Util.displayNumberOfResults(
            totalJobsCount,
            pageCount,
            activePage,
            perPage
        )
    }
    const displayText = `Displaying ${displayNumberOfResults()} results`
    return (
        <div>
            <section className="section-box-2">
               <div className="box-head-single none-bg" style= {{ backgroundColor: 'white' }}>
                    <div className="banner-hero">
                        <div className="container">
                        <div className="banner-inner">
                            <div className="row">
                                <div className=" login" style={{ margin: '0px auto'}}>
                                    <div className="block-banner">
                                        <div className="form-find mt-20 mb-20" style={{ boxShadow: 'none' }}>
                                            <div className="wow animate__ animate__fadeInUp animated"  style={{ visibility:'visible',animationName: 'fadeInUp', display: 'flex',width: '100%'}}>
                                               <input type="text" className="form-input input-keysearch3 mr-10"   onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            submitJobSearch(e)
                                                        }
                                                    }}  onChange={(e) => handleInputChange(e.target.value)} value={inputValue} placeholder="Search for jobs" />
                                                <button type='button' onClick={ (e) => submitJobSearch(e)} className="btn btn-default btn-find wow animate__ animate__fadeInUp questions"  style={{ margin: '0px',visibility:'visible',animationName: 'fadeInUp' }} >Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
               </div>
           </section>
            {resumeFileName && (<div className='text-primary text-center mt-4 font-weight-bolder'>Attached File: {resumeFileName} <span style={{cursor: 'pointer'}} onClick={()=>removeResume()}><i className='text-danger' data-feather='x'/></span></div>)}
            <div className='row flex'>
                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                
                    {window.location.pathname == "/job/cardinal_jobs" ?
                        "" :
                        <div className="pl-2">
                            {!currentUser &&
                                <ResumeDragDrop
                                    title={'resume'}
                                    buttonlabel={'Get Matched Jobs'}
                                    files={resumeFile}
                                    handleOnRemoveFiles={handleOnRemoveResumes}
                                    handleFiles={handleResumeFiles}
                                    handleOnSubmit={handleSubmitResume}
                                    validationErrors={validationErrors}
                                    handleClearResumes={handleClearResumes}
                                    buttonDisabled={buttonDisabled}
                                />
                            }
                            <FilterJob_v2
                                filterStack={filterStack}
                                setStackFilter={setStackFilter}
                                handleSearch={handleSearch}
                                setActivePage={setActivePage}
                                experienceYears={experienceYears}
                                setExperienceYears={setExperienceYears}
                            />
                        </div>
                    }
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center align-content-center"
                            style={{ width: '100%' }}
                        >
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    ) : errorSearchingJobs ? (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorSearchingJobs(null)}
                            dismissible
                        >
                            {errorSearchingJobs}
                        </Alert>
                    ) : generatingScoreLoading ? (
                        <div className="w-100 flex-column justify-content-center align-items-center">
                            <p className={styles.alertText}>
                                Please give us a moment as our AI generates your
                                matches!
                            </p>
                            <HorizontalLoader />
                        </div>
                    ) : (
                        <div className='col-lg-12  col-md-12 col-sm-12 col-12' >
                            {totalJobsCount && (
                                 <div className='col-lg-12 col-md-12 col-sm-12 col-12' style={{minHeight: '10px'}}>
                                    <p className={styles.recommendedJobsTitle}>
                                        <span className="" style={{ fontSize: '0.75rem' }}>{displayText}</span>
                                    </p>
                                </div>
                            ) || ''}
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className="job-list-list mb-15 mt-15">
                                <div className="list-recent-jobs">
                                    {(jobs && jobs.length) ? (
                                        jobs.map((jobItem) => {
                                            return (
                                                <JobPositionCard_v2
                                                    key={jobItem.id}
                                                    job={jobItem}
                                                    matchScore={(jobItem?.score) ? Math.round(jobItem?.score) : 0}
                                                    showSalary={false}
                                                    showLocation={false}
                                                    showCardinalJob={true}
                                                    showMatchScore={(jobItem.score) ? true : false}
                                                    personId={personId}
                                                    onChangeBulkJobIds={onChangeBulkJobIds}
                                                />
                                            )
                                        })
                                    ) : (
                                        <div style={{ textAlign: 'center' }}>
                                            No jobs found
                                        </div>
                                    )}
                                </div>
                            </div>
                            </div>
                            {Array.isArray(jobs) && pageCount > 1 && (
                                <Row className="d-flex justify-content-center">
                                    <Paginator_v2
                                        pageCount={pageCount}
                                        pageWindowSize={5}
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                    />
                                </Row>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SearchResultsBlock_v2
