import React, { useEffect, useState } from 'react'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Alert, Modal, Spinner } from 'react-bootstrap'
import axios from 'axios'
import bannerImage from '../../../../assets/v2/imgs/banner/banner.png';
import unionImage from '../../../../assets/v2/imgs/banner/union.svg';
import docsImage from '../../../../assets/v2/imgs/banner/docs.svg';
import tickImage from '../../../../assets/v2/imgs/banner/tick.svg';
import googleImage from '../../../../assets/v2/imgs/google1.png';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import '../../../../assets/v2/css/main.css'
import styles from './styles/Signup.module.scss'
import UserForm from './UserFormV2'
import './styles/default.css';
import LogoGroups from '../../../../assets/images/logos/cardinalgroups.png'

const initialFormData = {
  email: '',
  password: '',
}

const singInValidation = {
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
}

const SigninV2Page = (props) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [response, setResponse] = useState({})
  const [isSignUp, setIsSignUp] = useState(false)

  useEffect(() => {
    if (props.isError) {
      setIsSignUp(true)
    }
  }, [props])

  const validationSchema = Yup.object({ ...singInValidation })

  const resendConfirmation = () => {
    setResponse({})
    const resendformData = new FormData()
    resendformData.append(`user[email]`, formData.email)
    const url = `/resend_confirmation`
    makeRequest(url, 'post', resendformData, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createSuccessMessage: () => 'Recruiter member added successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: () => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      },
    })
  }

  const handleGoogleSignIn = async () => {
      window.location.href = `/user/auth/google_oauth2`
  }

  const handleSignIn = async (values, resetForm) => {
    setResponse({})
    setFormData({ ...formData, ...values })
    const payload = new FormData()
    const url = '/users/sign_in'
    payload.append('user[email]', values.email)
    payload.append('user[password]', values.password)
    payload.append('format', 'js')
    setLoading(true)
    await axios
      .post(url, payload)
      .then((response) => {
        setTimeout(() => {
          if (response.data.role == 'talent'){
            window.location = '/communities'
          }else{
            window.location = '/'
          }
        }, 2000)
        localStorage.setItem('user', JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(error)
        setResponse(error?.response?.data)
        resetForm();
        setLoading(false)
      })
  }

  const TextField = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>
          {label}
        </label>
        <input {...field} {...props} />
      </>
    );
  };
  const currentHost = window.location.host;

  return (
    <div>
      {/* <main className="d-flex align-items-center"> */}
      <section className="container mt-80">
        <div className="banner-hero hero-1">
          <div className="banner-inner">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 communities-create">
                {/* {(currentHost == 'cardinalgroups.com' || currentHost == 'www.cardinalgroups.com' ? */}
                  <>
                    <div className="build_community pl-40">
                      {/* <a href={props.host_url} className={"d-flex img1"}>
                        <img src={LogoGroups} rounded />
                      </a> */}
                      <h1>Everything you need to build community and make money online.</h1>
                      <div className="build_community_info">
                        <div> 📈 Highly engaged</div>
                        <div> ❤️ Simple to setup</div>
                        <div> 🙂 It's fun</div>
                        <div> 💰 Charge for subscription membership</div>
                        <div> 🌎 Global distribution</div>
                      </div>
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="mailto:help@cardinalgroups.ai">help@cardinalgroups.com</a>
                      </div>
                    </div>
                  </>
                  
                  {/* <div className="banner-imgs" style={{ width: '70%', margin: '0px auto' }}>
                    <img alt="CardinalTalent" src={bannerImage} className="img-responsive shape-1" />
                    <span className="union-icon"><img alt="CardinalTalent" src={unionImage} className="img-responsive shape-3" /></span>
                    <span className="docs-icon"><img alt="CardinalTalent" src={docsImage} className="img-responsive shape-2" /></span>
                    <span className="tick-icon"><img alt="CardinalTalent" src={tickImage} className="img-responsive shape-3" /></span>
                  </div>
                )} */}
              </div>
              <div className="col-lg-6 bg-white" style={{ maxWidth: '500px', margin: '0px auto' }}>
                <div className="block-banner login">
                  <div className="right-content" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <h3 className="form-title text-center">Log in</h3>

                    {!_.isEmpty(response) && (
                      <Alert
                        variant={response.messageType}
                        onClose={() => setResponse({})}
                        style={{ fontSize: '15px', paddingRight: '1rem' }}
                        dismissible
                        className="candidate-info-close"
                      >
                        <span className={styles.alertInfo}> {response.message} </span>
                      </Alert>
                    )}
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      initialValues={initialFormData}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => { handleSignIn(values, resetForm) }}
                    >
                      <Form>
                        <UserForm />
                        <div className="second-action-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                          <div className="remember-me-container" style={{ display: 'flex' }}>
                            <input type="checkbox" name="remember" className="sonara-checkbox checkbox1" id="id_remember" />
                            <label htmlFor="id_remember">
                              <p className="auth-secondary-text" style={{ margin: 0, color: '#666' }}>Remember me</p>
                            </label>
                          </div>
                          <a className="button secondaryAction" href="/users/password/new" style={{ color: '#007bff', textDecoration: 'none' }}>
                            <p className="auth-secondary-text" style={{ margin: 0, color: '#666', textDecoration: 'underline' }}>Forgot your password?</p>
                          </a>
                        </div>
                        {_.isEmpty(response) && (
                          <div className={styles.onResponse}>
                            {response.messageType === 'warning' && (
                              <a href="#" onClick={() => resendConfirmation()}>
                                Click here to resend confirmation
                              </a>
                            )}
                          </div>
                        )}
                        <div className="text-center mt-4">
                          <button type="submit" disabled={loading} className="btn btn-default font-heading icon-send-letter btn1 signupbtn w-50">{loading ? (
                            <Spinner animation="border" variant="light" />
                          ) : (
                            'Sign In'
                          )}
                          </button>
                        </div>
                        {/* <span className="signup-link text-center">Don't have an account? <a href="signup.html" style={{textDecoration: 'underline'}}>Sign up</a></span>  */}
                      </Form>
                    </Formik>
                    {/* <span className="signup-link text-center">Don't have an account? <a href="signup.html" style={{textDecoration: 'underline'}}>Sign up</a></span> */}
                    <div className="hr-separator">
                      <span className="separator">OR</span>
                    </div>
                    <div className="text-center">
                      <a
                        className="button is-google hero_buttons w-button"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleGoogleSignIn()}
                      >
                        <img src={googleImage} alt="Google Logo" width={16} height={16} style={{ margin: '10px' }} />
                        Sign In with Google
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </main> */}
    </div>
  )
}

export default SigninV2Page
