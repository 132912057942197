import React, { useEffect, useState,Fragment} from 'react'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Util from '../../../utils/util'
import './styles/NetworkCoversation.scss'
import NetworkCandidateList from './NetworkCandidateList'
import NetworkMessages from './NetworkMessages'

function NetworkCoversationIndex({current_user,req_user_id,community_id=0}) {
    const { id, linkedin_profile_url, location, job_title, github_url, name } = current_user
    const [loading, setLoader] = useState(true)
    const [searchName,setSearchName] = useState('')
    const [activeCandidate, setActiveCandidate] = useState(req_user_id || '')
    const [candidates, setCandidates] = useState([])
    const [fetchCandidateDetail, setFetchCandidateDetail] = useState('')
    const [fetchCandidateMessage, setFetchCandidateMessage] = useState([])
    const fetchData = async(auto_load) => {
        setCandidates([])
        let url = ''
        if(community_id > 0){
            url = '/communities/' + community_id + '/members/chat_member_list.json'
        }else{
            url = `/fetch_candidates_list.json`
        }
        await makeRequest(url, 'get',  {
            params: {
               name: searchName,
               community_id: community_id
            //    req_user_id: activeCandidate
            }},{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setCandidates(
                    res.data.candidates.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate?.person?.first_name != null) ? capitalize(candidate?.person?.first_name): capitalize(candidate?.first_name || 'N/A'),
                        last_name: (candidate?.person?.last_name != null) ? capitalize(candidate?.person?.last_name) : capitalize(candidate?.last_name || 'N/A'),
                        title: candidate?.person?.title || candidate.title,
                        location: candidate?.person?.location || candidate.location,
                        state: candidate?.state || '',
                        city: candidate?.city || '',
                        avatar_url: candidate?.person?.avatar_url || '',
                        employer: candidate?.person?.employer || '',
                        description: candidate?.person?.description || '',
                        updated_at: candidate?.user_updated_at,
                    }))
                )
                if(auto_load == false){
                    if(req_user_id == null){
                        viewCandidateMessages(res.data.candidates[0]?.id,0)
                    }else{
                        viewCandidateMessages(req_user_id,0)
                    }
                }
            },
        })
    }

    const viewCandidateMessages = async(id,coversation_id) => {
        if(id != undefined && id != ''){
            setFetchCandidateDetail('')
            setFetchCandidateMessage([])
            setActiveCandidate(id)
            const url = `/fetch_candidates_detail/${id}/${coversation_id}.json`
            await makeRequest(url, 'get', {
                params: {
                   community_id: community_id
                }},{
                contentType: 'application/json',
                loadingMessage: 'Fetching...',
                createSuccessMessage: (response) => response.data.message,
                onSuccess: (res) => {
                    const candidate = res.data.candidates_detail
                    setFetchCandidateDetail({
                        id: candidate.id,
                        first_name: (candidate?.person?.first_name != null) ? capitalize(candidate?.person?.first_name): capitalize(candidate?.first_name || 'N/A'),
                        last_name: (candidate?.person?.last_name != null) ? capitalize(candidate?.person?.last_name) : capitalize(candidate?.last_name || 'N/A' ),
                        title: candidate?.person?.title || candidate.title,
                        location: candidate?.person?.location || candidate.location,
                        state: candidate?.state || '',
                        city: candidate?.city || '',
                        avatar_url: candidate?.person?.avatar_url || '',
                        employer: candidate?.person?.employer || '',
                        description: candidate?.person?.description || '',
                    })

                    setFetchCandidateMessage(
                        res.data.candidates_message.map((candidate) => ({
                            id: candidate.id,
                            first_name: (candidate?.user1?.person?.first_name != null) ? capitalize(candidate?.user1?.person?.first_name): capitalize(candidate?.first_name || 'N/A'),
                            last_name: (candidate?.user1?.person?.last_name != null) ? capitalize(candidate?.user1?.person?.last_name) : capitalize(candidate?.last_name || 'N/A'),
                            title: candidate?.user1?.person?.title || candidate.title,
                            location: candidate?.user1?.person?.location || candidate.location,
                            state: candidate?.state || '',
                            city: candidate?.city || '',
                            avatar_url: candidate?.user1?.person?.avatar_url || '',
                            employer: candidate?.user1?.person?.employer || '',
                            description: candidate?.user1?.person?.description || '',
                            message: candidate.message,
                            created_at: candidate.created_at
                        }))
                    )
                },
            })
        }
    }

    useEffect(()=>{
        fetchData(false)
    },[])

    useEffect(()=>{
        fetchData(true)
    },[searchName])

    useEffect(() => {
        // Set up interval to fetch new messages every minute
        const intervalId = setInterval(() => {
            fetchData(true);
        }, 60000); // 60000 milliseconds = 1 minute
    
        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }, []);

    return (
        <>
            <div className="post-loop-grid mt-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <NetworkCandidateList candidates={candidates} activeCandidate={activeCandidate} viewCandidateMessages={viewCandidateMessages} setSearchName={setSearchName}></NetworkCandidateList>
                        </div>
                        <div className="col-md-8">
                            {(fetchCandidateDetail != '') &&
                                <NetworkMessages fetchCandidateDetail={fetchCandidateDetail} fetchCandidateMessage={fetchCandidateMessage} setFetchCandidateMessage={setFetchCandidateMessage} community_id={community_id}></NetworkMessages>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NetworkCoversationIndex
