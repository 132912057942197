import React, { useState, useEffect } from 'react'
import './../styles/communities.scss'
import image3 from '../../../../../assets/v2/imgs/placeholder.jpg'
import playImg from '../../../../../assets/v2/imgs/videoplay.png'
import './../styles/coursePage.scss'
import { Box } from '../../../common/Styled components/styled'
import Text from '../../../common/Styled components/Text'
import axios from 'axios'
import Modal from "react-bootstrap/Modal"
import { Row, Col, Spinner } from 'react-bootstrap'
import { Formik, Form , Field } from 'formik'
import * as Yup from 'yup'
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import { CKEditor } from 'ckeditor4-react'

const ChapterList = ({ community, course, is_community_admin }) => {
    const [showAddNewModal, setShowAddNewModal] = useState(false)
    const [editorKey, setEditorKey] = useState(1)
    const [chapters, setChapters] = useState([])
    const [chapterReads, setChapterReads] = useState([])
    const [status, setStatus] = useState(1)
    const [showChapterDetail, setShowChapterDetail] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [chapterEntry, setChapterEntry] = useState({})
    const [method, setMethod] = useState('post');
    const [initialFormData, setInitialFormData] = useState({
        name:'',
        description: '',
        status: 1,
        video_url: ''
    })
    const [loading, setLoading] = useState(false)
    const [totalChapter, setTotalChapter] = useState(0)
    const [totalRead, setTotalRead] = useState(0)

    const CommunityValidation = {
        name: Yup.string().trim()
          .required('Please enter group name.')
          .max(50, 'Module name has a max length of 50 characters')
          .test(
            'module-unique',
            'This module name is already in use',
            async function (value) {
              const res = await axios.get(
                `/communities/chapters/exists?name=${encodeURIComponent(value)}&id=${course.id}&is_update=${ (method == 'post' ? false : true) }`
              )
              return !res.data.course_chapter_exists
            }
          ),
        description: Yup.string().trim().required('Description is required'),
        video_url: Yup.string().trim().url('Invalid URL').nullable(),
      }
    const validationSchema = Yup.object({ ...CommunityValidation })
    const handleStatusUpdate = (e) => {
        if(e){
            setStatus(1)
        }else{
            setStatus(0)
        }
    }

    const onChangeDescription = (e, field, values, setFieldValue) => {
        let data = e.editor.getData()
        setFieldValue('description', data)
    }

    const handleSubmit = async (values) => {
        setLoading(true)
        const payload = new FormData()
        var url = '/communities/'+course.id+'/add_chapter'
        if(method == 'put'){
            url = '/communities/course_chapter/'+chapterEntry.id+'/update'
        }
        payload.append('chapters[name]', values.name)
        payload.append('chapters[status]', status)
        payload.append('chapters[video_url]', values.video_url || '')
        payload.append('chapters[description]', values.description)
        payload.append('chapters[community_course_id]', course.id)
        const response = await makeRequest(url, method, payload, {
          contentType: 'multipart/form-data',
          loadingMessage: 'Submitting...',
          createSuccessMessage: (response) => response.data.message,
          createResponseMessage: (response) => {
            return {
              message: response.message,
              messageType: response.success ? 'success' : 'failure',
              loading: false,
              autoClose: true,
            }
          },
          onSuccess: (res) => {
            setLoading(false)
                if(res.data.success){
                    window.location.reload()
                }
          },
        })
        setLoading(false)
    }

    const fetchData = () => {
        const url = `/communities/${community.id}/classroom/${course.id}/chapters.json`
        makeRequest(url, 'get', { params: {  } }, {
          contentType: 'application/json',
          loadingMessage: 'Fetching...',
          createSuccessMessage: (response) => response.data.message,
          onSuccess: (res) => {
                setChapters(res.data.chapters)
                setChapterReads(res.data.reads)
                setTotalChapter(res.data.total_chapter)
                setTotalRead(res.data.total_read)
          },
        })
    }

    const deleteChapter = () => {

        let URL = `/communities/course_chapter/${chapterEntry.id}/delete`
        makeRequest(URL, 'delete', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                if(response.data.success){
                    window.location.reload()
                }
            },
          })
      }  
    

    const onShowChapterClick = (chapter) => {
        setShowChapterDetail(true)
        setChapterEntry(chapter)
    }

    const onClickAddNewModule = () => {
        setShowAddNewModal(true)
        setInitialFormData({
            name: '',
            description: '',
            status: 1,
            video_url:''
        })
    }

    const onEditChapter = () => {
        setInitialFormData({
            name: chapterEntry.name,
            description: chapterEntry.description,
            status: chapterEntry.status,
            video_url: chapterEntry.video_url
        })
        setStatus(chapterEntry.status)
        setShowAddNewModal(true)
        setMethod('put');
    }

    const onMarkRead = (checked) => {
        let URL = `/communities/${community.id}/course/${chapterEntry.community_course_id}/chapter/${chapterEntry.id}/read/${checked}`
        makeRequest(URL, 'get', '', {
        contentType: 'application/json',
        loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                if(response.data.success){
                    setChapterReads(response.data.reads)
                    setTotalChapter(response.data.total_chapter)
                    setTotalRead(response.data.total_read)
                }   
            },
        })
    }

    useEffect(() => {
        fetchData()
      }, [])

  return (
    <>
      <div className="container mt-60 communities-detail">
        <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                <div className="sidebar-shadow">
                    <div className="sidebar-heading">
                        <div className="avatar-sidebar">
                            <div className="sidebar-info pl-0">
                                <span className="sidebar-company mb-4" style={{ lineHeight: 'inherit'}}>{ course.name }</span>
                                {/* <div class="dropdowm mb-4">
                                    <button class=" btn-dots btn-dots-abs-right dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu dropdown-menu-light" style={{margin: '0px'}}>
                                        <li><a class="dropdown-item" href="#">Edit course</a></li>
                                        <li><a class="dropdown-item" href="#">Add module</a></li>
                                        <li><a class="dropdown-item" href="#">Delete course</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className='avatar-sidebar' style={{marginBottom: '20px'}}>
                            <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{width: (totalChapter > 0) ? Math.round((totalRead * 100)/totalChapter)+'%' : '0%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{(totalChapter > 0) ? Math.round((totalRead * 100)/totalChapter)+'%' : '0%'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-shadow widget-categories" style={{ border: 'none !important'}}>
                        <ul>
                        {chapters.map((chapter, index) => (
                            <>
                                <a className='a_cursour mt-5' onClick={()=> onShowChapterClick(chapter)} style={{ color: (chapter.id === chapterEntry.id) ? '#2e6ef5' : '' }}>
                                    <li key={index} className={"d-flex justify-content-between align-items-center"+ (chapter.id === chapterEntry.id ? " active" : "")}  >
                                        { chapter.name }
                                    </li>
                                </a>
                            </>
                           ))}
                           { (is_community_admin == 1) &&
                            <li className="d-flex justify-content-between align-items-center">
                                <a className='a_cursour' style={{color: '#007aff'}} onClick={()=> onClickAddNewModule()}> + New Module</a>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-8" style={{paddingTop: '0px', margin: '0px'}}>
                <div className="row">
                     { showChapterDetail == false && 
                            <div className="col-lg-12 mb-30">
                            <div className="card-blog-1 hover-up wow animate__ animate__fadeIn  animated" data-wow-delay=".0s" style={{visibility: 'visible', animationDelay: '0s', animationName: 'fadeIn'}}>
                                <figure className="post-thumb mb-15">
                                    <a href="#">
                                        <img alt="jobhub" src={ (course.cover_image != null && course.cover_image != '') ? course.cover_image : image3} />
                                    </a>
                                </figure>
                                <div className="card-block-info">
                                    <h3 className="post-title mb-15">{ course.name }</h3>
                                    {course && (
                                             <div dangerouslySetInnerHTML={{ __html: course.description }} ></div>
                                    )}
                                </div>
                            </div>
                        </div>
                     }

                    { showChapterDetail == true && 
                            <div className="col-lg-12 mb-30">
                            <div className="card-blog-1 hover-up wow animate__ animate__fadeIn  animated" data-wow-delay=".0s" style={{visibility: 'visible', animationDelay: '0s', animationName: 'fadeIn'}}>
                                <div className="card-block-info">
                                         <div style={{ position: 'relative', textAlign: 'left',display: 'flex', justifyContent: 'space-between', alignItem: 'center'}}>
                                           <h3 className="post-title mb-15">{ chapterEntry.name }</h3>
                                           <div style={{float: 'right'}}>
                                            <span style={{fontWeight: '800'}}>Is Read </span><input type="checkbox" onChange={(e)=> onMarkRead(e.target.checked)} name="mark-read" checked={(chapterReads[chapterEntry?.id]) ? true : false} id="mark-read" title='Mark As Done' style={{marginRight:'30px',width: '34px',height: '18px'}}/> 
                                           { (is_community_admin == 1) && 
                                                <>
                                                    <a className='a_cursour mr-2'  onClick={ () => onEditChapter() } >Edit</a> |
                                                    <a className='a_cursour ml-2' onClick={ () => setShowRemoveConfirmation(true)} >Delete</a>
                                                </>
                                            }
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-5">
                                            {chapterEntry && chapterEntry.video_url != null && chapterEntry.video_url != '' && (
                                                <div style={{ textAlign: 'center'}} className='mb-5'>
                                                    <a href={chapterEntry.video_url} target='_blank'><img src={playImg} height={150} width={150}/></a>
                                                </div>
                                            )}

                                            {chapterEntry && (
                                                    <div dangerouslySetInnerHTML={{ __html: chapterEntry.description }} ></div>
                                            )}
                                        </div>
                                </div>
                            </div>
                        </div>
                     }
                    
                </div>
            </div>
        </div>
    </div>
            <Modal
                onHide={() => setShowAddNewModal(false)}
                show={showAddNewModal}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
                scrollable
            >     
                <Modal.Body>
                    
                        <>
                        <Formik
                            validateOnChange={true}
                            validateOnBlur={false}
                            initialValues={initialFormData}
                            validationSchema={validationSchema}
                            onSubmit={(values)  => { handleSubmit(values)}}
                        >
                            {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                setValues,
                                handleChange,
                                handleSubmit,
                            } = props
                            return (
                                <Form style={{padding: '10px'}}>
                                <div className="form-group">
                                    <label className="control-label">Module Name* :</label>
                                    <div className="">
                                    <input type="text" name="name" className="form-control  w-100" placeholder="Module Name"
                                        value={values.name} 
                                        onChange={handleChange} 
                                        style={{ height: 'auto' }}
                                        error={
                                            Boolean(
                                                errors.name
                                            ) &&
                                            touched.name
                                        }
                                    />
                                    {errors.name && touched.name && (
                                        <span width="28rem" className='error'>{errors.name}</span>
                                    )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Video Link :</label>
                                    <div className="">
                                        <input type='text' value={values?.video_url || ''} onChange={handleChange} className='form-control w-100' 
                                        name='video_url' id="video_url"  placeholder="Video URL"
                                        style={{ height: 'auto' }}
                                        error={
                                            Boolean(
                                                errors.video_url
                                            ) &&
                                            touched.video_url
                                        } />
                                    </div>
                                    {errors.video_url && touched.video_url && (
                                        <span width="28rem" className='error'>{errors.video_url}</span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Description* :</label>
                                    <div className="">
                                    <Field name="numberOfTickets">
                                        {({ field }) => (
                                        <CKEditor
                                            key={editorKey} // Force re-render when the key changes
                                            // data={values.description}
                                            initData={values.description}
                                            config={{
                                                toolbar: [
                                                    ['Cut', 'Copy', 'Paste'],
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Bold', 'Italic', 'Strike'],
                                                    ['Undo', 'Redo'],
                                                    ['Link', 'Unlink'],
                                                    ['Table', 'HorizontalRule'],
                                                    ['Maximize']
                                                ]
                                            }}
                                            onChange={e => onChangeDescription(e, field, values, setFieldValue)}
                                        />
                                    )}
                                    </Field>

                                    {errors.description && touched.description && (
                                        <span width="28rem" className='error'>{errors.description}</span>
                                    )}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between' style={{marginTop: '40px'}}>
                                    <div  className={`deduct`}> 
                                        <label>
                                            <strong>{status ? 'Published' : 'Drafted'}</strong>
                                            <input type="checkbox" defaultChecked={status} onClick={()=>{
                                                handleStatusUpdate(!status)
                                            }}
                                            />
                                            <span />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button type="button" className="btn btn-light icon-send-letter" onClick={() => setShowAddNewModal(false)}>
                                        Cancel
                                    </button>
                                    <button type="submit" disabled={loading} className="btn btn-default  icon-send-letter">
                                    {loading ? (
                                        <Spinner animation="border" variant="light" />
                                    ) : (
                                        (chapterEntry?.id) ? 'Update' :'Save' 
                                    )}
                                    </button>
                                </div>
                                </Form>
                            )
                            }}
                        </Formik>
                        </>
                    
                </Modal.Body>
            </Modal>

            {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete module?"
                    onConfirm={()=>(deleteChapter())}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this!
                </SweetAlert>
            }
    </>
  );
}
export default ChapterList