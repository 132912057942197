import React, { useState, useEffect, useContext, useRef } from 'react'
import image2 from '../../../../../assets/v2/imgs/avatar/ava_16.png'
import image3 from '../../../../../assets/v2/imgs/placeholder.jpg'
import './../styles/communities.scss'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Elements, StripeProvider } from 'react-stripe-elements'
import TextInput from '../../../common/TextInput/TextInput'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Formik, Form } from 'formik'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CheckoutForm from './CheckoutForm'
import { Spinner } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import * as Yup from 'yup'
import moment from 'moment'

function AboutLeftPenal({ communityId,buttonRef }) {
    const [stripeStatus, setStripeStatus] = useState([])
    const [stripeErrorMessage, setStripeErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [sweetalertMessage, setSweetalertMessage] = useState('')
    const [sweetalertMessageError, setSweetalertMessageError] = useState('')
    const [currentUser, setCurrentUser] = useState(null)
    const [communitySubscription, setCommunitySubscription] = useState(null)
    const [subscriptionDetail, setSubscriptionDetail] = useState(null)
    const [totalMember, setTotalMember] = useState(0)
    const [publishableKey, setPublishableKey] = useState(false)
    const [memberDetail, setMemberDetail] = useState(false)
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showCancelConfirmation, setCancelShowRemoveConfirmation] = useState(false)
    const [community, setCommunity] = useState({})
    const [btnshow, setBtnshow] = useState('')

    const onPaymentClick = () => {
        setShowPaymentModal(true)
    }

    useEffect(() => {
        getInfo()
    }, [])

    const getInfo = () => {
        let URL = `/communities/member/detail/${communityId}`
        makeRequest(URL, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                let data = response.data
                setPublishableKey(data.publishable_key)
                setIsSubscriptionActive(data.publishable_key)
                setCommunity(data.community)
                setMemberDetail(data.member_detail)
                setTotalMember(data.total_member)
                setCommunitySubscription(data.community_subscription)
                setCurrentUser(data.curent_user)
                setBtnshow(data.btnshow)
                setSubscriptionDetail(data.subscriptionDetail)
            },
        })
    }

    const cancelSubscription = () => {
        let URL = `/communities/subscription/cancel/${subscriptionDetail.id}`
        makeRequest(URL, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                window.location.reload()
            },
        })
    }

    const cancelMembership = () => {
        let URL = `/communities/${communityId}/membership/cancel`
        makeRequest(URL, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                window.location.reload()
            },
        })
    }

    const sendJoinRequest = () => {
        let URL = `/communities/member/join/${communityId}`
        makeRequest(URL, 'get', '', {
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            onSuccess: (response) => {
                window.location.reload()
            },
        })
    }

    const validationSchemaWithCard = Yup.object({
        nameCard: Yup.string().trim()
            .matches(/^[a-zA-Z ]+$/, "Please enter alphabets only")
            .required('Card Name is Required')
    })

    const createSubscription = (values, stripe_payload) => {
        let URL = `/communities/subscription/payment`
        const payload = new FormData()
        payload.append('subscription[community_id]', community.id)
        payload.append('subscription[first_name]', currentUser.first_name)
        payload.append('subscription[last_name]', currentUser.last_name)
        payload.append('subscription[email]', currentUser.email)
        payload.append('subscription[user_id]', currentUser.id)
        payload.append('subscription[plan_id]', communitySubscription.id)
        payload.append('subscription[name_card]', values?.nameCard || '')
        payload.append('subscription[token]', (stripe_payload?.token) ? JSON.stringify(stripe_payload.token) : '')
        makeRequest(URL, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if (responce.success) {
                    setLoading(false)
                    setSweetalertMessage('Your Subscription is Activated Now.')
                } else {
                    setLoading(false)
                    setSweetalertMessageError(responce.message)
                }
            },
            createErrorMessage: (e) => {
                setLoading(false)
                return e.message
            },
        })
    }

    const handleSubmit = async (values) => {
        setLoading(true)
        if (communitySubscription?.amount > 0) {
            stripeStatus.createToken({ name: values.nameCard }).then(stripe_payload => {
                if (stripe_payload.token) {
                    createSubscription(values, stripe_payload)
                }
                if (stripe_payload.error) {
                    setLoading(false)
                    setStripeErrorMessage(stripe_payload.error.message)
                }
            });
        } else {
            createSubscription(values, '')
        }
    };

    const paymentSuccess = () => {
        window.location.reload()
    }

    return (
        <>
            <div className="card" >
                <img style={{ minHeight: '200px', maxHeight: '200px', minWidth: '100%', maxWidth: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} alt={community.name} src={(community?.cover_image && community?.cover_image != '' && community?.cover_image != null) ? community?.cover_image : image3} className="card-img-top" />
                <div className="card-body">
                    <h5 className="card-title">{community.name}</h5>
                    <p className="card-text">{community.small_description}</p>
                    <div className="text-start mt-20">
                        {currentUser &&
                            <>
                                {(community.paid == true && communitySubscription?.amount > 0 && btnshow == 'join-paid') &&
                                    <a className="form-control btn btn-default btn-join-custom" ref={buttonRef} onClick={onPaymentClick}> JOIN  ${(communitySubscription != null && communitySubscription?.amount) ? communitySubscription?.amount : 0} /month</a>
                                }

                                {(community.paid == false && btnshow == 'free-join') &&
                                    <a className="form-control btn btn-default btn-join-custom" ref={buttonRef} onClick={sendJoinRequest}>JOIN GROUP</a>
                                }

                                {(btnshow == 'requested') &&
                                    <><a className="form-control btn btn-primary btn-join-custom">Membership Pending</a>
                                        <p style={{ textAlign: 'center', color: '#b66464' }}>Waiting for admin approval</p>
                                    </>
                                }

                                {(btnshow == 'setting') &&
                                    <>
                                        <a href={`/communities/${community.id}/general_setting`} className="form-control btn btn-default btn-join-custom">Settings</a>
                                        {(memberDetail.total_member_pending > 0) &&
                                            <span style={{ paddingLeft: '13%', fontSize: '800', color: '#5f8dd2' }}>({memberDetail.total_member_pending}) <a href={`/communities/${community.id}/members/pending`} style={{ textAlign: 'center', color: '#5f8dd2', cursor: 'pointer', textDecoration: 'underline' }}>  membership request pending</a></span>
                                        }
                                    </>
                                }

                                {(btnshow == 'sub-cancel') &&
                                    <a className="form-control btn btn-primary btn-join-custom" onClick={() => setShowRemoveConfirmation(true)}>Cancel Subscription</a>
                                }

                                {(btnshow == 'cancel') &&
                                    <span style={{ paddingLeft: '23%', fontSize: '800', color: '#5f8dd2' }}> <a onClick={() => setCancelShowRemoveConfirmation(true)} style={{ textAlign: 'center', color: '#5f8dd2', cursor: 'pointer', textDecoration: 'underline', fontSize: '17px' }}>Cancel Membership</a></span>
                                }
                            </>
                        }
                        {!currentUser &&
                            <>
                                {(community.paid == true && communitySubscription?.amount > 0 && btnshow == 'join-paid') &&
                                    <a className="form-control btn btn-default btn-join-custom" href='/signup/talent'> JOIN  ${(communitySubscription != null && communitySubscription?.amount) ? communitySubscription?.amount : 0} /month</a>
                                }

                                {(community.paid == false && btnshow == 'free-join') &&
                                    <a className="form-control btn btn-default btn-join-custom" href='/signup/talent'>JOIN GROUP</a>
                                }
                            </>
                        }
                    </div>
                    <div className="sidebar-list-job">
                        <ul>
                            <li>
                                <div className="sidebar-icon-item"><i className="fi-rr-shield-check"></i></div>
                                <div className="sidebar-text-info">
                                    <span className="text-description text-capitalize">{community.community_type} Group</span>
                                </div>
                            </li>
                            <li>
                                <div className="sidebar-icon-item"><i className="fi-rr-user"></i></div>
                                <div className="sidebar-text-info">
                                    <span className="text-description">{totalMember} Members</span>
                                </div>
                            </li>
                            <li>
                                <div className="sidebar-icon-item"><i className="fi-rr-clock"></i></div>
                                <div className="sidebar-text-info">
                                    <span className="text-description">{moment(community.created_at).format('MMMM DD, YYYY')}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Modal
                onHide={() => setShowPaymentModal(false)}
                show={showPaymentModal}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                centered
                scrollable
            >     <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ width: '100%', textAlign: 'center' }}>
                        <div>
                            <h4 style={{ fontSize: '18px', color: '#000' }}>{community.name}</h4>
                            <p style={{ fontSize: '15px', color: '#000' }}>${(communitySubscription != null && communitySubscription?.amount) ? communitySubscription?.amount : 0} /month membership.</p>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(communitySubscription?.amount > 0) &&
                        <>
                            <Formik
                                validateOnChange={true}
                                validateOnBlur={true}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    nameCard: ''
                                }}
                                validationSchema={(communitySubscription?.amount > 0) ? validationSchemaWithCard : validationSchemaWithoutCard}
                                onSubmit={(values) => handleSubmit(values)}
                            >
                                <Form>
                                    <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                        <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: '25px' }}>
                                            <TextInput
                                                className={'fullwidthInput'}
                                                label="Name on Card*"
                                                name="nameCard"
                                                type="text"
                                                width="100%"
                                                display="block"
                                                style={{ width: '430px' }}
                                            />
                                            {/* <input
                                            className={'fullwidthInput'}
                                            label="Name on Card*"
                                            name="nameCard"
                                            type="text"
                                            width="100%"
                                            display="block"
                                            style={{height: '39px'}}
                                        /> */}
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: '15px' }}>
                                            <label>Enter Card Detail*</label>
                                            <StripeProvider apiKey={publishableKey}>
                                                <Elements>
                                                    <CheckoutForm setStripeStatus={setStripeStatus} />
                                                </Elements>
                                            </StripeProvider>
                                            <small style={{ color: '#eb1c26' }}>{(stripeErrorMessage) && stripeErrorMessage}</small>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} style={{ marginTop: '15px' }}>
                                            <button
                                                className='btn btn-default'
                                                type="submit"
                                                style={{
                                                    float: 'center',
                                                    width: '80%',
                                                    marginLeft: '10%'
                                                }}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <Spinner animation="border" variant="light" />
                                                ) : (
                                                    (communitySubscription?.amount > 0) ? `JOIN  $${(communitySubscription != null && communitySubscription?.amount) ? communitySubscription?.amount : 0} /month` : 'Submit'
                                                )}
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>
                        </>
                    }
                </Modal.Body>
            </Modal>
            {sweetalertMessage != '' && (
                <SweetAlert success confirmBtnText="Ok" title="Success" onConfirm={paymentSuccess}>
                    {sweetalertMessage}
                </SweetAlert>
            )}
            {sweetalertMessageError != '' && (
                <SweetAlert error title="Payment Failed" onConfirm={(e) => {
                    setSweetalertMessageError('')
                }}>
                    {sweetalertMessageError}
                </SweetAlert>
            )}
            {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to cancel subscription?"
                    onConfirm={() => (cancelSubscription())}
                    onCancel={() => setShowRemoveConfirmation(false)}
                    focusCancelBtn
                >
                    You will not be able to recover this!
                </SweetAlert>
            }
            {showCancelConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to cancel membership?"
                    onConfirm={() => (cancelMembership())}
                    onCancel={() => setCancelShowRemoveConfirmation(false)}
                    focusCancelBtn
                >
                    You will not be able to recover this!
                </SweetAlert>
            }
        </>
    )

}

export default AboutLeftPenal
