import React, { useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
// import styles from './styles/Navbar.module.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import '../../../../assets/v2/css/main.css'
import LogoGroups from '../../../../assets/images/logos/cardinalgroups.png'

const NavbarLogin = ({
    base_url,
    host_url,
    is_groups_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    var SideName = (is_groups_url)?'Cardinal Groups':'CardinalTalent';
    const authLinks = (
        <>
            <li>
                <a
                    href="https://cardinaltalent.ai/"
                    className={`${currentPathname === '/talent_home'
                        ? 'active'
                        : ''
                        }`}
                >
                    Home
                </a>
            </li>
            {/* <li>
                <a
                    href="/job_search"
                    className={`${currentPathname === '/job_search'
                        ? 'active'
                        : ''
                        }`}
                >
                    Find Jobs
                </a>
            </li>
            <li>
                <a
                    href="/join"
                    className={`${currentPathname === '/join'
                        ? 'active'
                        : ''
                        }`}
                >
                    Join My Coaching
                </a>
            </li> */}
        </>
    )
    return (
        <>
            <header className={"header sticky-bar"}>
                <div className={'container'}>
                <div className={"main-header"}>
                    <div className={"header-left"}>
                        <div className={"header-logo"}>
                            <a href="/" className={"d-flex img1"}>
                                <Image src={(is_groups_url)?LogoGroups:LogoWithText} alt={SideName} />
                            </a>
                        </div>
                        <div className={"header-nav"}>
                            <nav className={"nav-main-menu d-none d-xl-block"}>
                                <Nav className="align-items-center flex-wrap">
                                    {/* <ul className={'main-menu'} style={{ display: 'flex', alignItems: 'center' }}>
                                        {authLinks}
                                    </ul> */}
                                </Nav>
                            </nav>
                            <div className={"burger-icon burger-icon-white"}>
                                <span className={"burger-icon-top"}></span>
                                <span className={"burger-icon-mid"}></span>
                                <span className={"burger-icon-bottom"}></span>
                            </div>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="block-signin">
                            <ul className="main-menu">
                                <li className="has-children">
                                <a href="#" className="text-link-bd-btom hover-up">Sign Up</a>
                                    <ul className="sub-menu">
                                        <li><a href="/signup/talent">Talent</a></li>
                                        <li><a href="/signup/employer">Employer</a></li>
                                        <li><a href="/signup/recruiter">Recruiter</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href={`/users/sign_in?page=${currentPathname == '/welcome/employer'
                                ? currentPathname
                                : currentPathname == '/welcome/recruiter'
                                    ? currentPathname
                                    : '/'
                            }`} className="btn btn-default btn-shadow ml-20 hover-up btn-white" style={{color:'#fff'}}>Sign in</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </header>
            <div className={"mobile-header-active mobile-header-wrapper-style perfect-scrollbar"}>
            <div className={"mobile-header-wrapper-inner"}>
                <div>
                    <div className={"burger-icon burger-icon-white"}>
                        <span className={"burger-icon-top"}></span>
                        <span className={"burger-icon-mid"}></span>
                        <span className={"burger-icon-bottom"}></span>
                    </div>
                </div>
                <div className={"mobile-header-content-area"}>
                    <div className={"perfect-scroll"}>
                        <div className={"mobile-menu-wrap mobile-header-border d-xl-none"}>
                            <nav>
                                <Nav>
                                    <ul className={'mobile-menu font-heading'}>
                                        <li><a href="/" >Sign In</a></li>
                                        <li className="has-children">
                                            <a href="#">Sign Up</a>
                                            <ul className="sub-menu mt-10">
                                                <li><a href="/signup/talent">Talent</a></li>
                                                <li><a href="/signup/employer">Employer</a></li>
                                                <li><a href="/signup/recruiter">Recruiter</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Nav>
                            </nav>
                        </div>
                        <div className={"mobile-account"}>
                            <ul className={"mobile-menu font-heading"}>
                                <li><a href="/communities" >Community</a></li>
                                <li><a href="/talent" >Talent</a></li>
                                <li><a href="/employer" >Employer</a></li>
                                <li><a href="/signup/recruiter">Recruiter</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default NavbarLogin
