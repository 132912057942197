import React, { useState } from 'react'
import UploadResumeSectionV2 from '../../common/UploadResumeSection/UploadResumeSectionV2'

const TalentDashboardV2 = ({
    currentUser,
    hasResume,
    webSocketsUrl,
    resume,
    resume_service_url,
    applied_jobs_count,
    match_score_status,
    has_tech_group
}) => {
    const [jobFilterText, setJobFilterText] = useState('')
    const [activePage, setActivePage] = useState(0)
    const [errorFetchingJob, setErrorFetchingJob] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [jobs, setJobs] = useState([])
    const [totalJobsCount, setTotalJobsCount] = useState(0)
    const [addAddTechList, setAddTechList] = useState(has_tech_group ? false : true)
    
    return (
        <UploadResumeSectionV2
            webSocketsUrl={webSocketsUrl}
            uploadedResume={resume}
            currentUser={currentUser}
            hasResume={hasResume}
            resume={resume}
            jobs={jobs}
            setJobs={setJobs}
            totalJobsCount={totalJobsCount}
            setTotalJobsCount={setTotalJobsCount}
            jobFilterText={jobFilterText}
            setJobFilterText={setJobFilterText}
            activePage={activePage}
            setActivePage={setActivePage}
            errorFetchingJob={errorFetchingJob}
            setErrorFetchingJob={setErrorFetchingJob}
            loading={loading}
            setLoading={setLoading}
            pageCount={pageCount}
            setPageCount={setPageCount}
            resumeServiceUrl={resume_service_url}
            matchScoreStatus={match_score_status}
            render={({
                uploadedResume,
                setUploadedResume,
                handleFiles,
                showUploadResumeModal,
                setShowUploadResumeModal,
            }) => {
                return (
                    <>
                        {/* <UploadApplyControl
                            uploadedResume={uploadedResume}
                            setUploadedResume={setUploadedResume}
                            handleFiles={handleFiles}
                            showUploadResumeModal={showUploadResumeModal}
                            setShowUploadResumeModal={
                                setShowUploadResumeModal
                            }
                            applied_jobs_count={applied_jobs_count}
                            resumeServiceUrl={resume_service_url}
                        /> */}
                    </>
                )
            }}
        />
    )
}

export default TalentDashboardV2
