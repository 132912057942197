import React, { Fragment,useState,useEffect,KeyboardEventHandler } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Typography, ImageContainer } from './styles/AccountSettingPage.styled'
import CreatableSelect from 'react-select/creatable';
import ProfileUploader from '../CandidateProfilePage/ProfileUploader'
import styles from './styles/AccountSettingPage.module.scss'
import '../AccountSettingPage/styles/account_setting.css'
import CandidateTwoImage from '../../../../assets/images/img_avatar.png'
import Modal from '../../common/Styled components/Modal'
import CropImageModal from '../CandidateViewProfilePage/CropImageModal'

const Schema = {
    firstName: Yup.string()
        .required('First Name is required')
        .test(
            'first name alphabets only',
            'First Name can only contain alphabet characters and one space in between if there are two words',
            function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
            }
        ),

    lastName: Yup.string()
        .required('Last Name is required')
        .test(
            'last name alphabets only',
            'Last Name can only contain alphabet characters and one space in between if there are two words',
            function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
            }
        ),
    phoneNumber: Yup.string()
        .typeError('Invalid Phone number, please add numbers only')
        .nullable(true)
        .test(
            'phone number digits only',
            'Phone number must contain 10 to 15 digits only',
            function (value) {
                if( value != '' && value != undefined){
                    const regex = /^\d{10,15}$/g
                    return regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim())
                }
                return true
                
            }
        ),
}

let finalLinkedInURL = ''

const experienceList = [
    { label: 'Entry (0-1 year)', value: 'Entry' },
    { label: 'Intermediate (2-3 years)', value: 'Intermediate' },
    { label: 'Advanced (4-5 years)', value: 'Advanced' },
    { label: 'Experienced (6-9 years)', value: 'Experienced' },
    { label: 'Expert (10+ years)', value: 'Expert' },
]

function EditAccountSetting(props) {
    const {
        firstName,
        lastName,
        email,
        handleSaveBasic,
        phoneNumber,
        linkedinProfileUrl,
        role,
        job_title,
        avatar,
        location,
        experience,
        skill,
        setIsEditBasicInfo,
        setLinkedinId
    } = props
    const linkedinProfileUrlScema = Yup.string().trim()
        .nullable(true)
        .test(
            'linkedin only',
            'Invalid url, please add Linkedin url only',
            function (value) {
                if(value){
                    try {
                        let hostname = new URL(value).hostname
                        finalLinkedInURL = value
                        setLinkedinId('')
                        if(value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/'){
                            return false
                        }
                        return (
                            hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                        )
                    } catch (error) {
                        finalLinkedInURL = value
                        setLinkedinId('')
                        if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                            return false
                        }
                        let checkDomainExist = value.indexOf("linkedin.com/")
                        if(checkDomainExist == -1){
                            return false
                        }else if(checkDomainExist == 0){
                            let splitValue = value.split('linkedin.com/')
                            if(splitValue[1] === ''){ 
                                return false
                            }
                            finalLinkedInURL = 'https://www.'+ value
                            setLinkedinId('')
                            return true
                        }else{
                            let splitValue = value.split('linkedin.com/')
                            if(splitValue[0] === 'www.'){
                                finalLinkedInURL = 'https://'+ value
                               setLinkedinId('')
                                return true
                            }
                        }
                        return false
                    }
                }
                return true
    
            }
        )
    
    const talentSchema = {
        ...Schema,
        linkedinProfileUrl: linkedinProfileUrlScema,
    }
    
    const [profile, setProfile] = useState()
    const [openInner, setOpenInner] = useState(false)
    const [avatar_url, setAvatarUrl] = useState(avatar)
    const [defaultLocation, setdefaultLocation]  = useState(location === null ? []  : location.split(',').map(loc => ({
        value: loc,
        label: loc,
    })))
    const [defaultSkills, setDefaultSkills]  = useState([])
    const [defaultJT, setDefaultJT]  = useState([])
    const [defaultExperiences, setDefaultExperiences]  = useState([])
    const [selectLocations, setValue] = useState(location === null ? []  : location.split(',').map(loc => ({
        value: loc,
        label: loc,
    })))
    const [locationOptions, setLocationOptions] = useState([])
    const [validationErrors, setValidationErrors] = useState([])
    const [inputSkill, setInputSkill] = React.useState('');
    const [skills, setSkills] = useState(skill === null ? []  : skill.split(',').map(sk => ({
        value: sk,
        label: sk,
    })))
    const [inputJT, setInputJT] = React.useState('');
    const [jts, setJts] = useState(job_title === null ? []  : job_title.split(',').map(jt => ({
        value: jt,
        label: jt,
    })))
    const [experienceOptions, setExperienceOptions] = useState(experienceList)
    const [experiences, setExperiences] = useState(experience === null ? []  : experience.split(',').map(exp => ({
        value: exp,
        label: exp,
    })))

    const validationSchema = role === 'talent' ? Yup.object({...talentSchema}) :Yup.object({...Schema})

    useEffect(() => {
        fetchRequired()
    }, [])

    const format_logo_url = (avatar_url) => {
        if (typeof avatar_url == 'object') {
            return URL.createObjectURL(avatar_url)
        }
        return avatar_url
    }

    const handlePictureError = (error) => {
        let errors = []
        errors.push(error)
        setValidationErrors(errors)
    }
    const handlePicture = (file) => {
        if (file && file.size > 1024 * 1024 * 4) {
            handlePictureError('File size cannot exceed more than 4MB')
        } else {
            let errors = []
            setValidationErrors(errors)
            setProfile(format_logo_url(file))
        }
    }
    const saveCropImage = (upImg) => {
        setAvatarUrl(upImg)
        setOpenInner(!openInner)
    }

    const fetchRequired = async () => {
        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}`,
            label: `${res.city}`,
        }))
        setLocationOptions([...locationArray])
    }

    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleCreate = (inputValue,key) => {
        const trimmedInput = inputValue.trim();
        if (trimmedInput) {
            const newOption = createOption(inputValue);
            if(key == 'location'){
                setLocationOptions((prev) => [...prev, newOption]);
                setValue((prev) => [...prev, newOption]);
            }else if(key == 'exp'){
                setExperienceOptions((prev) => [...prev, newOption]);
                setExperiences((prev) => [...prev, newOption]); 
            }
        }
    };

    const handleKeyDown = (event,key) => {
        switch (event.key) {
          case "Enter":
          case "Tab":
            if(key == 'jt'){
                if (!inputJT) return;
                setJts((prev) => [...prev, createOption(inputJT)]);
                setInputJT("");  
            }else if(key == 'skill'){
                if (!inputSkill) return;
                setSkills((prev) => [...prev, createOption(inputSkill)]);
                setInputSkill("");  
            }
            event.preventDefault();
        }
    };

    function signOut(){
        localStorage.removeItem("user")
        if(localStorage.getItem("meet-sourcer-showed")){
          localStorage.removeItem("meet-sourcer-showed")
        }
        fetch('/users/sign_out', {method: 'DELETE'})
        .then((res) => {
          window.location.href = '/users/sign_in'
        })
    }

    return (
        <>
            <section className="section-box" style={{ overflow: 'inherit !important' }}>
                <div className="container bootstrap snippets bootdeys">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="panel panel-default">
                                <div className="panel-heading" style={{ backgroundColor: '#622fff85' }}>
                                    <h4 className="panel-title" style={{ color: 'white' }}>Account Settings</h4>
                                </div>
                                <div className="panel-body">
                                    <Formik
                                        initialValues={{
                                            firstName: firstName,
                                            lastName: lastName,
                                            phoneNumber: phoneNumber,
                                            linkedinProfileUrl: linkedinProfileUrl,
                                        }}
                                        validationSchema={validationSchema}
                                        validate={(values) => {
                                            const errors = {}
                                            // Handle Title Error 
                                            if (jts.length == 0) {
                                                errors.jts = 'Job Titles are required'
                                            }
                                            if (jts.length > 20) {
                                                errors.jts = 'Max 20 job titles are allowed'
                                            }
                                            // Handle Experience Error 
                                            if (experiences.length == 0) {
                                                errors.experiences = 'Experiences are required'
                                            }
                                            // Handle Title Error 
                                            if (skills.length == 0) {
                                                errors.skills = 'Skills are required'
                                            }
                                            // Handle Location Error
                                            if (selectLocations.length == 0) {
                                                errors.location = 'Locations are required'
                                            }
                                            if (selectLocations.length > 20) {
                                                errors.location = 'Max 20 locations are allowed'
                                            }
                                            return errors
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            values['job_titles'] = jts?.map(item => item.value).join(',')
                                            values['experiences'] = experiences?.map(item => item.value).join(',')
                                            values['locations'] = selectLocations?.map(item => item.value).join(',')
                                            values['skills'] = skills?.map(item => item.value).join(',')
                                            values['avatar_url'] = avatar_url
                                            setTimeout(() => {
                                                setSubmitting(false)
                                            }, 500)
                                            handleSaveBasic(values)
                                        }}
                                    >
                                        {(props) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting,
                                                handleChange,
                                                handleSubmit,
                                            } = props
                                            return (
                                                <Fragment>
                                                    <div className="row m1">
                                                        <div className="col-md-3">
                                                            <ImageContainer>
                                                                <img
                                                                    src={
                                                                        avatar_url == null || avatar_url == ''
                                                                            ? CandidateTwoImage
                                                                            : format_logo_url(avatar_url)
                                                                    }
                                                                />

                                                            </ImageContainer>
                                                            <ProfileUploader
                                                                style={{ width: '180px' }}
                                                                onFileSelectSuccess={(file) => {
                                                                    handlePicture(file)
                                                                    setOpenInner(!openInner)
                                                                }
                                                                }
                                                                onFileSelectError={({ error }) =>
                                                                    handlePictureError(error)
                                                                }
                                                                isProfilePicture={'profilePicture'}
                                                            />
                                                            {profile && (
                                                                <Modal
                                                                    width={'50%'}
                                                                    isOpen={openInner}
                                                                    onBlur={() => setOpenInner(!openInner)}
                                                                >
                                                                    <CropImageModal
                                                                        setOpenInner={setOpenInner}
                                                                        profile={profile}
                                                                        saveCropImage={saveCropImage}
                                                                        handlePicture={(file) => { handlePicture(file) }}
                                                                    />
                                                                </Modal>
                                                            )}
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label className="control-label">First Name</label>
                                                                <div className="">
                                                                    <input type="text" name="firstName" className="form-control" placeholder="Enter First Name"
                                                                        value={values.firstName} 
                                                                        onChange={handleChange} 
                                                                        style={{ height: 'auto' }}
                                                                        error={
                                                                            Boolean(
                                                                                errors.firstName
                                                                            ) &&
                                                                            touched.firstName
                                                                        }
                                                                    />
                                                                    {errors.firstName && touched.firstName && (
                                                                        <Typography>{errors.firstName}</Typography>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label">Last Name</label>
                                                                <input type="text" name="lastName" className="form-control" placeholder="Enter Last Name"
                                                                    value={values.lastName} 
                                                                    onChange={handleChange} 
                                                                    style={{ height: 'auto' }}
                                                                    error={
                                                                        Boolean(
                                                                            errors.lastName
                                                                        ) &&
                                                                        touched.lastName
                                                                    }
                                                                />
                                                                {errors.lastName && touched.lastName && (
                                                                    <Typography>{errors.lastName}</Typography>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-label">Linkedin profile</label>
                                                        <input type="text" name="linkedinProfileUrl" className="form-control" placeholder="Enter Linkedin profile"
                                                            value={values.linkedinProfileUrl} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto' }}
                                                            error={
                                                                Boolean(
                                                                    errors.linkedinProfileUrl
                                                                ) &&
                                                                touched.linkedinProfileUrl
                                                            }
                                                        />
                                                        {errors.linkedinProfileUrl && touched.linkedinProfileUrl && (
                                                            <Typography>{errors.linkedinProfileUrl}</Typography>
                                                        )}
                                                    </div>
                                                    <div className="form-group multiselectui">
                                                        <label className="control-label">Job Titles</label>
                                                        <CreatableSelect
                                                            inputValue={inputJT}
                                                            isClearable
                                                            isMulti
                                                            menuIsOpen={false}
                                                            onChange={(newValue) => setJts(newValue)}
                                                            onInputChange={(newValue) => setInputJT(newValue)}
                                                            onKeyDown={(event) => handleKeyDown(event,'jt')}
                                                            placeholder="Type something and press enter..."
                                                            value={jts}
                                                        />
                                                        {errors.jts && (
                                                            <Typography>{errors.jts}</Typography>
                                                        )}
                                                    </div>
                                                    <div className="form-group multiselectui">
                                                        <label className="control-label">Skills</label>
                                                        <CreatableSelect
                                                            inputValue={inputSkill}
                                                            isClearable
                                                            isMulti
                                                            menuIsOpen={false}
                                                            onChange={(newValue) => setSkills(newValue)}
                                                            onInputChange={(newValue) => setInputSkill(newValue)}
                                                            onKeyDown={(event) => handleKeyDown(event,'skill')}
                                                            placeholder="Type something and press enter..."
                                                            value={skills}
                                                        />
                                                        {errors.skills && (
                                                            <Typography>{errors.skills}</Typography>
                                                        )}
                                                    </div>
                                                    <div className="form-group multiselectui">
                                                        <label className="control-label">Experiences</label>
                                                        <CreatableSelect
                                                            defaultValue={defaultExperiences}
                                                            isMulti
                                                            onCreateOption={(event) => {
                                                                handleCreate(event, 'exp');
                                                            }}
                                                            onChange={(newValue) => setExperiences(newValue)}
                                                            options={experienceOptions}
                                                            value={experiences}
                                                        />
                                                        {errors.experiences && (
                                                            <Typography>{errors.experiences}</Typography>
                                                        )}
                                                    </div>
                                                    <div className="form-group multiselectui">
                                                        <label className="control-label">Locations</label>
                                                        <CreatableSelect
                                                            defaultValue={defaultLocation}
                                                            isMulti
                                                            onCreateOption={(event) => {
                                                                handleCreate(event, 'location');
                                                            }}
                                                            onChange={(newValue) => setValue(newValue)}
                                                            options={locationOptions}
                                                            value={selectLocations}
                                                        />
                                                        {errors.location && (
                                                            <Typography>{errors.location}</Typography>
                                                        )}
                                                    </div>
                                                    <div className="form-group" style={{zIndex: '0'}}>
                                                        <div className="col-sm-10 col-sm-offset-2">
                                                            <button type="submit" onClick={handleSubmit} className="btn btn-default" style={{margin: '0px !important'}}>Update</button>
                                                            {/* <button type="reset" className="btn btn-default" style={{margin: '0px !important'}}>Cancel</button> */}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        }}
                                    </Formik>
                                    
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="button" onClick={signOut} className="btn btn-default" style={{ background: '#bdbdbd', color: 'black'}}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditAccountSetting
