import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SubmissionResultsBlock from './SubmissionResultsBlock/SubmissionResultsBlock'

const SubmissionPage = (props) => {
    const [submission, setSubmission] = useState([])
    const [totalJobsCount, setTotalJobsCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [perPage, setPerPage]= useState(0)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [activePage, setActivePage] = useState(0)
    const [errorSearchingJobs, setErrorSearchingJobs] = useState(null)

    useEffect(() => {
        fetchAppledJobs()
    }, [activePage])

    const submitJobSearch = (event) => {
        event.preventDefault()
        setActivePage(0)
        fetchAppledJobs()
    }

    const handleInputChange = (value) => {
        setInputValue(value)
    }

    const fetchAppledJobs = async () => {
        const url = 'submissions.json'
        setLoading(true)
        // const appled_job_search = {
        //     keyword: inputValue,
        // }
        // const payload = JSON.stringify({ appled_job_search })
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')

        try {
            const response = await axios.get(
                `${url}?page=${activePage + 1}&query=${inputValue}`,
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': CSRF_Token,
                    },
                }
            )
            const submissions_data = response.data
            const submissions = submissions_data.submissions
            const total_count = submissions_data.total_count
            const total_pages = submissions_data.total_pages
            const per_page = submissions_data.per_page
            setTotalJobsCount(total_count)
            setTotalPages(total_pages)
            setSubmission(submissions)
            setPerPage(per_page)
        } catch (e) {
            setErrorSearchingJobs(e.message)
        }
        setLoading(false)
    }

    const showJobDetails = () =>{
        window.location = `/jobs/${jobId}.json`
    }


    return (
        <div className="submission-page">
            <div>
                <>
                    <SubmissionResultsBlock
                        submission={submission}
                        totalJobsCount={totalJobsCount}
                        pageCount={totalPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        errorSearchingJobs={errorSearchingJobs}
                        setErrorSearchingJobs={setErrorSearchingJobs}
                        loading={loading}
                        inputValue={inputValue}
                        showJobDetails={showJobDetails}
                        submitJobSearch={submitJobSearch}
                        handleInputChange={handleInputChange}
                        perPage={perPage}
                    />
                </>
            </div>
        </div>
    )
}

export default SubmissionPage
