import React, { useState, useEffect, useReducer } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { H1, StyledForm, Label, Button, P } from '../styles/UserManagementEditPage.styled'
import TextInput from '../shared/TextInput'
import Select from '../shared/Select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from 'react-select/async-creatable'
import RadioInput from '../shared/RadioInput'
import ReactSelect from 'react-select';
import { reducer, JobStoreContext } from '../../../../stores/Admin/JobStore'
import { CKEditor } from 'ckeditor4-react';
import '../styles/Jobs.scss'
import isEmpty from 'lodash.isempty'
import axios from 'axios';

const techTalentList = [
    { value: 'Accounting / Finance', label: 'Accounting / Finance' },
    { value: 'Account Executives', label: 'Account Executives' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Customer Success', label: 'Customer Success' },
    { value: 'Designers', label: 'Designers' },
    { value: 'HR', label: 'HR' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Marketers', label: 'Marketers' },
    { value: 'Recruiters', label: 'Recruiters' },
    { value: 'SDRs', label: 'SDRs' },
    { value: 'Software Engineers', label: 'Software Engineers' },
];

const JobsCreate = ({ job, organization, industry, method, job_owner, organization_recruiters, job_locations, job_groups, find_person_id }) => {
    const [isLoading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [selectedOrganization, setSelectedOrganization] = useState([{ value: organization.id, label: organization.name }])
    const [member_options, setOptions] = useState([])
    const [industries, setIndustries] = useState(industry.map((ind) => ({ value: ind.key, label: ind.value })))
    const [selectedLocation, setSelectedLocation] = useState(job?.location)
    const [defaultLocation, setdefaultLocation] = useState([{ value: job?.location, label: job.location !== "null" ? job.location : 'No location found' }])
    const [selectedOwner, setSelectedOwner] = useState(job_owner?.id)
    const [defaultOwner, setdefaultOwner] = useState(job_owner ? [{ value: job_owner?.id, label: `${job_owner?.first_name} ${job_owner?.last_name}`, email: job_owner?.email }] : [])
    const [ownerOptions, setOwnerOptions] = useState(job_owner && organization_recruiters && organization_recruiters?.some(el => el.user_id === job_owner?.id) ? [...organization_recruiters.map((el) => ({ value: el.user_id, label: `${el?.first_name} ${el?.last_name}`, email: el?.email }))] :
        (job_owner && organization_recruiters) ? [{ value: job_owner?.id, label: `${job_owner?.first_name} ${job_owner?.last_name}`, email: job_owner?.email }, ...organization_recruiters.map((el) => ({ value: el.user_id, label: `${el?.first_name} ${el?.last_name}`, email: el?.email }))] : []
        )
    const [selectedGroup, setSelectedGroup] = useState(job?.group_id)
    const [selectedTechGroup, setSelectedTechGroup] = useState(job?.tech_group?.trim()?.length > 0 ? [...job?.tech_group.split(',').map((tech_group) => {
        return {
            value: tech_group.trim(),
            label: tech_group.trim(),
            };
        })] : [])
    const [selectedIndustry, setSelectedIndustry] = useState(job?.industry?.trim()?.length > 0 ? [...job?.industry.split(',').map((ind) => {
        return {
            value: ind.trim(),
            label: ind.trim(),
            };
        })] : [])
    const [jobGroup, setJobGroup] = useState(job_groups && [...job_groups?.map((el) => ({ value: el.value, label: el?.label }))])
    const [techTalent, setTechTalent] = useState(techTalentList)
    const [defaultGroup, setDefaultGroup] = useState(job?.group_id && job_groups && job_groups.filter(el => el.value === job.group_id).map(group => ({ value: group.value, label: group.label })))
    const expiryDate = moment(job?.expiry_date).format('YYYY-MM-DD')
    const beforeExpiryDate = moment(job?.expiry_date).subtract(7, 'day').format('YYYY-MM-DD')
    const currentDate = moment(new Date()).format('YYYY-MM-DD')
    const [descriptionAdd, setDescriptionAdd] = useState(job?.description)
    const [addNotes, setAddNotes] = useState(job?.add_notes)
    const [mailMessage, setMailMessage] = useState(job?.in_mail_message)
    const [errorDescription, setErrorDescription] = useState()
    const [errorHiringEmail, setErrorHiringEmail] = useState()
    const [errorJobOwner, setErrorJobOwner] = useState('')
    const [errorOrganization, setErrorOrganization] = useState('')
    const [errorJobGroup, setErrorJobGroup] = useState('')
    const [errorJobTechGroup, setErrorJobTechGroup] = useState('')
    const [errorJobIndustry, setErrorJobIndustry] = useState('')
    const [organizationType, setOrganizationType] = useState(organization.organization_type)
    const [organizationRecruiterPresent, setOrganizationRecruiterPresent] = useState()
    const [locationPreference, setLocationPreference] = useState()
    const [locationIds, setLocationIds] = useState()
    const [linkedinURL, setLinkedinURL] = useState(job.linkedin_job_url || false)
    const [notificationemail, setPrimaryOwnerEmail] = useState(job.notificationemails || '')
    const [recruitersList, setRecruitersList] = useState([])
    const [errorNotificationEmail, setErrorNotificationEmail] = useState('')
    const [automationLimitError, setAutomationLimitError] = useState('')
    const [errorDepart, setErrorDepart] = useState('')
    const {
        organization_id,
        name,
        location,
        description,
        skills,
        nice_to_have_skills,
        notificationemails,
        referral_amount,
        keywords,
        nice_to_have_keywords,
        department,
        experience_years,
        prefered_titles,
        prefered_industries,
        school_names,
        company_names,
        location_names,
        status,
        create_lead,
        campaign_status,
        hiring_manager_email,
        job_view_type,is_original_job,is_automation,automation_limit,job_salary,add_notes } = job

    let initialState = {
        ...initialState,
        ...{
            job,
            organization_id,
            name,
            location,
            description,
            skills,
            nice_to_have_skills,
            notificationemails,
            referral_amount,
            keywords,
            nice_to_have_keywords,
            department,
            experience_years,
            prefered_titles,
            prefered_industries,
            school_names,
            company_names,
            location_names,
            status,
            create_lead,
            campaign_status,
            hiring_manager_email,
            job_view_type,
            is_original_job,
            is_automation,
            automation_limit,
            job_salary,
            add_notes
        }
    }
    const job_status = job?.status ? job.status : ''
    const [state, dispatch] = useReducer(reducer, initialState)
    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/admin/organizations/fetch_cardinal_member_organization?search=${inputValue}`)
            .then((res) => res.json())
            .then((res) => {
                let { organizations } = res
                setLoading(false)
                setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
            })
            .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const handleMultiPreferences = (values) => {
        setLocationPreference([...values.map(({ value, label }) => ({ value: value, label: label }))])
        setLocationIds([...values.map(({ value, label }) => value)])
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        setErrorHiringEmail('')
        return str;
    }
    const locationChange = (str) => {
        setSelectedLocation(str.label)
    }
    const ownerChange = (str) => {
        setSelectedOwner(str.value)
        setPrimaryOwnerEmail(primaryOwnerEmail(str.value))
        setErrorJobOwner('')
    }
    const groupChange = (str) => {
        setSelectedGroup(str.value)
        setErrorJobGroup('')
    }
    const techGroupChange = (event) => {
        setSelectedTechGroup(event.map((tech_group) => {
            return {
                value: tech_group.value.trim(),
                label: tech_group.label.trim(),
                };
            }))
        setErrorJobTechGroup('')
    }
    const industryChange = (event) => {
        setSelectedIndustry(event.map((tech_group) => {
            return {
                value: tech_group.value.trim(),
                label: tech_group.label.trim(),
                };
            }))
            setErrorJobIndustry('')
    }
    const handleSelectOption = (selectedOptions) => {
        setSelectedOrganization([selectedOptions])
        organizationGet(selectedOptions.value)
        getOrganizationRecruiter(selectedOptions.value)
        setErrorOrganization('')
    }

    const organizationGet = (id) => {
        const URL = `/api/v1/company/organizations/${id}`
        makeRequest(URL, 'get', {}, {
            createResponseMessage: (response) => {
            },
            onSuccess: (response) => {
                setOrganizationType((response.data.organization.organization_type) ? response.data.organization.organization_type : null)
            }
        })
    }

    const getOrganizationRecruiter = async (id) => {
        const URL = `/admin/jobs/get_job_owner?organization_id=${id}`
        let owners = null
        await makeRequest(URL, 'get', {}, {
            createResponseMessage: (response) => {
            },
            onSuccess: (response) => {
                owners = response.data.owners
                console.log(owners)
                setRecruitersList(owners) // Todo : Not a good solution , form need to be refactor
            }
        })
        setOwnerOptions([...owners.map((owner) => ({ value: owner.user_id, label: `${owner.first_name} ${owner.last_name}`, email: owner.email }))])
    }

    const validOrganizationRecruiter = async (org_id) => {
        const formData = new FormData()
        formData.append(`organization_id`, org_id)
        formData.append(`user_id`, selectedOwner)
        const URL = `/admin/jobs/create_organization_member`
        let present = null
        await makeRequest(URL, 'post', formData, {
            createResponseMessage: (response) => {
            },
        })
    }

    const saveJob = async (newJob) => {
        if (method === 'put') {
            dispatch({ type: 'update_job', value: newJob, id: job.id })
        } else {
            dispatch({ type: 'add_job', value: newJob })
        }
             
    }
    const filterOptions = [
        { value: 'active', label: 'Active' },
        { value: 'expired', label: 'Expired' },
    ]
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#f5f7ff', border: 'none', height: '45px' })
    };

    const LocationPrefcolourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#f5f7ff', border: 'none', height: 'auto', zIndex: '15' })
    };

    const exdendExpiredJob = async () => {
        const formData = new FormData()
        for (const key in job) {
            if (key != 'status') {
                formData.append(`job[${key}]`, job[key])
            } else {
                formData.append(`job[${key}]`, 'active')
            }
        }
        const url = `/admin/exdend_job/${job.id}`
        await makeRequest(url, 'put', formData, {
            createSuccessMessage: () => 'Job exdend successfully ',
            onSuccess: () => {
                window.location.href = '/admin/jobs'
            },
        })
    }


    const handleEditorChange = (content) => {
        setDescriptionAdd(content.editor.getData())
        setErrorDescription('')
    }

    const handleNotesChange = (content) => {
        setAddNotes(content.editor.getData())
    }

    const handleMailMessageChange = (content) => {
        setMailMessage(content.editor.getData())
    }

    const primaryOwnerEmail = (userId) => {
        if (job_owner != null) {
            return organization_recruiters.find(recruiter => recruiter.user_id == userId).email || ''
        }
        return recruitersList.find(recruiter => recruiter.user_id == userId).email || ''
    }

    const handleNotificationEmail = (e) => {
        const { value } = e.target
        setPrimaryOwnerEmail(value)
        if (value) {
            setErrorNotificationEmail('')
        }
    }

    // const change_stage = () => {
    //     const url = '/admin/change_stage'
    //     const candidate_ids = find_person_id.filter(Boolean).map( person_id => (person_id.person_id) )
    //     console.log("🚀 ~ file: JobsCreate.js:278 ~ JobsCreate ~ candidate_ids", candidate_ids)
    //     const payload = ({
    //         job_id: job.id,
    //         candidate_ids,
    //         status,
    //         submission_type: 'onhold',
    //         organization_id: job?.organization_id,
    //         source: 'ats',
    //     })
    //     makeRequest(url, 'post', payload, {
    //         loadingMessage: 'Submitting...',
    //         createSuccessMessage: (response) =>
    //             `Candidate${
    //                 candidate_ids.length > 1 ? 's' : ''
    //             } successfully moved`,
    //     })
    // }

    const handleKeyPress = async (value, callback) => {
        const url = `/filter_candidate_on_location`;
        const formData = new FormData();
        formData.append('filter_word', value);
        setLoading(true);
        const response = await axios
            .post(url, formData)
            .then((res) => res)
            .catch((error) => console.log(error));
        const locationPreferrenceArray = response.data.filter.map(
            ({ id, state, city, country }) => {
                if (country === 'usa') {
                    return { value: id, label: `${city}, ${state} (US)` };
                } else {
                    return { value: id, label: `${city}, ${state}` };
                }
            }
        );
        callback(locationPreferrenceArray);
        setLoading(false);
    };

    return (
        <JobStoreContext.Provider value={{ state, dispatch }}>
            <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
                <H1>{method == 'post' ? 'Create Job' : 'Update Job'}</H1>
                <Formik
                    initialValues={{
                        name: job?.name == "null" ? '' : job?.name,
                        location: job.location == "null" ? '' : job.location,
                        description: descriptionAdd == "null" ? '' : descriptionAdd,
                        skills: job?.skills == "null" ? '' : job?.skills,
                        nice_to_have_skills: job?.nice_to_have_skills == "null" ? '' : job?.nice_to_have_skills,
                        notificationemails: notificationemail == "null" ? '' : notificationemail,
                        referral_amount: job?.referral_amount == "null" ? '' : job?.referral_amount,
                        keywords: job?.keywords == "null" ? '' : job?.keywords,
                        nice_to_have_keywords: job?.nice_to_have_keywords == "null" ? '' : job?.nice_to_have_keywords,
                        department: job?.department == "null" ? '' : job?.department,
                        experience_years: job?.experience_years == "null" ? '' : job?.experience_years,
                        prefered_titles: job?.prefered_titles == "null" ? '' : job?.prefered_titles,
                        school_names: job?.school_names == "null" ? '' : job?.school_names,
                        company_names: job?.company_names == "null" ? '' : job?.company_names,
                        location_preference: job?.location_preference == "null" ? '' : job?.location_preference,
                        status: job?.id ? job.status : 'active',
                        create_lead: job?.id ? job.create_lead : true,
                        campaign_status: job?.campaign_status ? job.campaign_status : false,
                        hiring_manager_email: job?.hiring_manager_email || '',
                        linkedin_url: job?.linkedin_url == "null" ? '' : job?.linkedin_url,
                        linkedin_url_2: job?.linkedin_url_2 == "null" ? '' : job?.linkedin_url_2,
                        linkedin_url_3: job?.linkedin_url_3 == "null" ? '' : job?.linkedin_url_3,
                        sourcer_notes: job?.sourcer_notes == "null" ? '' : job?.sourcer_notes,
                        compensation: job?.compensation == "null" ? '' : job.compensation,
                        work_authorization: job?.work_authorization == "null" ? '' : job.work_authorization,
                        employment_type: job?.employment_type == "null" ? '' : job.employment_type,
                        boolean_keyword_search: job?.boolean_keyword_search == "null" ? '' : job.boolean_keyword_search,
                        industry: job.industry == "null" ? '' : job.industry,
                        title_avoid: job.title_avoid == "null" ? '' : job.title_avoid,
                        companies_avoid: job.companies_avoid == "null" ? '' : job.companies_avoid,
                        target_location_avoid: job.target_location_avoid == "null" ? '' : job.target_location_avoid,
                        job_description_link: job.job_description_link == "null" ? '' : job.job_description_link,
                        ideal_linked_in_url: job?.ideal_linked_in_url == "null" ? '' : job?.ideal_linked_in_url,
                        manual_sourcing: job?.manual_sourcing?.toString() == "null" ? '' : job?.manual_sourcing?.toString(),
                        job_view_type: job?.id ? job.job_view_type : 1,
                        community_id: job?.community_id ? job.community_id : '',
                        is_automation: job?.is_automation ? job.is_automation : 0,
                        is_original_job: job?.is_original_job ? job.is_original_job : 0,
                        automation_limit: job?.automation_limit ? job.automation_limit : 0,
                        job_salary: job?.job_salary == "null" ? '' : job?.job_salary,
                        add_notes: addNotes == "null" ? '' : addNotes,
                        in_mail_message: mailMessage == "null" ? '' : mailMessage,
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().test('Title is required', function (value) {
                            if (value) {
                                if (value.trim()?.length == 0) {
                                    console.log('hereeeeeeeeeeee')
                                    return false
                                }
                                return true
                            }
                            return true
                        })
                            .required('Position Title is required').nullable(),
                        skills: Yup.string().trim()
                            .required('Must have skills is required').nullable(),
                        job_salary: Yup.string().trim()
                            .required('Job Salary is required').nullable(),    
                        hiring_manager_email: Yup.string()
                            .email('Please enter valid email id'),
                        linkedin_url: Yup.string().test('Invalid linkedin URL', 'Invalid linkedin URL', function (value) {
                            if (value) {
                                const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                                if (!(regex.test(value) && value.includes("linkedin"))) {
                                    return false
                                }
                                return true
                            }
                            return true
                        }).notRequired().nullable(true),
                        linkedin_url_2: Yup.string().test('Invalid linkedin URL', 'Invalid linkedin URL', function (value) {
                            if (value) {
                                const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                                if (!(regex.test(value) && value.includes("linkedin"))) {
                                    return false
                                }
                                return true
                            }
                            return true
                        }).notRequired().nullable(true),
                        linkedin_url_3: Yup.string().test('Invalid linkedin URL', 'Invalid linkedin URL', function (value) {
                            if (value) {
                                const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                                if (!(regex.test(value) && value.includes("linkedin"))) {
                                    return false
                                }
                                return true
                            }
                            return true
                        }).notRequired().nullable(true),
                        ideal_linked_in_url: Yup.string().test('Invalid Ideal Candidate LinkedIn Profile', 'Invalid Ideal Candidate LinkedIn Profile', function (value) {
                            if (value) {
                                const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                                if (!(regex.test(value) && value.includes("linkedin"))) {
                                    return false
                                }
                                return true
                            }
                            return true
                        }).notRequired().nullable(true),
                        job_description_link: Yup.string().test('Invalid Job Description Link', 'Invalid Job Description Link', function (value) {
                            if (value) {
                                const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                                if (!(regex.test(value))) {
                                    return false
                                }
                                return true
                            }
                            return true
                        }).notRequired().nullable(true),
                        // prefered_titles: Yup.string().trim().required('Current Titles is required').nullable(),
                        // location_preference: Yup.string().trim().required('Target Locations is required').nullable(),
                        // experience_years: Yup.string().trim().required('Experience is required').nullable(),
                        // boolean_keyword_search: Yup.string().trim().required('Boolean / Keyword Search is required').nullable(),
                        // keywords: Yup.string().trim().required('Must have keyword is required').nullable(),
                    })}
                    onSubmit={(values) => {
                        setErrorDepart('')
                        setErrorDescription('')
                        setErrorOrganization('')
                        setErrorJobOwner('')
                        setErrorDepart('')
                        setErrorHiringEmail('')
                        setErrorJobGroup('')
                        setErrorNotificationEmail('')
                        setAutomationLimitError('')
                        let source_at = false
                        if (isEmpty(descriptionAdd)) {
                            setErrorDescription('Job Description is required')
                            return false;
                        }
                        if (selectedOrganization[0].value == '' || selectedOrganization[0].value == null | selectedOrganization[0].value == 'null' || typeof selectedOrganization[0].value == 'undefined') {
                            setErrorOrganization('Organization is required')
                            return false
                        }
                        if (selectedOwner == '' || selectedOwner == null || selectedOwner == 'null' || typeof selectedOwner == 'undefined') {
                            setErrorJobOwner('Primary owner is required')
                            return false
                        }
                        if ( isEmpty(values.department) || values.department == '') {
                            setErrorDepart('Job Department is required')
                            return false;
                        }
                        // if (organizationType === 'contingency' && values.hiring_manager_email == '') {
                        //     setErrorHiringEmail('Hiring manager email is required')
                        //     return false;
                        // }
                        // if (selectedGroup == '' || selectedGroup == null || selectedGroup == 'null' || typeof selectedGroup == 'undefined') {
                        //     setErrorJobGroup('Job group is required')
                        //     return false
                        // }
                        if(selectedTechGroup?.length > 5){
                            
                            setErrorJobTechGroup('Max 5 Job Tech Group are allowed')
                            return false
                        }
                        if(selectedIndustry?.length == 0){
                            
                            setErrorJobIndustry('Industry is required')
                            return false
                        }
                        if (notificationemail) {
                            if (!notificationemail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                setErrorNotificationEmail('Email is invalid')
                                return false
                            }
                        }
                        if (isEmpty(notificationemail)) {
                            setErrorNotificationEmail('Email is required')
                            return false
                        }
                        if(values.is_automation && (values.is_automation == 1 || values.is_automation == '1')){
                            if(values.automation_limit <= 0){
                                setAutomationLimitError('Automation limit must be grater than zero')
                                return false
                            }
                        }
                        if( !isEmpty((values?.nice_to_have_keywords)?.trim())
                            || !isEmpty((values?.boolean_keyword_search)?.trim())
                            || !isEmpty((values?.keywords)?.trim())
                            || !isEmpty((values?.prefered_titles)?.trim())
                            || !isEmpty((values?.experience_years)?.trim())
                            || !isEmpty((values?.school_names)?.trim())
                            || !isEmpty((values?.company_names)?.trim())
                            || !isEmpty((values?.location_preference)?.trim())
                            || !isEmpty((values?.sourcer_notes)?.trim())
                            || !isEmpty((values?.employment_type)?.trim())
                            || !isEmpty((values?.work_authorization)?.trim())
                            || !isEmpty((values?.compensation)?.trim())
                            || !isEmpty((values?.title_avoid)?.trim())
                            || !isEmpty((values?.companies_avoid)?.trim())
                            || !isEmpty((values?.target_location_avoid)?.trim())
                            || !isEmpty((values?.ideal_linked_in_url)?.trim())
                            || !isEmpty((values?.job_description_link)?.trim())
                            || values?.manual_sourcing == "true"){
                                source_at = true
                        }
                        saveJob({
                            organization_id: selectedOrganization[0].value,
                            name: (values?.name) ?  (values.name).trim() : '',
                            location: selectedLocation,
                            description: ((descriptionAdd) ? descriptionAdd.trim() : ''),
                            skills: ((values?.skills) ?  (values.skills).trim() : ''),
                            nice_to_have_skills: (!values?.nice_to_have_skills || values?.nice_to_have_skills == "null" || values?.nice_to_have_skills == "") ? (values?.skills).trim() : (values?.nice_to_have_skills).trim(),
                            notificationemails: ((notificationemail) ? notificationemail.trim() : ''),
                            referral_amount: ((values?.referral_amount) ? (values.referral_amount).trim() : ''),
                            keywords: ((values?.keywords) ? (values.keywords).trim() : ''),
                            nice_to_have_keywords: ((values?.nice_to_have_keywords) ?  (values.nice_to_have_keywords).trim() : ''),
                            department: ((values?.department) ? (values.department).trim() : ''),
                            experience_years: ((values?.experience_years) ? (values.experience_years).trim() : ''),
                            prefered_titles: ((values?.prefered_titles) ?  (values.prefered_titles).trim() : ''),
                            school_names: ((values?.school_names) ? (values.school_names).trim() : ''),
                            company_names: ((values?.company_names) ? (values.company_names).trim() : ''),
                            // location_names: locationIds,
                            location_preference: ((values?.location_preference) ? (values.location_preference).trim() : ''),
                            status: values.status,
                            create_lead:  method === 'put' ? values?.create_lead : true,
                            campaign_status: method === 'put' ? values?.campaign_status : false,
                            hiring_manager_email: ((values?.hiring_manager_email) ?  (values.hiring_manager_email).trim() : ''),
                            creator_id: selectedOwner,
                            linkedin_url: values?.linkedin_url == "null" ? '' : ((values?.linkedin_url) ?  (values.linkedin_url).trim() : ''),
                            linkedin_url_2:  values?.linkedin_url_2 == "null" ? '' : ((values?.linkedin_url_2) ?  (values.linkedin_url_2).trim() : ''),
                            linkedin_url_3:  values?.linkedin_url_3 == "null" ? '' : ((values?.linkedin_url_3) ?  (values.linkedin_url_3).trim() : ''),
                            sourcer_notes: ((values?.sourcer_notes) ? (values.sourcer_notes).trim() : ''),
                            compensation: ((values?.compensation) ? (values.compensation).trim() : ''),
                            work_authorization: ((values?.work_authorization) ? (values.work_authorization).trim() : ''),
                            employment_type: ((values?.employment_type) ? (values.employment_type).trim() : ''),
                            group_id: selectedGroup,
                            boolean_keyword_search: (values.boolean_keyword_search == "null" || isEmpty(values.boolean_keyword_search)) ? '' : ((values?.boolean_keyword_search) ? (values.boolean_keyword_search).trim() : ''),
                            source_at: source_at,
                            industry: selectedIndustry?.map((ind)=> ind.value).join(','),
                            title_avoid: ((values?.title_avoid) ? (values.title_avoid).trim() : ''),
                            companies_avoid: ((values?.companies_avoid) ? (values.companies_avoid).trim() : ''),
                            target_location_avoid: ((values?.target_location_avoid) ? (values.target_location_avoid).trim() : ''),
                            job_description_link: ((values?.job_description_link) ? (values?.job_description_link).trim() : ''),
                            portalcompanyname: ((selectedOrganization[0]?.label) ? (selectedOrganization[0].label).trim() : ''),
                            ideal_linked_in_url: ((values?.ideal_linked_in_url) ? (values.ideal_linked_in_url).trim() : ''),
                            linkedin_job_url: linkedinURL,
                            manual_sourcing: (values.manual_sourcing == "null" || isEmpty(values.manual_sourcing)) ? '' : values.manual_sourcing,
                            tech_group: selectedTechGroup?.map((techTalent)=> techTalent.value).join(','),
                            job_view_type: values.job_view_type,
                            community_id: values.community_id,
                            is_original_job: values.is_original_job,
                            is_automation: values.is_automation,
                            automation_limit: values.automation_limit,
                            job_salary: values.job_salary,
                            add_notes: ((addNotes) ? addNotes.trim() : ''),
                            in_mail_message: ((mailMessage) ? mailMessage.trim() : '')
                            // company_preference: (values.company_names == 'Specify') ? '' : values.company_names,
                            // education_preference: (values.school_names == 'Specify') ? '' : values.school_names
                        })
                        validOrganizationRecruiter(selectedOrganization[0].value)
                        // change_stage()
                    }}
                >
                    <StyledForm>
                        <Row>
                            <P>Job Information</P>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            {'Select Organization*'}
                                        </Label>
                                        <AsyncSelect
                                            isLoading={isLoading}
                                            isClearable={false}
                                            isDisabled={(method == 'put') ? true : false}
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            defaultValue={selectedOrganization[0]}
                                            onInputChange={handleInputChange}
                                            onChange={handleSelectOption}
                                            styles={colourStyles}
                                            placeholder='search for organization'
                                            noOptionsMessage={() => 'start typing the name of organization'}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorOrganization && errorOrganization}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            Primary Owner*
                                        </Label>
                                        <ReactSelect
                                            onChange={ownerChange}
                                            options={ownerOptions}
                                            styles={colourStyles}
                                            defaultValue={defaultOwner}
                                            formatOptionLabel={function(data){
                                                return (<div style={{display: 'flex', flexDirection: 'column'}} dangerouslySetInnerHTML={{__html: `<span>${data.label}</span><small>${data.email}</small>`}}></div>)
                                            }}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobOwner && errorJobOwner}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="Position Title*"
                                            name="name"
                                            type="text"
                                            id="name"
                                            width="100%"
                                            placeholder='Job title'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            {'Location'}
                                        </Label>
                                        <AsyncCreatableSelect
                                            isLoading={isLoading}
                                            isClearable={false}
                                            cacheOptions
                                            loadOptions={handleKeyPress}
                                            defaultValue={defaultLocation}
                                            onChange={locationChange}
                                            styles={colourStyles}
                                            placeholder="Search for location"
                                            noOptionsMessage={() => 'start typing the name of location'}
                                        />
                                    </Col>
                                </Row>
                                <Label>
                                    {'Job Description*'}
                                </Label>
                                {/* <Editor
                        value={descriptionAdd}
                        init={{
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                        }}
                        onEditorChange={handleEditorChange}
                    /> */}
                                <CKEditor
                                    initData={descriptionAdd}
                                    config={{
                                        toolbar: [
                                            ['Cut', 'Copy', 'Paste'],
                                            ['Styles', 'Format', 'Font', 'FontSize'],
                                            ['Bold', 'Italic', 'Strike'],
                                            ['Undo', 'Redo'],
                                            ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                                            ['Link', 'Unlink'],
                                            ['Table', 'HorizontalRule'],
                                            ['Maximize']
                                        ]
                                    }
                                    }
                                    onChange={handleEditorChange}
                                />
                                <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                    {errorDescription && errorDescription}
                                </Label>
                                <br />

                            </Col>
                            <Col xs={12} sm={12} lg={12} className='mb-4'>
                                <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Must have skills (Comma separated values e.g. PHP,MySql)*"
                                            name="skills"
                                            type="textarea"
                                            id="skills"
                                            width="100%"
                                            rows="7"
                                            maxLength="500"
                                            placeholder='Must have skills'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                    <Label>
                                        {'Auto Email Template: {PERSON_NAME} {ROLE_NAME} {COMPANY_NAME}'}
                                    </Label>
                                        <CKEditor
                                            initData={mailMessage}
                                            config={{
                                                toolbar: [
                                                    ['Cut', 'Copy', 'Paste'],
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Bold', 'Italic', 'Strike'],
                                                    ['Undo', 'Redo'],
                                                    ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                                                    ['Link', 'Unlink'],
                                                    ['Table', 'HorizontalRule'],
                                                    ['Maximize']
                                                ]
                                            }
                                            }
                                            onChange={handleMailMessageChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Nice to have skills (Comma separated values e.g. PHP,MySql)*"
                                            name="nice_to_have_skills"
                                            type="textarea"
                                            id="nice_to_have_skills"
                                            width="100%"
                                            rows="7"
                                            maxLength="500"
                                            placeholder='Nice to have skills'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                    <Label>
                                        {'Matching Candidate Note'}
                                    </Label>
                                        <CKEditor
                                            initData={addNotes}
                                            config={{
                                                toolbar: [
                                                    ['Cut', 'Copy', 'Paste'],
                                                    ['Styles', 'Format', 'Font', 'FontSize'],
                                                    ['Bold', 'Italic', 'Strike'],
                                                    ['Undo', 'Redo'],
                                                    ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                                                    ['Link', 'Unlink'],
                                                    ['Table', 'HorizontalRule'],
                                                    ['Maximize']
                                                ]
                                            }
                                            }
                                            onChange={handleNotesChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} lg={3} className='d-flex align-items-center'>
                                <div class="custom-control custom-switch mb-2">
                                    <input type="checkbox" class="custom-control-input" checked={linkedinURL} onClick={(e)=>{
                                        setLinkedinURL(e.target.checked)
                                    }} id="linkedin_url"/>
                                    <label class="custom-control-label" for="linkedin_url">Linkedin Job Post URL</label>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} lg={3}>
                                <TextInput
                                    label="Job View Type*"
                                    name="job_view_type"
                                    type="text"
                                    id="job_view_type"
                                    width="100%"
                                    as="select"
                                >
                                    <option value="1">Public</option>
                                    <option value="2">Private</option>
                                </TextInput>
                            </Col>
                            { (linkedinURL || (job?.linkedin_url && job?.linkedin_url != '') )&& (<Col xs={12} sm={12} lg={6}>
                                <TextInput
                                    label="Linkedin URL"
                                    name="linkedin_url"
                                    type="text"
                                    id="linkedin_url"
                                    width="100%"
                                    placeholder='Linkedin URL'
                                />
                            </Col>)}
                            { (linkedinURL || (job?.linkedin_url && job?.linkedin_url != '') )&& (
                                <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Linkedin URL 2"
                                            name="linkedin_url_2"
                                            type="text"
                                            id="linkedin_url_2"
                                            width="100%"
                                            placeholder='Linkedin URL 2'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Linkedin URL 3"
                                            name="linkedin_url_3"
                                            type="text"
                                            id="linkedin_url_3"
                                            width="100%"
                                            placeholder='Linkedin URL 3'
                                        />
                                    </Col>
                                </Row>
                                </Col>
                              )}
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            Job Group
                                        </Label>
                                        <ReactSelect
                                            onChange={groupChange}
                                            options={jobGroup}
                                            styles={colourStyles}
                                            defaultValue={defaultGroup}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobGroup && errorJobGroup}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="Where would you like to receive your applicants?*"
                                            name="notificationemails"
                                            value={notificationemail}
                                            type="email"
                                            id="notificationemails"
                                            width="100%"
                                            placeholder='Email address'
                                            onChange={(e) => handleNotificationEmail(e)}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red' }}>
                                            {errorNotificationEmail && errorNotificationEmail}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Select
                                            label="Job Department*"
                                            name="department"
                                            id="department"
                                            width="100%"
                                            type="text"
                                        >
                                            <option value="">Select</option>
                                            <option value="Accounting">Accounting</option>
                                            <option value="Finance">Finance</option>
                                            <option value="Sales">Sales</option>
                                            <option value="Research and Development">Research and Development </option>
                                            <option value="IT">IT</option>
                                            <option value="Management">Management</option>
                                            <option value="Administration">Administration</option>
                                            <option value="Customer support">Customer support</option>
                                            <option value="Technical support">Technical support</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Logistics">Logistics</option>
                                            <option value="Operations">Operations</option>
                                            <option value="Planning">Planning</option>
                                            <option value="Human resources">Human resources</option>
                                            <option value="Purchasing">Purchasing</option>
                                            <option value="Quality assurance">Quality assurance</option>
                                            <option value="Engineering">Engineering</option>
                                            <option value="Public relations">Public relations</option>
                                        </Select>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="what is the commision for hire of a referred candidate?"
                                            name="referral_amount"
                                            type="text"
                                            id="money"
                                            width="100%"
                                            placeholder="$"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} lg={3}>
                                {job && (
                                    <TextInput
                                        label="Status*"
                                        name="status"
                                        type="text"
                                        id="status"
                                        width="100%"
                                        as="select"
                                    >
                                        <option value="active">Active</option>
                                        <option value="pending">Pending</option>
                                        <option value="onhold">On Hold</option>
                                        <option value="expired">Expired</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="closed">Closed</option>
                                    </TextInput>
                                )}
                            </Col>
                            { method === 'put'  && (
                                <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="Create Lead"
                                            name="create_lead"
                                            type="text"
                                            id="create_lead"
                                            width="100%"
                                            as="select"
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </TextInput>
                                </Col>
                             )}
                             { method === 'put'  && (
                                <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="Campaigning"
                                            name="campaign_status"
                                            type="text"
                                            id="campaign_status"
                                            width="100%"
                                            as="select"
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </TextInput>
                                </Col>
                             )}
                            <Col xs={12} sm={12} lg={3}>
                                <TextInput
                                    label="Hiring Manager Email"
                                    name="hiring_manager_email"
                                    type="text"
                                    id="hiring_manager_email"
                                    width="100%"
                                    placeholder='Hiring Manager Email'
                                />
                                <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                    {errorHiringEmail && errorHiringEmail}
                                </Label>
                                <br />
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} lg={3}>
                                <TextInput
                                    label="Community Refer Id"
                                    name="community_id"
                                    type="text"
                                    id="community_id"
                                    width="100%"
                                    placeholder="Enter Id"
                                />
                            </Col>

                            <Col xs={12} sm={12} lg={3}>
                                <TextInput
                                    label="Job Salary*"
                                    name="job_salary"
                                    type="text"
                                    id="job_salary"
                                    width="100%"
                                    placeholder="Enter Salary"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <P>Sourcing Requirements</P>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12} sm={12} lg={6}>
                                <TextInput
                                    label="Must have keyword"
                                    name="keywords"
                                    type="textarea"
                                    id="keywords"
                                    width="100%"
                                    rows="4"
                                    maxLength="500"
                                    placeholder='Must have keyword'
                                />
                                <TextInput
                                    label="Nice to have keywords"
                                    name="nice_to_have_keywords"
                                    type="textarea"
                                    id="nice_to_have_keywords"
                                    width="100%"
                                    rows="4"
                                    maxLength="500"
                                    placeholder='Nice to have keywords'
                                />
                            </Col>
                            <Col xs={12} sm={12} lg={6}>
                                <TextInput
                                    label="Current Titles"
                                    name="prefered_titles"
                                    type="textarea"
                                    id="prefered_titles"
                                    width="100%"
                                    rows="4"
                                    maxLength="500"
                                    placeholder='Current Titles'
                                />
                                <TextInput
                                    label="Specific Notes for Sourcing"
                                    name="sourcer_notes"
                                    type="textarea"
                                    id="sourcer_notes"
                                    width="100%"
                                    rows="4"
                                    maxLength="1200"
                                    placeholder='Specific Notes for Sourcing'
                                />
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Target Companies"
                                            type="textarea"
                                            name="company_names"
                                            id="company_names"
                                            width="100%"
                                            rows="4"
                                            placeholder='Target Companies'
                                        />
                                    </Col>
                                    <Col>
                                        <TextInput
                                            label="Target Locations (Separated by comma)"
                                            type="textarea"
                                            name="location_preference"
                                            id="location_preference"
                                            width="100%"
                                            rows="4"
                                            placeholder='Target Locations'
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Education Preferences"
                                            name="school_names"
                                            id="school_names"
                                            width="100%"
                                            placeholder='Education Preferences'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <TextInput
                                            label="Job Description Link"
                                            type='test'
                                            className="form-control"
                                            name="job_description_link"
                                            id="job_description_link"
                                            width="100%"
                                            placeholder='Job Description Link'
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={4}>
                                        <TextInput
                                            label="Experience"
                                            name="experience_years"
                                            id="experience_years"
                                            width="100%"
                                            placeholder='Experience'
                                        />
                                    </Col>
                                    {/* <Col xs={12} sm={12} lg={4}>
                                        <TextInput
                                            label="Compensation"
                                            name="compensation"
                                            id="compensation"
                                            width="100%"
                                            placeholder='Compensation'
                                        />
                                    </Col> */}
                                    <Col xs={12} sm={12} lg={4}>
                                        <TextInput
                                            label="Work Authorization"
                                            name="work_authorization"
                                            id="work_authorization"
                                            width="100%"
                                            as="select"
                                        >
                                            <option value="">Select</option>
                                            <option value="US Citizen">US Citizen</option>
                                            <option value="Permanent Resident">Permanent Resident</option>
                                            <option value="H-1B">H-1B</option>
                                            <option value="OPT">OPT</option>
                                            <option value="Not Specified">Not Specified</option>
                                        </TextInput>
                                    </Col>
                                    <Col xs={12} sm={12} lg={4}>
                                        <TextInput
                                            label="Employment Type"
                                            name="employment_type"
                                            id="employment_type"
                                            width="100%"
                                            placeholder='Employment Type'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={4}>
                                        <TextInput
                                            label="Boolean / Keyword Search"
                                            name="boolean_keyword_search"
                                            id="boolean_keyword_search"
                                            width="100%"
                                            placeholder='Boolean / Keyword Search'
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Titles to Avoid"
                                            type='text'
                                            className="form-control"
                                            name="title_avoid"
                                            id="title_avoid"
                                            width="100%"
                                            placeholder='Titles to Avoid'
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Target Companies to Avoid"
                                            type='text'
                                            className="form-control"
                                            name="companies_avoid"
                                            id="companies_avoid"
                                            width="100%"
                                            placeholder='Target Companies to Avoid'
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Target Locations to Avoid"
                                            type='text'
                                            className="form-control"
                                            name="target_location_avoid"
                                            id="target_location_avoid"
                                            width="100%"
                                            placeholder='Target Locations to Avoid'
                                        />
                                    </Col>
                                    <Col xs={4}>
                                         <Label style={{ fontSize: '14px',marginTop: '5px' }}>
                                            Industry*
                                        </Label>
                                        <ReactSelect
                                            options={industries}
                                            name='industry'
                                            id='industry'
                                            placeholder={'Select Industry'}
                                            onChange={industryChange}
                                            value={selectedIndustry}
                                            isMulti
                                            menuPosition='fixed'
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobIndustry && errorJobIndustry}
                                        </Label>
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Ideal Candidate LinkedIn Profile"
                                            type='text'
                                            className="form-control"
                                            name="ideal_linked_in_url"
                                            id="ideal_linked_in_url"
                                            width="100%"
                                            placeholder='Ideal Candidate LinkedIn Profile'
                                        />
                                    </Col>
                                    <Col xs={4}>
                                    <TextInput
                                        label="Manual Sourcing"
                                        name="manual_sourcing"
                                        type="text"
                                        id="manual_sourcing"
                                        width="100%"
                                        as="select"
                                    >
                                        <option value="">Select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </TextInput>
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Is Original Job"
                                            name="is_original_job"
                                            type="text"
                                            id="is_original_job"
                                            width="100%"
                                            as="select"
                                        >   
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </TextInput>
                                    </Col>
                                    <Col xs={12} sm={12} lg={4}>
                                        <Label style={{ fontSize: '14px',marginTop: '5px' }}>
                                            Tech Group
                                        </Label>
                                        
                                        <ReactSelect
                                            options={techTalent}
                                            name='techTalents'
                                            id='techTalents'
                                            placeholder={'Select Tech Group (Max 5)'}
                                            onChange={techGroupChange}
                                            value={selectedTechGroup}
                                            isMulti
                                            menuPosition='fixed'
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobTechGroup && errorJobTechGroup}
                                        </Label>
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Is Automation Job"
                                            name="is_automation"
                                            type="text"
                                            id="is_automation"
                                            width="100%"
                                            as="select"
                                        >   
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </TextInput>
                                    </Col>
                                    <Col xs={4}>
                                        <TextInput
                                            label="Automation Limit Per Day (%)"
                                            name="automation_limit"
                                            type="number"
                                            id="automation_limit"
                                            width="100%"
                                        />   
                                        <Label style={{ fontSize: '10px', color: 'red' }}>
                                            {automationLimitError && automationLimitError}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ marginTop: '18px' }}>
                                    <Button type="submit">Save</Button>
                                    <Button
                                        type="button"
                                        onClick={() => window.history.back()}
                                        className="ml-sm-3"
                                    >
                                        Go Back
                                    </Button>
                                    {((job && (expiryDate >= beforeExpiryDate && beforeExpiryDate <= currentDate)) || (job && (job_status === 'expired'))) && (
                                        <Button
                                            type="button"
                                            onClick={exdendExpiredJob}
                                            className="ml-sm-3"
                                        >
                                            Extend Expired Job
                                        </Button>)
                                    }

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ marginTop: '18px' }}>
                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorOrganization && errorOrganization}
                                    </Label>

                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorHiringEmail && errorHiringEmail}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobGroup && errorJobGroup}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorJobOwner && errorJobOwner}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorDescription && errorDescription}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red' }}>
                                        {errorNotificationEmail && errorNotificationEmail}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red' }}>
                                        {errorDepart && errorDepart}
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </StyledForm>
                </Formik>
            </div>
        </JobStoreContext.Provider>
    )
}
export default JobsCreate