import React, { useEffect, useState,Fragment} from 'react'
import {     } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Util from '../../../utils/util'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import './styles/NetworkCoversation.scss'
import lodingImage from '../../../../assets/v2/imgs/theme/loading_new.gif'
import Moment from 'moment'

function NetworkCandidateList({candidates,viewCandidateMessages,activeCandidate,setSearchName}) { 
    return (
        <>
            <div className="sidebar-shadow sidebar-news-small">
                <h5 className="sidebar-title">Messaging</h5>
                <div className="widget_search mb-20">
                    <div className="search-form">
                        <input type="text" placeholder="Search name" onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearchName(e.target.value);
                            }
                        }} />
                    </div>
                </div>
                <div className="post-list-small chat-list">
                {candidates && candidates.map((candidate, index) => {
                    return (
                        <div key={candidate.id} onClick={(e) => {
                                viewCandidateMessages(candidate.id,0)
                            }} className={`${candidate.id == activeCandidate ? 'chat-active': ''} post-list-small-item d-flex align-items-center mb-0 p-10 `}>
                            <figure className="thumb mr-15">
                                {candidate?.avatar_url ?
                                    <img alt="jobhub" className={'img_border'} src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                    : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                }
                            </figure>
                            <div className="content w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0">{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</h5>
                                    <p>{Moment(candidate?.updated_at).fromNow()}</p>
                                </div>
                                <p className="mb-0 text-muted font-xs">
                                    <span className="mr-10">{candidate?.description || 'N/A'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                )}
                </div>
            </div>
        </>
    )
}

export default NetworkCandidateList
