import React, { Fragment, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
    W3text,
    Row,
    Typography
} from './styles/AccountSettingPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { passwordSchema } from '../../common/ValidationSchema/PasswordSchema'
import '../../../../assets/v2/css/main.css'
import './styles/account_setting.css'

const passwordValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    from_email: Yup.string().required('Email is required'),
    host: Yup.string().required('Host is required'),
    port: Yup.string().required('Port is required'),
    user_email: Yup.string().required('Username/Email is required'),
    password: Yup.string().required('Password is required'),
})

const EmailConfigurationPageV2 = (props) => {
    const [error, setError] = useState(false)
    const { currentUser, email_data } = props
    

    const handleReset = async (data) => {
        const { name, from_email, host, port, user_email,password  } = data
        const payload = new FormData()
        const url = '/account/add_email_configuration'
        payload.append('email_configuration[name]', name)
        payload.append('email_configuration[from_email]', from_email)
        payload.append('email_configuration[host]', host)
        payload.append('email_configuration[port]', port)
        payload.append('email_configuration[user_email]', user_email)
        payload.append('email_configuration[password]', password)
        payload.append('email_configuration[user_id]', currentUser.id)

        await makeRequest(url, 'post', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                    window.location.reload()
                    setError(false)
            },
        })
    }

    const handleUpdate = async (data) => {
        const { name, from_email, host, port, user_email,password  } = data
        const payload = new FormData()
        const url = '/account/update_email_configuration'
        payload.append('email_configuration[name]', name)
        payload.append('email_configuration[id]', email_data?.id)
        payload.append('email_configuration[from_email]', from_email)
        payload.append('email_configuration[host]', host)
        payload.append('email_configuration[port]', port)
        payload.append('email_configuration[user_email]', user_email)
        payload.append('email_configuration[password]', password)
        payload.append('email_configuration[user_id]', currentUser.id)

        await makeRequest(url, 'put', payload, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            onSuccess: (res) => {
                    window.location.reload()
                    setError(false)
            },
        })
    }

    return (
        <section className="section-box" style={{ overflow: 'inherit !important' }}>
            <div className="container bootstrap snippets bootdeys">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="panel panel-default" style={{ marginTop: '60px'}}>
                            <div className="panel-heading" style={{ backgroundColor: '#622fff85' }}>
                                <h4 className="panel-title" style={{ color: 'white' }}>Email Configuration</h4>
                            </div>
                            <div className="panel-body">
                                
                                <Formik
                                    initialValues={{
                                        name: email_data?.name || '',
                                        from_email: email_data?.from_email || '',
                                        host: email_data?.host || '',
                                        port: email_data?.port || '',
                                        user_email: email_data?.user_email || '',
                                        password: email_data?.password || '',

                                    }}
                                    validationSchema={passwordValidationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            setSubmitting(false)
                                        }, 500)
                                        if(email_data){
                                            handleUpdate(values)
                                        }else{
                                        handleReset(values)
                                        }
                                    }}
                                >
                                    {(props) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            isSubmitting,
                                            handleChange,
                                            handleSubmit,
                                        } = props
                                        return (
                                            <Fragment>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">From Name</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" name="name" className="form-control" placeholder="From Name"
                                                            value={values.name} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto' }}
                                                            error={
                                                                Boolean(
                                                                    errors.name
                                                                ) &&
                                                                touched.name
                                                            }
                                                        />
                                                        {errors.name &&
                                                            touched.name && (
                                                                <Typography>
                                                                    {
                                                                        errors.name
                                                                    }
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">From Email</label>
                                                    <div className="col-sm-6">
                                                        <input type="email" name="from_email" className="form-control" placeholder="Enter From Email"
                                                            value={values.from_email} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto', width: '97%' }}
                                                            error={
                                                                Boolean(
                                                                    errors.from_email
                                                                ) &&
                                                                touched.from_email
                                                            }
                                                        />
                                                        {errors.from_email &&
                                                            touched.from_email && (
                                                                <Typography>
                                                                    {
                                                                        errors.from_email
                                                                    }
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Email Host</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" name="host" className="form-control" placeholder="Enter Email Host"
                                                            value={values.host} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto' }}
                                                            error={
                                                                Boolean(
                                                                    errors.host
                                                                ) &&
                                                                touched.host
                                                            }
                                                        />
                                                        {errors.host &&
                                                            touched.host && (
                                                                <Typography>
                                                                    {
                                                                        errors.host
                                                                    }
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Email Port</label>
                                                    <div className="col-sm-6">
                                                        <input type="number" name="port" className="form-control" placeholder="Enter Email Port"
                                                            value={values.port} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto', width: '97%' }}
                                                            error={
                                                                Boolean(
                                                                    errors.port
                                                                ) &&
                                                                touched.port
                                                            }
                                                        />
                                                        {errors.port &&
                                                            touched.port && (
                                                                <Typography>
                                                                    {
                                                                        errors.port
                                                                    }
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Username/Email</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" name="user_email" className="form-control" placeholder="Enter Username/Email"
                                                            value={values.user_email} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto' }}
                                                            error={
                                                                Boolean(
                                                                    errors.user_email
                                                                ) &&
                                                                touched.user_email
                                                            }
                                                        />
                                                        {errors.user_email &&
                                                            touched.user_email && (
                                                                <Typography>
                                                                    {
                                                                        errors.user_email
                                                                    }
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Password</label>
                                                    <div className="col-sm-6">
                                                        <input type="password" name="password" className="form-control" placeholder="Enter Password"
                                                            value={values.password} 
                                                            onChange={handleChange} 
                                                            style={{ height: 'auto', width: '97%' }}
                                                            error={
                                                                Boolean(
                                                                    errors.password
                                                                ) &&
                                                                touched.password
                                                            }
                                                        />
                                                        {errors.password &&
                                                            touched.password && (
                                                                <Typography>
                                                                    {
                                                                        errors.password
                                                                    }
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-10 col-sm-offset-2">
                                                        <button onClick={handleSubmit} className="btn btn-default" style={{margin: '0px !important'}}>{email_data ? 'Update' : 'Submit'}</button>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
    )
}
export default EmailConfigurationPageV2
