import React, { useEffect, useState, useRef} from 'react'
import { capitalize } from '../../../utils';
import { makeRequest } from '../../common/RequestAssist/RequestAssist';
import Util from '../../../utils/util'
import CandidateAvatarIcon from '../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import './styles/NetworkCoversation.scss'
import lodingImage from '../../../../assets/v2/imgs/theme/loading_new.gif'
import Moment from 'moment'
import { CKEditor } from 'ckeditor4-react';

function NetworkMessages({fetchCandidateDetail,fetchCandidateMessage,setFetchCandidateMessage,community_id}) {
    const [message, setMessage] = useState('');
    const [editorKey, setEditorKey] = useState(1); // Key to force CKEditor re-render,

    const handleSubmit = (event) => {
        event.preventDefault();
        const url = `/send_message.json`
        makeRequest(url, 'post',  {
                receiver_id: fetchCandidateDetail.id,
                message: message,
                network_conversation_id: 0,
                community_id: community_id
            },{
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            createResponseMessage: (response) => {
                return {
                    message: response.message,
                    messageType: response.messageType,
                    loading: false,
                    autoClose: true,
                }
            },
            onSuccess: (response) => {
                setMessage('');
                setEditorKey((prevKey) => prevKey + 1);
                fetchNewMessages(fetchCandidateDetail.id,0)
                // const updatedCandidates = candidates.map(candidate =>
                //     candidate.id === id ? { ...candidate, status: 'pending' } : candidate
                // );
                // setNewData({...fetchCandidateDetail,id: res.data.send_message.id,message: res.data.send_message.message,created_at: res.data.send_message.created_at});
            },
        })
    };

    const handleEditorChange = (content) => {
        setMessage(content.editor.getData())
    }
    
    const fetchNewMessages = (id,coversation_id) => {
        setFetchCandidateMessage([])
        const url = `/fetch_message/${id}/${coversation_id}.json`
        makeRequest(url, 'get',{
            params: {
               community_id: community_id
            }},{
            contentType: 'application/json',
            onSuccess: (res) => {
                setFetchCandidateMessage(
                    res.data.candidates_message.map((candidate) => ({
                        id: candidate.id,
                        first_name: (candidate.user1.person.first_name != null) ? capitalize(candidate.user1.person.first_name): capitalize(candidate.first_name),
                        last_name: (candidate.user1.person.last_name != null) ? capitalize(candidate.user1.person.last_name) : capitalize(candidate.last_name),
                        title: candidate.user1.person.title || candidate.title,
                        location: candidate.user1.person.location || candidate.location,
                        state: candidate.state || '',
                        city: candidate.city || '',
                        avatar_url: candidate.user1.person.avatar_url || '',
                        employer: candidate.user1.person.employer || '',
                        description: candidate.user1.person.description || '',
                        message: candidate.message,
                        created_at: candidate.created_at
                    }))
                )
            },
        })
    }

    useEffect(() => {
        if(fetchCandidateDetail?.id != ''){
            // Set up interval to fetch new messages every minute
            const intervalId = setInterval(() => {
            fetchNewMessages(fetchCandidateDetail.id, 0);
            }, 30000); // 60000 milliseconds = 1 minute
        
            // Clean up the interval when the component is unmounted
            return () => clearInterval(intervalId);
        }
      }, [fetchCandidateDetail?.id]);

    return (
        <>
            <div className="">   
                <div className="author-bio p-20 border-radius-15 bg-white">
                    <div className="author-image pb-15 message-box-heading" >
                        <figure className="thumb">
                            {fetchCandidateDetail?.avatar_url ?
                                <img alt="profile" className={'img_border'} src={(fetchCandidateDetail?.avatar_url != '')?fetchCandidateDetail.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                : <CandidateAvatarIcon firstName={fetchCandidateDetail?.first_name} lastName={fetchCandidateDetail?.last_name} />
                            }
                        </figure>
                        <div className="author-infor ml-10">
                            <h5 className="mb-5">{Util.handleUndefinedFullName(fetchCandidateDetail?.first_name, fetchCandidateDetail?.last_name)}</h5>
                            <p className="mb-0 text-muted font-xs">
                                <span className="mr-10">{fetchCandidateDetail?.description || 'N/A'}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="author-des mt-20 message-box">
                        {fetchCandidateMessage && fetchCandidateMessage.map((candidate, index) => {
                            return (
                                <div key={candidate.id} className="post-list-small-item d-flex align-items-center">
                                    <div className="content">
                                        <div className="post-meta text-muted d-flex align-items-center mb-10">
                                            <div className="author d-flex align-items-center mr-10">
                                                    {candidate?.avatar_url ?
                                                        <img alt="profile" src={(candidate?.avatar_url != '')?candidate.avatar_url:'assets/imgs/page/candidates/img-candidate10.png'} />
                                                        : <CandidateAvatarIcon firstName={candidate?.first_name} lastName={candidate?.last_name} />
                                                    }
                                                <h5>{Util.handleUndefinedFullName(candidate?.first_name, candidate?.last_name)}</h5>
                                            </div>
                                            <div className="date">
                                                <span>{Moment(candidate?.created_at).fromNow()}</span>
                                            </div>
                                        </div>
                                        <p className="pl-30"><div dangerouslySetInnerHTML={{ __html: candidate?.message }} /></p>
                                    </div>
                                </div>
                            )}
                        )}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="textarea-style mt-10">
                            {/* <textarea name="message" onChange={(e) => setMessage(e.target.value)}  id="message" placeholder="Message" value={message} /> */}
                            <CKEditor
                                    key={editorKey} // Force re-render when the key changes
                                    data={message}
                                    config={{
                                        toolbar: [
                                            ['Bold'],
                                            ['Link', 'Unlink'],
                                        ]
                                    }
                                    }
                                    onChange={handleEditorChange}
                                />
                        </div>
                        <button className="btn btn-default btn-shadow hover-up" type="submit">Send message</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NetworkMessages
