import React, { useEffect, useState } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import styles from './styles/InviteYourFriend.module.scss'
import Input from '../../common/Styled components/Input'
import { capitalize } from '../../../utils'
import '../Admin/styles/DataTableCustom.scss'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import moment from 'moment'
import DataTable from 'react-data-table-component-footer'
import P from '../Admin/shared/P'
import Util from '../../../utils/util'
import s1 from '../../../../assets/v2/imgs/s1.png';
import s2 from '../../../../assets/v2/imgs/s2.png';
import '../AccountSettingPage/styles/account_setting.css'

const InviteYourFriendPageV2 = (props) => {
  const { totalEarned } = props
  const [invitaions, setInvitations] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [loading, setLoading] = useState([])
  const [userEmail, setUserEmail] = useState("")
  const [activePage, setActivePage] = useState(0)
  const [totalCount, setTotalCount] = useState()
  const [sortDirection, setSortDirection] = useState('desc')
  const [currentCount, setCurrentCount] = useState()
  const [perPage, setPerPage] = useState(10)
  const [sortField, setSortField] = useState('list.created_at')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [validationError, setValidationError] = useState({})


  const fetchData = async () => {
    setLoading(true)
    setTotalPages(0)
    const url = `/account/invite_your_friend.json?page=${activePage == 0 ? 1 : activePage}`
    makeRequest(url, 'get', '', {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      onSuccess: (response) => {
        let sr_no = (activePage == 0 ? 0 : (activePage - 1)) * 10
        setInvitations(
          response.data.list.map((list) => (sr_no = sr_no + 1, {
            id: sr_no,
            email: list.email,
            status: ((list.status == 'Success') ? <span style={{ color: 'green' }}>Success</span> : <span style={{ color: 'red' }}>Pending</span>),
            role: Util.capitalize(list.role),
            created_at: moment(list.created_at).format('MMM DD YYYY'),
            actions: ((list.status == 'Pending') ? ( <button className="new3" onClick={() => (onEditClick(list))}>Resend</button>) : "")
          }))
        )
        setPerPage(response.data.per_page)
        setTotalPages(response.data.total_pages)
        setTotalCount(response.data.total_count)
        setCurrentCount(response.data.current_counts)
        setLoading(false)
      },
    })
  }

  const columns = [
    {
      name: 'Email',
      selector: row => row.email,
      wrap: true,
      maxWidth: '35%',
      minWidth: '35%',
    },
    {
      name: 'Status',
      selector: row => row.status,
      wrap: true,
      maxWidth: '15%',
      minWidth: '15%',
    },
    {
      name: 'Invited Date',
      selector: row => row.created_at,
      maxWidth: '25%',
      minWidth: '25%'
    },
    {
      name: 'Action',
      selector: row => row.actions,
      maxWidth: '25%',
      minWidth: '25%',
    },
  ]



  const handleVerification = async (event) => {
    if (validateForm(userEmail)) {
      const URL = '/account/invite_your_friend'
      const payload = new FormData()
      payload.append('email_invitation[email]', userEmail)
      payload.append('email_invitation[token]', '')
      makeRequest(URL, 'post', payload, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
        createResponseMessage: (res) => {
          setTimeout(() => {
            window.open('/account/invite_your_friend', "_self")
          }, 1000);
          return {
            message: res?.msg,
            messageType: res?.messageType
          }
        }
      })
    }
  }
  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
    if (!userEmail) {
      formIsValid = false;
      errors["email"] = "Email is required."
    }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    handleVerification(event)
  }

  const onEditClick = (item) => {
    const URL = '/account/invite_your_friend/resend'
    const payload = new FormData()
    payload.append('email_re_invitation[email]', item.email)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (res) => {
        setTimeout(() => {
          window.open('/account/invite_your_friend', "_self")
        }, 1000);
        return {
          message: res?.msg,
          messageType: res?.messageType
        }
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [activePage])


  return (
    <div className="d-flex justify-content-center align-items-center">
      {/* <div className="col-md-9 "> */}
        <div className="card bg-white shadow p-5 mt-15 align-items-center">
          <div className="text-center mb-4">
            <h4 style={{ color: '#2e6ef5' }}>Invite your Network</h4>
            <div className="mx-5 mt-5 mb-10 container" style={{ textAlign: 'initial' }}>
              <div className="item mt-4 mb-4">
                <img src={s1} alt="Weee! Points" />
                <div className="content">
                  <span className="enki-heading-base text-surface-1-fg-default-idle">Earn 25% of revenue generated from each person you invite who joins CardinalTalent.ai</span>
                  <p className="pt-[2px] enki-body-base text-surface-1-fg-minor-idle">Earn 25% Of Revenue</p>
                </div>
              </div>
              <div className="item mt-4 mb-4">
                <img src={s2} alt="Coupons" />
                <div className="content">
                  <span className="enki-heading-base text-surface-1-fg-default-idle">Example of revenue generated</span>
                  <p className="pt-[2px] enki-body-base text-surface-1-fg-minor-idle">If the person you invited purchases a $500 per month subscription for 4 months that would be $2,000 in revenue.<br></br>  So, you would earn $500 for inviting that person.</p>
                </div>
              </div>
            </div>
            <div className="container mx-5" style={{ textAlign: 'initial' }}>
              <form onSubmit={(e) => { handleSubmit(e) }}>
                <input type="email" id="email" name="email" value={userEmail} required className="mt-3" style={{ border: '1px solid #2e6ef5', width: '71%', height: '50px' }} placeholder="enter an email address" 
                  onChange={event =>{
                    setUserEmail(event.target.value)
                    setValidationError({})
                    }}
                  />
                <button type="submit" className="btn btn-outline-success mt-5" style={{ backgroundColor: '#2e6ef5', height: '50px' }}>Send
                  Invitation</button>
              </form>
              <label className='error'>
                {validationError && validationError.email}        
              </label>
            </div>
            <div class="points-container container mx-5">
              <h3 style={{ color: '#2e6ef5', fontWeight: 'bold'}} >Credits Earned   : <span style={{ color: 'black'}} >&nbsp;${totalEarned}</span></h3>
            </div>
          </div>
          <hr />
          <div className="container ps-5 pe-5 mx-5">
            <DataTable
            title={false}
            persistTableHead={true}
            noDataComponent={<p className="text-center p-3">No record found</p>}
            columns={columns}
            data={invitaions}
            progressPending={loading}
            progressComponent={<P className="text-center p-3" size="18px" color="#6077f4">
                <Spinner className='spinner' animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </P>}
            sortServer
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={perPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => {
                setActivePage(page)
            }}
          />
          </div>
        </div>
      {/* </div> */}
    </div>
  )
}

export default InviteYourFriendPageV2