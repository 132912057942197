import React, { useState, useEffect, useReducer } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { H1, StyledForm, Label, Button, P } from '../../Admin/styles/UserManagementEditPage.styled'
import TextInput from '../../Admin/shared/TextInput'
import Select from '../../Admin/shared/Select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from 'react-select/async-creatable'
import RadioInput from '../../Admin/shared/RadioInput'
import ReactSelect from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import '../Jobs/styles/index.scss'
import isEmpty from 'lodash.isempty'
import axios from 'axios';
import { AgencyJobStoreContext, reducer } from '../../../../stores/Agency/AgencyJobStore'

const JobsCreate = ({organization_recruiters,job_owner,industry,method, job_groups,job, organization }) => {
    const [isLoading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [selectedOrganization, setSelectedOrganization] = useState([{ value: organization.id, label: organization.name }])
    const [member_options, setOptions] = useState([])
    const [industries, setIndustries] = useState(industry)
    const [selectedLocation, setSelectedLocation] = useState(job?.location)
    const [defaultLocation, setdefaultLocation] = useState([{ value: job?.location, label: job.location !== "null" ? job.location : 'No location found' }])
    const [selectedOwner, setSelectedOwner] = useState(job_owner?.id)
    const [defaultOwner, setdefaultOwner] = useState(job_owner ? [{ value: job_owner?.id, label: `${job_owner?.first_name} ${job_owner?.last_name}` }] : [])
    const [ownerOptions, setOwnerOptions] = useState(job_owner && organization_recruiters && organization_recruiters?.some(el => el.user_id === job_owner?.id) ? [...organization_recruiters.map((el) => ({ value: el.user_id, label: `${el?.first_name} ${el?.last_name}` }))] :
        (job_owner && organization_recruiters) ? [{ value: job_owner?.id, label: `${job_owner?.first_name} ${job_owner?.last_name}` }, ...organization_recruiters.map((el) => ({ value: el.user_id, label: `${el?.first_name} ${el?.last_name}` }))] : []
    )
    const [selectedGroup, setSelectedGroup] = useState(job?.group_id)
    const [jobGroup, setJobGroup] = useState(job_groups && [...job_groups?.map((el) => ({ value: el.value, label: el?.label }))])
    const [defaultGroup, setDefaultGroup] = useState(job?.group_id && job_groups && job_groups.filter(el => el.value === job.group_id).map(group => ({ value: group.value, label: group.label })))
    const expiryDate = moment(job?.expiry_date).format('YYYY-MM-DD')
    const beforeExpiryDate = moment(job?.expiry_date).subtract(7, 'day').format('YYYY-MM-DD')
    const currentDate = moment(new Date()).format('YYYY-MM-DD')
    const [descriptionAdd, setDescriptionAdd] = useState(job?.description)
    const [errorDescription, setErrorDescription] = useState()
    const [errorHiringEmail, setErrorHiringEmail] = useState()
    const [errorJobOwner, setErrorJobOwner] = useState('')
    const [errorOrganization, setErrorOrganization] = useState('')
    const [errorJobGroup, setErrorJobGroup] = useState('')
    const [organizationType, setOrganizationType] = useState(organization.organization_type)
    const [organizationRecruiterPresent, setOrganizationRecruiterPresent] = useState()
    const [locationPreference, setLocationPreference] = useState()
    const [locationIds, setLocationIds] = useState()
    const [linkedinURL, setLinkedinURL] = useState(job.linkedin_job_url || false)
    const [notificationemail, setPrimaryOwnerEmail] = useState(job.notificationemails || '')
    const [recruitersList, setRecruitersList] = useState([])
    const [errorNotificationEmail, setErrorNotificationEmail] = useState('')
    const [errorDepart, setErrorDepart] = useState('')
    const {
        organization_id,
        name,
        location,
        description,
        skills,
        nice_to_have_skills,
        notificationemails,
        referral_amount,
        keywords,
        nice_to_have_keywords,
        department,
        experience_years,
        prefered_titles,
        prefered_industries,
        school_names,
        company_names,
        location_names,
        status,
        create_lead,
        hiring_manager_email } = job

    let initialState = {
        ...initialState,
        ...{
            job,
            organization_id,
            name,
            location,
            description,
            skills,
            nice_to_have_skills,
            notificationemails,
            referral_amount,
            keywords,
            nice_to_have_keywords,
            department,
            experience_years,
            prefered_titles,
            prefered_industries,
            school_names,
            company_names,
            location_names,
            status,
            create_lead,
            hiring_manager_email
        }
    }
    const job_status = job?.status ? job.status : ''
    const [state, dispatch] = useReducer(reducer, initialState)
    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/agencies/jobs/fetch_agency_organization?search=${inputValue}`)
            .then((res) => res.json())
            .then((res) => {
                let { organizations } = res
                setLoading(false)
                setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
            })
            .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const handleMultiPreferences = (values) => {
        setLocationPreference([...values.map(({ value, label }) => ({ value: value, label: label }))])
        setLocationIds([...values.map(({ value, label }) => value)])
    }

    const handleInputChange = (str) => {
        setInputValue(str)
        setErrorHiringEmail('')
        return str;
    }
    const locationChange = (str) => {
        setSelectedLocation(str.label)
    }
    const ownerChange = (str) => {
        setSelectedOwner(str.value)
        setPrimaryOwnerEmail(primaryOwnerEmail(str.value))
        setErrorJobOwner('')
    }
    const groupChange = (str) => {
        setSelectedGroup(str.value)
        setErrorJobGroup('')
    }
    const handleSelectOption = (selectedOptions) => {
        setSelectedOrganization([selectedOptions])
        // organizationGet(selectedOptions.value)
        getOrganizationRecruiter(selectedOptions.value)
        setErrorOrganization('')
    }

    const organizationGet = (id) => {
        const URL = `/api/v1/company/organizations/${id}`
        makeRequest(URL, 'get', {}, {
            createResponseMessage: (response) => {
            },
            onSuccess: (response) => {
                setOrganizationType((response.data.organization.organization_type) ? response.data.organization.organization_type : null)
            }
        })
    }

    const getOrganizationRecruiter = async (id) => {
        const URL = `/agencies/jobs/job_owner?organization_id=${id}`
        let owners = null
        await makeRequest(URL, 'get', {}, {
            createResponseMessage: (response) => {
            },
            onSuccess: (response) => {
                owners = response.data.owners
                setRecruitersList(owners) // Todo : Not a good solution , form need to be refactor
            }
        })
        setOwnerOptions([...owners.map((owner) => ({ value: owner.user_id, label: `${owner.first_name} ${owner.last_name}` }))])
    }

    const validOrganizationRecruiter = async (org_id) => {
        const formData = new FormData()
        formData.append(`organization_id`, org_id)
        formData.append(`user_id`, selectedOwner)
        const URL = `/agencies/jobs/create_organization_member`
        let present = null
        await makeRequest(URL, 'post', formData, {
            createResponseMessage: (response) => {
            },
        })
    }

    const saveJob = async (newJob) => {
        if (method === 'put') {
            dispatch({ type: 'update_job', value: newJob, id: job.id })
        } else {
            dispatch({ type: 'add_job', value: newJob })
        }

    }
    const filterOptions = [
        { value: 'active', label: 'Active' },
        { value: 'expired', label: 'Expired' },
    ]
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#f5f7ff', border: 'none', height: '40px' })
    };

    const LocationPrefcolourStyles = {
        control: styles => ({ ...styles, backgroundColor: '#f5f7ff', border: 'none', height: 'auto', zIndex: '15' })
    };

    const exdendExpiredJob = async () => {
        const formData = new FormData()
        for (const key in job) {
            if (key != 'status') {
                formData.append(`job[${key}]`, job[key])
            } else {
                formData.append(`job[${key}]`, 'active')
            }
        }
        const url = `/agencies/jobs/exdend_job/${job.id}`
        await makeRequest(url, 'put', formData, {
            createSuccessMessage: () => 'Job exdend successfully ',
            onSuccess: () => {
                window.location.href = '/agencies/jobs'
            },
        })
    }


    const handleEditorChange = (content) => {
        setDescriptionAdd(content.editor.getData())
        setErrorDescription('')
    }

    const primaryOwnerEmail = (userId) => {
        if (job_owner != null) {
            return organization_recruiters.find(recruiter => recruiter.user_id == userId).email || ''
        }
        return recruitersList.find(recruiter => recruiter.user_id == userId).email || ''
    }

    const handleNotificationEmail = (e) => {
        const { value } = e.target
        setPrimaryOwnerEmail(value)
        if (value) {
            setErrorNotificationEmail('')
        }
    }

    const handleKeyPress = async (value, callback) => {
        const url = `/filter_candidate_on_location`;
        const formData = new FormData();
        formData.append('filter_word', value);
        setLoading(true);
        const response = await axios
            .post(url, formData)
            .then((res) => res)
            .catch((error) => console.log(error));
        const locationPreferrenceArray = response.data.filter.map(
            ({ id, state, city, country }) => {
                if (country === 'usa') {
                    return { value: id, label: `${city}, ${state} (US)` };
                } else {
                    return { value: id, label: `${city}, ${state}` };
                }
            }
        );
        callback(locationPreferrenceArray);
        setLoading(false);
    };

    return (
        <AgencyJobStoreContext.Provider value={{ state, dispatch }}>
            <div className="d-flex flex-column align-items-center justify-content-center mx-5 my-5">
                <H1>{method == 'post' ? 'Create Job' : 'Update Job'}</H1>
                <Formik
                    initialValues={{
                        name: job?.name == "null" ? '' : job?.name,
                        location: job.location == "null" ? '' : job.location,
                        description: descriptionAdd == "null" ? '' : descriptionAdd,
                        skills: job?.skills == "null" ? '' : job?.skills,
                        nice_to_have_skills: job?.nice_to_have_skills == "null" ? '' : job?.nice_to_have_skills,
                        notificationemails: notificationemail == "null" ? '' : notificationemail,
                        referral_amount: job?.referral_amount == "null" ? '' : job?.referral_amount,
                        keywords: job?.keywords == "null" ? '' : job?.keywords,
                        nice_to_have_keywords: job?.nice_to_have_keywords == "null" ? '' : job?.nice_to_have_keywords,
                        department: job?.department == "null" ? '' : job?.department,
                        experience_years: job?.experience_years == "null" ? '' : job?.experience_years,
                        prefered_titles: job?.prefered_titles == "null" ? '' : job?.prefered_titles,
                        school_names: job?.school_names == "null" ? '' : job?.school_names,
                        company_names: job?.company_names == "null" ? '' : job?.company_names,
                        location_preference: job?.location_preference == "null" ? '' : job?.location_preference,
                        status: job?.id ? job.status : 'active',
                        create_lead: job?.id ? job.create_lead : true,
                        hiring_manager_email: job?.hiring_manager_email || '',
                        linkedin_url: job?.linkedin_url == "null" ? '' : job?.linkedin_url,
                        sourcer_notes: job?.sourcer_notes == "null" ? '' : job?.sourcer_notes,
                        compensation: job?.compensation == "null" ? '' : job.compensation,
                        work_authorization: job?.work_authorization == "null" ? '' : job.work_authorization,
                        employment_type: job?.employment_type == "null" ? '' : job.employment_type,
                        boolean_keyword_search: job?.boolean_keyword_search == "null" ? '' : job.boolean_keyword_search,
                        industry: job.industry == "null" ? '' : job.industry,
                        title_avoid: job.title_avoid == "null" ? '' : job.title_avoid,
                        companies_avoid: job.companies_avoid == "null" ? '' : job.companies_avoid,
                        target_location_avoid: job.target_location_avoid == "null" ? '' : job.target_location_avoid,
                        job_description_link: job.job_description_link == "null" ? '' : job.job_description_link,
                        ideal_linked_in_url: job?.ideal_linked_in_url == "null" ? '' : job?.ideal_linked_in_url,
                        manual_sourcing: job?.manual_sourcing?.toString() == "null" ? '' : job?.manual_sourcing?.toString() 
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .required('Position Title is required').nullable().trim(),
                        nice_to_have_skills: Yup.string()
                        .required('Nice to have skills is required').nullable().trim(),    
                        skills: Yup.string()
                            .required('Must have skills is required').nullable().trim('Must have skills is required'),
                    })}
                    onSubmit={(values) => {
                        setErrorDepart('')
                        setErrorDescription('')
                        setErrorOrganization('')
                        setErrorJobOwner('')
                        setErrorDepart('')
                        setErrorHiringEmail('')
                        setErrorJobGroup('')
                        setErrorNotificationEmail('')
                        let source_at = false
                        if (isEmpty(descriptionAdd)) {
                            setErrorDescription('Job Description is required')
                            return false;
                        }
                        if (selectedOrganization[0].value == '' || selectedOrganization[0].value == null | selectedOrganization[0].value == 'null' || typeof selectedOrganization[0].value == 'undefined') {
                            setErrorOrganization('Organization is required')
                            return false
                        }
                        if (selectedOwner == '' || selectedOwner == null || selectedOwner == 'null' || typeof selectedOwner == 'undefined') {
                            setErrorJobOwner('Primary owner is required')
                            return false
                        }
                        if ( isEmpty(values.department) || values.department == '') {
                            setErrorDepart('Job Department is required')
                            return false;
                        }
                        if (organizationType === 'contingency' && values.hiring_manager_email == '') {
                            setErrorHiringEmail('')
                            return false;
                        }
                        if (selectedGroup == '' || selectedGroup == null || selectedGroup == 'null' || typeof selectedGroup == 'undefined') {
                            setErrorJobGroup('Job group is required')
                            return false
                        }
                        if (notificationemail) {
                            if (!notificationemail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                setErrorNotificationEmail('Email is invalid')
                                return false
                            }
                        }
                        if (isEmpty(notificationemail)) {
                            setErrorNotificationEmail('Email is required')
                            return false
                        }
                        if( !isEmpty((values?.nice_to_have_keywords)?.trim())
                            || !isEmpty((values?.boolean_keyword_search)?.trim())
                            || !isEmpty((values?.keywords)?.trim())
                            || !isEmpty((values?.prefered_titles)?.trim())
                            || !isEmpty((values?.experience_years)?.trim())
                            || !isEmpty((values?.school_names)?.trim())
                            || !isEmpty((values?.company_names)?.trim())
                            || !isEmpty((values?.location_preference)?.trim())
                            || !isEmpty((values?.sourcer_notes)?.trim())
                            || !isEmpty((values?.employment_type)?.trim())
                            || !isEmpty((values?.work_authorization)?.trim())
                            || !isEmpty((values?.compensation)?.trim())
                            || !isEmpty((values?.industry)?.trim())
                            || !isEmpty((values?.title_avoid)?.trim())
                            || !isEmpty((values?.companies_avoid)?.trim())
                            || !isEmpty((values?.target_location_avoid)?.trim())
                            || !isEmpty((values?.ideal_linked_in_url)?.trim())
                            || !isEmpty((values?.job_description_link)?.trim())
                            || !isEmpty((values?.manual_sourcing)?.trim())){
                                source_at = true
                        }
                        saveJob({
                            organization_id: selectedOrganization[0].value,
                            name: (values?.name) ?  (values.name).trim() : '',
                            location: selectedLocation,
                            description: ((descriptionAdd) ? descriptionAdd.trim() : ''),
                            skills: ((values?.skills) ?  (values.skills).trim() : ''),
                            nice_to_have_skills: (!values?.nice_to_have_skills || values?.nice_to_have_skills == "null" || values?.nice_to_have_skills == "") ? (values?.skills).trim() : (values?.nice_to_have_skills).trim(),
                            notificationemails: ((notificationemail) ? notificationemail.trim() : ''),
                            referral_amount: ((values?.referral_amount) ? (values.referral_amount).trim() : ''),
                            keywords: ((values?.keywords) ? (values.keywords).trim() : ''),
                            nice_to_have_keywords: ((values?.nice_to_have_keywords) ?  (values.nice_to_have_keywords).trim() : ''),
                            department: ((values?.department) ? (values.department).trim() : ''),
                            experience_years: ((values?.experience_years) ? (values.experience_years).trim() : ''),
                            prefered_titles: ((values?.prefered_titles) ?  (values.prefered_titles).trim() : ''),
                            school_names: ((values?.school_names) ? (values.school_names).trim() : ''),
                            company_names: ((values?.company_names) ? (values.company_names).trim() : ''),
                            // location_names: locationIds,
                            location_preference: ((values?.location_preference) ? (values.location_preference).trim() : ''),
                            status: values.status,
                            create_lead:  method === 'put' ? values?.create_lead : true,
                            hiring_manager_email: ((values?.hiring_manager_email) ?  (values.hiring_manager_email).trim() : ''),
                            creator_id: selectedOwner,
                            linkedin_url: values?.linkedin_url == "null" ? '' : ((values?.linkedin_url) ?  (values.linkedin_url).trim() : ''),
                            sourcer_notes: ((values?.sourcer_notes) ? (values.sourcer_notes).trim() : ''),
                            compensation: ((values?.compensation) ? (values.compensation).trim() : ''),
                            work_authorization: ((values?.work_authorization) ? (values.work_authorization).trim() : ''),
                            employment_type: ((values?.employment_type) ? (values.employment_type).trim() : ''),
                            group_id: selectedGroup,
                            boolean_keyword_search: (values.boolean_keyword_search == "null" || isEmpty(values.boolean_keyword_search)) ? '' : ((values?.boolean_keyword_search) ? (values.boolean_keyword_search).trim() : ''),
                            source_at: source_at,
                            industry: ((values?.industry) ? (values.industry).trim() : ''),
                            title_avoid: ((values?.title_avoid) ? (values.title_avoid).trim() : ''),
                            companies_avoid: ((values?.companies_avoid) ? (values.companies_avoid).trim() : ''),
                            target_location_avoid: ((values?.target_location_avoid) ? (values.target_location_avoid).trim() : ''),
                            job_description_link: ((values?.job_description_link) ? (values?.job_description_link).trim() : ''),
                            portalcompanyname: ((selectedOrganization[0]?.label) ? (selectedOrganization[0].label).trim() : ''),
                            ideal_linked_in_url: ((values?.ideal_linked_in_url) ? (values.ideal_linked_in_url).trim() : ''),
                            linkedin_job_url: linkedinURL,
                            manual_sourcing: (values.manual_sourcing == "null" || isEmpty(values.manual_sourcing)) ? '' : values.manual_sourcing
                            // company_preference: (values.company_names == 'Specify') ? '' : values.company_names,
                            // education_preference: (values.school_names == 'Specify') ? '' : values.school_names
                        })
                        // validOrganizationRecruiter(selectedOrganization[0].value)
                    }}
                >
                    <StyledForm>
                        <Row>
                            <P>Job Information</P>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            {'Select Organization*'}
                                        </Label>
                                        <AsyncSelect
                                            isLoading={isLoading}
                                            isClearable={false}
                                            isDisabled={(method == 'put') ? true : false}
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            defaultValue={selectedOrganization[0]}
                                            onInputChange={handleInputChange}
                                            onChange={handleSelectOption}
                                            styles={colourStyles}
                                            placeholder='search for organization'
                                            noOptionsMessage={() => 'start typing the name of organization'}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorOrganization && errorOrganization}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            Primary Owner*
                                        </Label>
                                        <ReactSelect
                                            onChange={ownerChange}
                                            options={ownerOptions}
                                            styles={colourStyles}
                                            defaultValue={defaultOwner}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobOwner && errorJobOwner}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="Position Title*"
                                            name="name"
                                            type="text"
                                            id="name"
                                            width="100%"
                                            placeholder='Job title'
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            {'Location'}
                                        </Label>
                                        <AsyncCreatableSelect
                                            isLoading={isLoading}
                                            isClearable={false}
                                            cacheOptions
                                            loadOptions={handleKeyPress}
                                            defaultValue={defaultLocation}
                                            onChange={locationChange}
                                            styles={colourStyles}
                                            placeholder="Search for location"
                                            noOptionsMessage={() => 'start typing the name of location'}
                                        />
                                    </Col>
                                </Row>
                                <Label>
                                    {'Job Description*'}
                                </Label>
                                {/* <Editor
                        value={descriptionAdd}
                        init={{
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                        }}
                        onEditorChange={handleEditorChange}
                    /> */}
                                <CKEditor
                                    initData={descriptionAdd}
                                    config={{
                                        toolbar: [
                                            ['Cut', 'Copy', 'Paste'],
                                            ['Styles', 'Format', 'Font', 'FontSize'],
                                            ['Bold', 'Italic', 'Strike'],
                                            ['Undo', 'Redo'],
                                            ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                                            ['Link', 'Unlink'],
                                            ['Table', 'HorizontalRule'],
                                            ['Maximize']
                                        ]
                                    }
                                    }
                                    onChange={handleEditorChange}
                                />
                                <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                    {errorDescription && errorDescription}
                                </Label>
                                <br />

                                <TextInput
                                    label="Must have skills (Comma separated values e.g. PHP,MySql)*"
                                    name="skills"
                                    type="textarea"
                                    id="skills"
                                    width="100%"
                                    rows="7"
                                    maxLength="500"
                                    placeholder='Must have skills'
                                />
                                <TextInput
                                    label="Nice to have skills (Comma separated values e.g. PHP,MySql)*"
                                    name="nice_to_have_skills"
                                    type="textarea"
                                    id="nice_to_have_skills"
                                    width="100%"
                                    rows="7"
                                    maxLength="500"
                                    placeholder='Nice to have skills'
                                />
                            </Col>
                            <Col xs={12} sm={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Label>
                                            Job Group*
                                        </Label>
                                        <ReactSelect
                                            onChange={groupChange}
                                            options={jobGroup}
                                            styles={colourStyles}
                                            defaultValue={defaultGroup}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobGroup && errorJobGroup}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="Where would you like to receive your applicants?*"
                                            name="notificationemails"
                                            value={notificationemail}
                                            type="email"
                                            id="notificationemails"
                                            width="100%"
                                            placeholder='Email address'
                                            onChange={(e) => handleNotificationEmail(e)}
                                        />
                                        <Label style={{ fontSize: '10px', color: 'red' }}>
                                            {errorNotificationEmail && errorNotificationEmail}
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <Select
                                            label="Job Department*"
                                            name="department"
                                            id="department"
                                            width="100%"
                                            type="text"
                                        >
                                            <option value="">Select</option>
                                            <option value="Accounting">Accounting</option>
                                            <option value="Finance">Finance</option>
                                            <option value="Sales">Sales</option>
                                            <option value="Research and Development">Research and Development </option>
                                            <option value="IT">IT</option>
                                            <option value="Management">Management</option>
                                            <option value="Administration">Administration</option>
                                            <option value="Customer support">Customer support</option>
                                            <option value="Technical support">Technical support</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Logistics">Logistics</option>
                                            <option value="Operations">Operations</option>
                                            <option value="Planning">Planning</option>
                                            <option value="Human resources">Human resources</option>
                                            <option value="Purchasing">Purchasing</option>
                                            <option value="Quality assurance">Quality assurance</option>
                                            <option value="Engineering">Engineering</option>
                                            <option value="Public relations">Public relations</option>
                                        </Select>
                                    </Col>
                                    <Col xs={12} sm={12} lg={3}>
                                        <TextInput
                                            label="what is the commision for hire of a referred candidate?"
                                            name="referral_amount"
                                            type="text"
                                            id="money"
                                            width="100%"
                                            placeholder="$"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} lg={3}>
                                {job && (
                                    <TextInput
                                        label="Status*"
                                        name="status"
                                        type="text"
                                        id="status"
                                        width="100%"
                                        as="select"
                                    >
                                        <option value="active">Active</option>
                                        <option value="pending">Pending</option>
                                        <option value="expired">Expired</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="closed">Closed</option>
                                    </TextInput>
                                )}
                            </Col>
                        </Row>
                       
                        <Row>
                            <Col>
                                <div style={{ marginTop: '18px' }}>
                                    <Button type="submit">Save</Button>
                                    <Button
                                        type="button"
                                        onClick={() => window.history.back()}
                                        className="ml-sm-3"
                                    >
                                        Go Back
                                    </Button>
                                    {((job && (expiryDate >= beforeExpiryDate && beforeExpiryDate <= currentDate)) || (job && (job_status === 'expired'))) && (
                                        <Button
                                            type="button"
                                            onClick={exdendExpiredJob}
                                            className="ml-sm-3"
                                        >
                                            Extend Expired Job
                                        </Button>)
                                    }

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ marginTop: '18px' }}>
                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorOrganization && errorOrganization}
                                    </Label>

                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorHiringEmail && errorHiringEmail}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red', marginTop: '5px' }}>
                                            {errorJobGroup && errorJobGroup}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorJobOwner && errorJobOwner}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red', marginRight: '5px' }}>
                                        {errorDescription && errorDescription}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red' }}>
                                        {errorNotificationEmail && errorNotificationEmail}
                                    </Label>
                                    <Label style={{ fontSize: '10px', color: 'red' }}>
                                        {errorDepart && errorDepart}
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </StyledForm>
                </Formik>
            </div>
        </AgencyJobStoreContext.Provider>
    )
}
export default JobsCreate