import React, { useState, useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'
import Select from 'react-select'
import stateCities from 'state-cities'
import { Spinner } from 'react-bootstrap'

import TextInput from '../../common/TextInput/TextInput'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import FileButton from '../../common/FileButton/FileButton'
import { capitalizeFirstLetter } from '../../../utils'
import styles from '../SignupPage/styles/Signup.module.scss'
import {Button,H1,Logo,Message,P,StyledForm,A,InfoText,Row} from './styles/CreateProfilePage.styled'

const CONTACT_DETAILS = 'CONTACT_DETAILS'

const CreateOrganizationPage = ({
    formData,
    setFormData,
    submitData,
    loading,
    result,
    setResult,
    selectedRole,
    hidePrevious,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const inputRef = useRef()
    const [logo, setLogo] = useState(formData.organization.logo)
    const [logoError, setLogoError] = useState(null)
    const [stateError, setStateError] = useState(null)
    const [cityError, setCityError] = useState(null)
    const [logoUrl, setLogoUrl] = useState(
        logo ? URL.createObjectURL(logo) : null
    )
    const [compnanySizes, setCompnanySizes] = useState([])
    const [industries, setIndustries] = useState([])
    const [states, setStates] = useState([])
    const [selectState, setSelectState] = useState(null)
    const [citys, setCitys] = useState([])
    const [selectCity, setSelectCity] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [agreement, setAgreement] = useState(null)
    const [agreementError, setAgreementError] = useState(null)
    
    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
            },
        }))
        fetchLookUp()
    }, [])

    useEffect(()=>{
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                logo: logo,
            },
        }))
    },[logo])
    
    useEffect(() => {
        let stateOption = []
        stateCities.getStates().map((value) => {
            stateOption.push({
                label: capitalizeFirstLetter(value),
                value: capitalizeFirstLetter(value),
            })
        })
        setStates(stateOption)
        if (formData.organization.region && formData.organization.city) {
            setSelectState(formData.organization.region)
            setSelectCity(formData.organization.city)
            setCitys([])
        }
    }, [])

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            setFormData((prev) => ({
                ...prev,
                step: 'ORGANIZATION_TYPE',
            }))
        }
        setIsSubmitted(false)
    }, [isSubmitted])


    const fetchLookUp = async () => {
        const url = `/signup/contracts?name=org_note&role=${selectedRole}`
        await axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })

        const lookupsUrl = '/signup/lookups'
        await axios.get(lookupsUrl).then((res) => {
            setCompnanySizes([...res.data.company_sizes])
            setIndustries([...res.data.industries])
        })
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                150,
                150,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                'file',
                150,
                150
            )
        })

    const addLogo = async (e) => {
        e.persist()
        const compressedImage = await resizeFile(e.target.files[0])
        setLogoError(null)
        setLogo(compressedImage)
        setLogoUrl(URL.createObjectURL(compressedImage))
    }

    const onSelectState = (event) => {
        setSelectCity(null)
        setSelectState(event)
        let cityOption = []
        stateCities.getCities(event.value).map((value, key) => {
            cityOption.push({
                value: capitalizeFirstLetter(value),
                label: capitalizeFirstLetter(value),
            })
        })
        cityOption.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1)
        setCitys(cityOption)
        setFormData((prev) => ({
            ...prev,
            organization: {
                ...prev.organization,
                region: event,
                city:''
            },
        }))
    }
    const onSelectCity = (event) => {
        setSelectCity(event)
        setFormData((prev) => ({
            ...prev,
            organization: { 
                ...prev.organization,     
                city: event,
            },
        }))
    }

    const handleOnSubmit = (values) => {
        setFormData((prev) => ({
            ...prev,
            organization: {
                name: values.name,
                industry: values.industry,
                companySize: values.companySize,
                country: values.country,
                region: selectState,
                city: selectCity,
                description: values.description,
                logo: logo,
                agreement:agreement,
                website_url: values.website_url,
                subdomain: ((values.name).replace(" ","_")).toLowerCase(),
                slug: ((values.name).replace(" ","-")).toLowerCase(),
            },
        }))
        setIsSubmitted(true)
    }

    return (
        <>
            <div className={`${styles.signUpForm}`}>
                <MainPanel>
                    <H1>Create an Organization</H1>

                    <Formik
                        initialValues={{
                            name: formData.organization.name,
                            industry: formData.organization.industry,
                            companySize: formData.organization.companySize,
                            country: 'USA',
                            city: formData.organization.city,
                            region: formData.organization.region,
                            description: formData.organization.description,
                            logo: formData.organization.logo,
                            website_url: formData.organization.website_url,
                            subdomain: formData.organization.subdomain,
                            slug: formData.organization.slug,
                        }}
                        validateOnBlur={true}
                        validationSchema={Yup.object({
                            name: Yup.string().trim()
                                .max(100, 'Must be exactly 100 characters')
                                .required('Organization name is required')
                                .test(
                                    'name-unique',
                                    'This Organization name is already in use',
                                    async function (value) {
                                        // check if user exists with email
                                        // call backend with email and see if it returns user
                                        const res = await axios.get(
                                            `/organizations/exists?name=${encodeURIComponent(
                                                value
                                            )}`
                                        )
                                        return !res.data.organization_exists
                                    }
                                ),
                            industry: Yup.string()
                                .required('Industry is required')
                                .oneOf(
                                    industries.map(({ key, value }) => key),
                                    'Invalid Industry'
                                ),
                            companySize: Yup.string()
                                .required('Organization size is required')
                                .oneOf(
                                    compnanySizes.map(({ key, value }) => key),
                                    'Invalid Organization Size'
                                ),
                            country: Yup.string().required(
                                'Country is required'
                            ),
                            description: Yup.string().required(
                                'About Organization is required'
                            ).trim(),
                            website_url: Yup.string()
                                .required(
                                    'Organization Website Url is required'
                                )
                                .trim(
                                    'Organization website cannot include leading and trailing spaces'
                                ).matches(
                                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                    'Enter correct url!'
                                ),
                        })}
                        validate={(values) => {
                            const errors = {}
                            setFormData((prev) => ({
                                ...prev,
                                organization: {
                                    name: values.name,
                                    industry: values.industry,
                                    companySize: values.companySize,
                                    country: values.country,
                                    region:selectState ,
                                    city:selectCity ,
                                    description: values.description,
                                    logo: logo,
                                    website_url: values.website_url,
                                    subdomain: ((values.name).replace(" ","_")).toLowerCase(),
                                    slug: ((values.name).replace(" ","-")).toLowerCase(),
                                },
                            }))

                            if (!logo) {
                                errors.logo = 'Logo is required'
                            } else if (
                                ![
                                    'image/jpg',
                                    'image/jpeg',
                                    'image/gif',
                                    'image/png',
                                ].includes(logo.type)
                            ) {
                                errors.logo =
                                    'Logo can only ne of type jpg, png, gif, jpeg'
                            }
                            setLogoError(errors.logo)

                            if (!selectState) {
                                errors.region = 'State is required'
                            }
                            setStateError(errors.region)

                            if (!selectCity) {
                                errors.city = 'City is required'
                            }
                            setCityError(errors.city)

                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            handleOnSubmit(values)
                        }}
                    >
                        <StyledForm display="flex" direction="column">
                            <div className={styles.form_flexDiv}>
                                <div>
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        label="Organization Name*"
                                        name="name"
                                        type="text"
                                        id="name"
                                        width="31rem"
                                    />
                                    <div className='d-flex justify-content-between'>
                                        <TextInput
                                            className={styles.fullwidthInput}
                                            as="select"
                                            label="Industry*"
                                            name="industry"
                                            type="text"
                                            id="industry"
                                            width="18rem"
                                        >
                                            <option value="">Select</option>
                                            {industries.map(
                                                ({ key, value }) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={key}
                                                        >
                                                            {value}
                                                        </option>
                                                    )
                                                }
                                            )}
                                        </TextInput>
                                        <TextInput
                                            className={styles.fullwidthInput}
                                            as="select"
                                            label="Organization Size*"
                                            name="companySize"
                                            type="text"
                                            id="companySize"
                                            width="10rem"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            <option value="">Select</option>
                                            {compnanySizes.map(
                                                ({ key, value }) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={key}
                                                        >
                                                            {value}
                                                        </option>
                                                    )
                                                }
                                            )}
                                        </TextInput>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <TextInput
                                            className={styles.fullwidthInput}
                                            label="Organization Website URL*"
                                            name="website_url"
                                            type="text"
                                            id="website_url"
                                            width="18rem"
                                        />
                                        <TextInput
                                            className={styles.fullwidthInput}
                                            label="Country*"
                                            name="country"
                                            type="text"
                                            id="country"
                                            width="10rem"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={styles.form_flexDiv}>
                                        <div className={styles.selectDiv}>
                                            <label
                                                htmlFor="state"
                                                className="sc-gtsrHT jkaNjf"
                                            >
                                                State*
                                            </label>
                                            <Select
                                                options={states}
                                                onChange={onSelectState}
                                                name="region"
                                                value={formData.organization.region}
                                                className={`${styles.colourStyles} react-select-2`}
                                                classNamePrefix={
                                                    styles.colourStyles
                                                }
                                                
                                            />
                                            {
                                            !!stateError && 
                                                <span className={styles.formError}>
                                                    {stateError} 
                                                </span>
                                            }
                                        </div>
                                        <div className={styles.selectDiv}>
                                            <label
                                                htmlFor="city"
                                                className="sc-gtsrHT jkaNjf"
                                                style={{ marginLeft: '11px' }}
                                            >
                                                City*
                                            </label>
                                            <Select
                                                options={citys}
                                                onChange={onSelectCity}
                                                name="city"
                                                value={formData.organization.city}
                                                className={
                                                    styles.colourStylesCity
                                                }
                                                classNamePrefix={
                                                    styles.colourStylesCity
                                                }
                                            />
                                            {!!cityError && (
                                                <span className={styles.formError}>
                                                    {cityError}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                   
                                    <TextInput
                                        className={styles.fullwidthInput}
                                        as="textarea"
                                        label="About Organization*"
                                        name="description"
                                        type="text"
                                        style={{ marginTop: '10px',marginBottom: '5px' }}
                                        id="description"
                                        width="31rem"
                                    />

                                    {/* <FileButton
                                        type="button"
                                        label="Upload Agreement"
                                           'application/pdf',
                                        ]}
                                        style={{ marginTop: '15px',marginBottom: '15px' }}
                                        resumeError={agreementError}
                                        setResumeError={setAgreementError}
                                        ge file={agreement}
                                        fileType={[
                                        tFile={(file) => setAgreement(file)}
                                    /> */}

                                    <InfoText>
                                        You will be able to edit details later.
                                    </InfoText>
                                </div>
                                <div
                                    className={` ${styles.logoBox} d-inline-flex flex-column`}
                                >
                                    <Logo image={logoUrl}>
                                        {logoUrl
                                            ? ''
                                            : 'Upload Organizations Logo'}
                                    </Logo>

                                    {!!logoError && (
                                        <span
                                            style={{
                                                fontSize: '10px',
                                                color: 'red',
                                                marginTop: '5px',
                                            }}
                                        >
                                            {logoError}
                                        </span>
                                    )}
                                    <input
                                        type="file"
                                        name="logo"
                                        id="logo"
                                        ref={inputRef}
                                        hidden
                                        onChange={addLogo}
                                    />

                                    <Button
                                        style={{ marginTop: '18px' }}
                                        type="button"
                                        onClick={() => inputRef.current.click()}
                                    >
                                        Upload File
                                    </Button>
   
                                </div>
                            </div>

                            <Row jContent="space-between">
                                
                                <A
                                    style={{ marginRight: '20px' }}
                                    onClick={() =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            step: CONTACT_DETAILS,
                                        }))
                                    }
                                >
                                   {!hidePrevious &&  'Previous'}
                                </A>
                                <Button type="submit" disabled={loading}>
                                    {loading ? (
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                        />
                                    ) : (
                                        (selectedRole === 'employer') ? 'Next' : 'Submit'
                                    )}
                                </Button>
                            </Row>
                            {result && (
                                <Row jContent="center">
                                    <Message color={result.color}>
                                        {result.message}
                                    </Message>
                                </Row>
                            )}
                        </StyledForm>
                    </Formik>
                </MainPanel>
                { showInfoPanel &&
                    <InfoPanel>
                        <div className={`${styles.infopanelDiv}`}>
                            <div className="d-flex flex-column">
                                <P>Note</P>
                                <div
                                    style={{
                                        border: '1px solid #BFC5E2',
                                        width: '100%',
                                        marginBottom: '30px',
                                    }}
                                ></div>
                                <P
                                    weight={'normal'}
                                    dangerouslySetInnerHTML={{
                                        __html: roleDescription,
                                    }}
                                ></P>
                            </div>
                        </div>
                    </InfoPanel>
                }
            </div>
        </>
    )
}

export default CreateOrganizationPage
