import React, { useState, useEffect } from 'react'

import CreateOrganizationPage from './CreateOrganizationPage'
import CreateOrganizationTypePage from './CreateOrganizationTypePage'
import ContactDetailPage from './ContactDetailPage'
import CreateBookSlotPage from './CreateBookSlotPage'
import axios from 'axios'


const CreateProfilePage = ({ user, isProfileComplete, showInfoPanel, isOrgComplete }) => {
    console.log("🚀 ~ file: CreateProfilePage.js:11 ~ CreateProfilePage ~ isOrgComplete:", isOrgComplete)
    const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
    const CONTACT_DETAILS = 'CONTACT_DETAILS'
    const ORGANIZATION_TYPE = 'ORGANIZATION_TYPE'
    const BOOK_SLOT = 'BOOK_SLOT'
    console.log(user)
    const initialFormData = {
        // step: CREATE_ORGANIZATION,
        step: (isProfileComplete == true  && isOrgComplete == false) ? CREATE_ORGANIZATION : CONTACT_DETAILS,
        contactDetails: {
            firstName: user.first_name,
            lastName: user.last_name,
            title: '',
            phoneNumber: '',
            streetAddress: '',
            state: '',
            zipCode: '',
            location: '',
            resume: null,
            linkedinProfile: '',
            activeJobSeeker: '',
            audio_video_file: '',
            ein_number: '',
            calendly_link: '',
        },
        organization: {
            name: '',
            industry: '',
            companySize: '',
            country: '',
            city: null,
            region: null,
            description: '',
            logo: null,
            agreement: null,
            website_url: '',
        },
    }
    const [formData, setFormData] = useState(initialFormData)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)
    const isOnlyCreateOrganization = isProfileComplete && user.role === 'employer'
    const isOnlyCreateOrganizationAgreement = isProfileComplete && user.role === 'employer'
    const [infoPanel, setInfoPanel] = useState(showInfoPanel)

    useEffect(() => {
        setFormData({ ...formData })
        if (isOnlyCreateOrganization && isOrgComplete == false) {
            setFormData({ ...formData, step: CREATE_ORGANIZATION })
        }
        if(isOnlyCreateOrganizationAgreement && isOrgComplete == true){
            setFormData({ ...formData, step: ORGANIZATION_TYPE })
        }
    }, [user])

    const submitData = async (values) => {
        const data = { ...formData, ...values }
        const payload = new FormData()
        const url = '/create_profile'

        payload.append('registration[first_name]',data.contactDetails.firstName)
        payload.append('registration[last_name]', data.contactDetails.lastName)
        payload.append('registration[phone_number]',data.contactDetails.phoneNumber)
        payload.append('organization[name]', data.organization.name)
        payload.append('organization[description]',data.organization.description)
        payload.append('organization[industry]', data.organization.industry)
        payload.append('organization[company_size]',data.organization.companySize)
        payload.append('organization[country]', data.organization.country)
        payload.append('organization[region]', data.organization.region?.value)
        payload.append('organization[city]', data.organization.city?.value)
        payload.append('organization[website_url]', data.organization.website_url)
        payload.append('organization[subdomain]', data.organization.subdomain)
        payload.append('organization[slug]', data.organization.slug)
        payload.append('organization[logo]', data.organization.logo)
        if (data.organization.agreement){
            payload.append('registration[agreement]', (data.organization.agreement) || '')
        }
        
        console.log(payload)

        if(!infoPanel){
           payload.append('submit_from', 'admin')
        }
        
        if (user.role === 'employer') {
            payload.append('registration[title]', data.contactDetails.title)
            payload.append('registration[address]',data.contactDetails.streetAddress)
            payload.append('registration[zipcode]', data.contactDetails.zipCode)
            payload.append('registration[state]', data.contactDetails.state)
            payload.append('organization[organization_type]', (data.organization.organization_type) ? data.organization.organization_type : 'hourly')
        }
        if (user.role === 'talent') {
            payload.append('registration[resume]', data.contactDetails.resume)
            payload.append('registration[linkedin_profile_url]',data.contactDetails.linkedinProfile)
            payload.append('registration[active_job_seeker]',data.contactDetails.activeJobSeeker)
            if (data?.contactDetails.audio_video_file){
                payload.append('registration[audio_video]',  (data.contactDetails.audio_video_file) || '')
            }
                
        }
        
        if (user.role === 'recruiter'){
            payload.append('registration[location]',data.contactDetails.location)
            payload.append('registration[linkedin_profile_url]',data.contactDetails.linkedinProfile)
            if (data.contactDetails.resume){
            payload.append('registration[resume]', data.contactDetails.resume)
            }  
            payload.append('registration[ein_number]',data.contactDetails.ein_number)
            payload.append('registration[calendly_link]',data.contactDetails.calendly_link)
        }
       
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')

        setLoading(true)
        axios
            .post(url, payload, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            })
            .then((res) => {
                const responce = res.data
                if (responce.success) {
                    if (isOnlyCreateOrganization) {
                        setResult({color: '#0f5132',message: 'Organization successfully created',})
                    } else{
                        setResult({ color: '#0f5132', message: responce.msg })
                    }
                    setTimeout(() => {
                        if (!infoPanel){
                            window.location.href = '/admin/organizations'
                        }
                        else if (user.role === 'employer') {
                            window.location.href = '/jobs'
                        } else if (user.role === 'talent') {
                            window.location.href = '/talent_home'
                        } else if(user.role === 'admin'){
                            window.location.href = '/admin/dashboard'
                        } else {
                            window.location.href = '/jobs'
                        }
                        setLoading(false)
                    }, 2000)
                } else {
                    setResult({ color: '#ff1d31', message: responce.msg })
                    setLoading(false)
                }
                
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const displaySignupStep = (step) => {
        console.log("🚀 ~ file: CreateProfilePage.js:161 ~ displaySignupStep ~ step:", step)
    
        switch (step) {
            case CONTACT_DETAILS:
                return (
                    <ContactDetailPage
                        formData={formData}
                        setFormData={setFormData}
                        submitData={submitData}
                        loading={loading}
                        result={result}
                        setResult={setResult}
                        selectedRole={user.role}
                        user={user}
                        showInfoPanel={showInfoPanel}
                    />
                )
            case CREATE_ORGANIZATION:
                return (
                    <CreateOrganizationPage
                        formData={formData}
                        setFormData={setFormData}
                        submitData={submitData}
                        loading={loading}
                        result={result}
                        setResult={setResult}
                        selectedRole={user.role}
                        hidePrevious={isOnlyCreateOrganization}
                        showInfoPanel={showInfoPanel}
                    />
                )
            case ORGANIZATION_TYPE:
                return (
                    <CreateOrganizationTypePage
                        formData={formData}
                        setFormData={setFormData}
                        submitData={submitData}
                        loading={loading}
                        result={result}
                        setResult={setResult}
                        selectedRole={user.role}
                        hidePrevious={isOnlyCreateOrganization}
                        showInfoPanel={showInfoPanel}
                    />
                )
            case BOOK_SLOT:
                return (
                    <CreateBookSlotPage
                        formData={formData}
                        setFormData={setFormData}
                        submitData={submitData}
                        loading={loading}
                        result={result}
                        setResult={setResult}
                        selectedRole={user.role}
                        hidePrevious={isOnlyCreateOrganization}
                        showInfoPanel={showInfoPanel}
                    />
                )
            default:
                return null
        }
    }

    return <>{displaySignupStep(formData.step)}</>
}

export default CreateProfilePage
