import React from 'react'
import Image from 'react-bootstrap/Image'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import LogoGroups from '../../../../assets/images/logos/cardinalgroups.png'
const FooterTalent = ({version,base_url,host_url,is_groups_url}) => {
    var SideName = (is_groups_url)?'Cardinal Groups':'CardinalTalent';
    var decription = (is_groups_url)?'Cardinal Groups is the best free community to share, sell, and enjoy courses online.':'CardinalTalent  is the heart of the design community and the best resource to discover and connect with designers and jobs worldwide.';
    return (
        <>
            <footer className={"footer mt-70"}>
                <div className={"footer-bottom"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-md-4 col-sm-12 col-lg-4"}>
                                <a  href={(is_groups_url)? host_url+'/communities' : host_url} className={"d-flex img1"}>
                                    <Image src={(is_groups_url)?LogoGroups:LogoWithText} alt={SideName} />
                                    </a>
                                <div className={"mt-10 mb-20"}>{decription}</div>
                                <div className={"footer-social"}>
                                    <a href="#" className={"icon-socials icon-facebook ml-0"}></a>
                                    <a href="#" className={"icon-socials icon-twitter"}></a>
                                    <a href="#" className={"icon-socials icon-instagram"}></a>
                                    <a href="#" className={"icon-socials icon-linkedin"}></a>
                                </div>
                            </div>
                            <div className={"col-md-2 col-xs-12"}></div>
                            <div className={"col-md-3 col-xs-6"}>
                                <h6>Company</h6>
                                <ul className={"menu-footer mt-35"}>
                                    <li><a href="/welcome/about_us">About us</a></li>
                                    <li><a href="/faq">FAQs</a></li>
                                    {/* <li><a href="#">Our Team</a></li>
                                    <li><a href="#">Contact</a></li> */}
                                </ul>
                            </div>
                            {/* <div className={"col-md-2 col-xs-6"}>
                                <h6>Product</h6>
                                <ul className={"menu-footer mt-35"}>
                                    <li><a href="#">Feature</a></li>
                                    <li><a href="#">Other Products</a></li>

                                    <li><a href="/subscription/order">Pricing</a></li>
                                </ul>
                            </div> */}
                            <div className={"col-md-3 col-xs-6"}>
                                <h6>Support</h6>
                                <ul className={"menu-footer mt-35"}>
                                    <li><a href="/privacy_policy">Privacy</a></li>
                                    <li><a href="/ccpa">CCPA</a></li>
                                    <li><a href="/terms_of_service">Terms</a></li>
                                    <li><a href="/optout">Opt Out</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-md-6 d-flex align-items-center"}>
                                Copyright ©2021 <a href={(is_groups_url)? host_url+'/communities' : host_url}>{SideName}</a>. All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterTalent
